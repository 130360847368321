import React, { Component } from 'react';
import InputMask from 'react-input-mask';

//Paginas
import apiUrl from '../../axiosApi';
import './index.css';

//Img
//import alunoImg from '../../assets/iconAluno.png';

class Esqueci extends Component{

    constructor(props){
      super(props);
      this.state = {
        carregou:'0',
        mensagem:'',
        id: null, 
        email: '',
        cpf: '',
        dtNascimento: '',
        logado: '',
      };
    }

    changeHandler = e => {
      this.setState({[e.target.name]: e.target.value})
    }
    recuperarSenha = async e => {
      e.preventDefault();
      document.getElementById('progress').style.display = 'block'
      this.setState({"mensagem": ""}) 
      var cpf1 = this.state.cpf.toString().replace('.','').replace('-','').replace('.','')
      await apiUrl.post(`/usuario/enviar-nova-senha`, {
        cpf: cpf1,
      })
      .then(res => {
        //Resposta do Servidor
        console.log(res);
        this.setState({"mensagem":  "Senha Alterada com Sucesso, verifique seu email"});        
        this.toastMensagem("Senha Alterada com Sucesso, verifique seu email" );        
        //window.location.replace("/login");
      })
      .catch(error => {
        //this.setState({"mensagem": "ERROR: Email e/ou senha Incorretos" });
        this.toastMensagem("ERROR: Dados de CPF Incorreto" );

      })
      document.getElementById('progress').style.display = 'none'
    }
    toastMensagem(message){
      const M = window.M;
      M.toast({html: message, classes: 'rounded'})
    }

    render(){
      const {email, cpf, dtNascimento} = this.state;

      return(
        <body className="esq">
        <div>
          <div className="row">
            <div className="col s10 push-s1 m6 push-m3 l6 push-l3">
              <div className="form card">
                <div className="card-content black-text">
                  <span className="card-title">RECUPERAR SENHA</span>
                    <div className="row">
                        <div className="col s12 m12 l10 push-l1">      
                            <blockquote>
                            <p >Será enviado um email para sua caixa de email com sua nova senha. Você pode trocar essa nova senha no seu painel de usuário</p>
                            </blockquote>
                        </div>
                    </div>
                  <form action="" name="login" onSubmit={this.recuperarSenha}>
                                <div className="row">
                                    <div className="input-field col s10 push-s1 m10 push-m1 l10 push-l1">
                                        <input id="email" type="email" className="validate col s12  m12  l12 " required
                                            name="email" value={email} onChange={this.changeHandler}/>
                                        <label htmlFor="email">Email</label>   
                                    </div>
                                </div>
                                  <div className="row">
                                    <div className="input-field col s10 push-s1 m10 push-m1 l10 push-l1">
                                        <InputMask id="cpf" type="text" className="validate col s12  m12  l12 " required  mask="999.999.999-99"
                                            name="cpf" value={cpf} onChange={this.changeHandler}/>
                                        <label htmlFor="cpf">CPF</label>   
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="input-field col s10 push-s1 m10 push-m1 l10 push-l1">
                                        <input id="dtNascimento" type="date" className="validate col s12  m12  l12 " required
                                            name="dtNascimento" value={dtNascimento} onChange={this.changeHandler}/>
                                        <label htmlFor="dtNascimento">Data de Nascimento</label>   
                                    </div>
                                  </div>
                                    <div className="row">    
                                        <button className="btn waves-effect waves-light col s12  m12  l12 " type="submit" name="action">Recuperar Senha</button>
                                    </div>
                                    <div className="progress" id="progress">
                                      <div className="indeterminate"></div>
                                  </div>
                            </form>
                </div>
                <div className="card-action">
                  <a href="/login">Login</a>
                  <a href="/cadastro">Cadastre-se</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        </body>
      );
    }

}
export default Esqueci;




