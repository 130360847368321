import React , {Component} from 'react';
import {Bar, Line, Pie, Polar} from 'react-chartjs-2';

class Chart extends Component{
    constructor(props){
        super(props);
        this.state={
            chartData:{
                labels: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul'],
                datasets:[
                    {
                       label:'Carteira QUATTRO',
                       data:[
                        6,
                        8,
                         15,
                        20,
                        26,
                        50,
                        85
                       ], 
                       backgroundColor:[
                               'rgba(6, 47, 79, 1)',
                               'rgba(6, 47, 79, 1)',
                               'rgba(6, 47, 79, 1)',
                               'rgba(6, 47, 79, 1)',
                               'rgba(6, 47, 79, 1)',
                               'rgba(6, 47, 79, 1)',
                               'rgba(6, 47, 79, 1)',
                       ],
                       animation: true,
                    }
                ]
            }
        }

    }
    static defaultProps = {
        displayTitle:true,
        displayLegend: true,
        legendPosiotion: 'right'
    }
    render(){
        return(
            <div className="chart">
                <Line
                    data={this.state.chartData}
                    option={{
                        title:{
                            display:this.props.displayTitle,
                            text: 'Carteira QUATTRO',
                            fontSize:25
                        },
                        legend:{
                            display:this.props.displayLegend,
                            position: this.props.legendPosiotion,
                        },
                        maintainAspectRatio: false
                    }}
                />
            </div>
        );
    }
}

export default Chart;