import React, { Component } from 'react';

//Paginas
//import App from '../../App';
import './index.css';

class NotFound extends Component{

    constructor(props){
      super(props);
        this.state = {
          carregou:'0',
          mensagem:'',
          status: '4',
          id: localStorage.getItem('idUsuario')
        }
    }

    exiteMensagem = () => {
        if( this.state.id !== "" && this.state.id != null){
            if(this.state.status === '1'){
                this.setState({"mensagem": "Aceite os Termos de Uso"});
            }
            if(this.state.status === '2'){
                this.setState({"mensagem": "Preencha completamente o Perfil"});
            }
            if(this.state.status === '3'){
                this.setState({"mensagem": "Faça o Primeiro Aporte para desfrutar do Curso"});
            }
            if(this.state.status > 3){
                this.setState({"mensagem": "Página não Encontrada"});
            }
            //window.location.href = "/";
        }else{
            if(this.state.id === "" || this.state.id === null){
                this.setState({"mensagem": "Usuário deslogado"});
            }
        }
        
        
    }

    render(){
        
        return(
            <div className="notFound">
                <h2>Quattro Fintech</h2>
                <hr />
                <h3>Qualquer Dúvida entre em contato </h3>
                <div className="box">
                    <p>
                        { this.state.mensagem === "" ?
                            this.exiteMensagem()
                          :  this.state.mensagem
                        }
                    </p>
                </div>
                <br/>
            </div>
        );
    }
}

export default NotFound;
