import React, { Component } from 'react';

//Paginas
import apiUrl from '../../axiosApi';
import './index.css';


import pageView from '../../utils/pageView';

class Informativo extends Component{
    constructor(props){
      super(props)
      this.state = {        
        carregou:'0',
        mensagem:'',
        id: '',
        duvida: '',
        status: '',
        urlVideo: 'https://www.youtube.com/embed/paHZ3Aeq-cA',
      }
      
      pageView.log('informativo');
    }

    changeHandler = e => {
      this.setState({[e.target.name]: e.target.value})
    }

    cadastrarDuvida = async e => {
      this.setState({"mensagem": ""})
      e.preventDefault();
      await apiUrl.post('/informativo', {
          idAluno: this.state.id,
          duvida: this.state.duvida,
          status: this.state.status
      })
      .then(res => {
        //Resposta do Servidor
        this.setState({"mensagem": JSON.stringify(res.data)});
        this.setState({"duvida": ""})
      })
      .catch(error => {
        this.setState({"mensagem": JSON.stringify(error)});
        this.setState({"duvida": ""})
      })
    }
    componentDidMount(){
      const M = window.M;
      document.addEventListener('DOMContentLoaded', function() {
        var elems = document.querySelectorAll('.collapsible');
        var instances = M.Collapsible.init(elems, {});
      });
    }
    
    render(){
      return( 
        <div className="informativo">
          <div className="row">
              <div className="col s10 push-s1 m4 l5">      
                <blockquote>
                    <p className="flow-text"> Vídeos novos todas as terças e quintas na nossa plataforma e aos domingos no nosso canal no <a target="#" rel="noopener noreferrer" href="https://www.youtube.com/channel/UC6OBlD9wvUxn9K-f5UWHN1w">Youtube</a>.</p>
                    <p className="flow-text"> Siga nossa página no <a target="#" rel="noopener noreferrer" href="https://www.instagram.com/quattrofintech/">Instagram</a> e fique sempre por dentro de tudo que está acontecendo.</p>
                  </blockquote>
              </div>
              <div className="col s12 m8 push-m1 l5 push-l1">      
                <iframe title="Video de Informações"  src={this.state.urlVideo} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
              </div>
            </div>
          <div className="row">
            <div className="col s10 push-s1 m10 push-m1 l10 push-l1"> 
              <ul className="collapsible">
                <li>
                  <div className="collapsible-header">Como fazer um aporte?</div>
                  <div className="collapsible-body">
                    <ol>
                      <li>Vá em página <a href="/aporte" target="_blank">Aporte</a></li>
                      <li>Faça uma transferência para nossa conta com uma conta de sua titularidade (Seu CPF).</li>
                      <li>Preencha o formulário com o valor e a data da transferência, selecionando a opção APORTE.</li>
                      <li>Aguarde a confirmação do recebimento do depósito.</li>
                      <li>Após confirmado você ja poderá usar seu aporte com 7% de lucro.</li>
                        Obs.:
                        <li>Poderá demorar até 3 dias utéis para a confirmação do depósito.</li>
                        <li>Transferências de titularidades diferentes serão devolvidas (Descontando possíveis taxas bancárias).</li>
                    </ol>
                  </div>
                </li>
                <li>
                  <div className="collapsible-header">Como funciona o cashback?</div>
                  <div className="collapsible-body">
                    <ol>
                      <li>Ao adquirir o curso, você pode receber até 107% de volta ao final do contrato.</li>
                      <li><a href="/produtos">Adquirir Curso</a>.</li>
                    </ol>
                  </div>
                </li>
                <li>
                  <div className="collapsible-header">Não sei nada de investimento, o curso serve pra mim?</div>
                  <div className="collapsible-body">
                    <ol>
                      <li>Sim! Nosso curso foi pensado do básico ao avançado</li>
                      <li>e assim receber alunos de todos os níveis.</li>
                    </ol>
                  </div>
                </li>
                <li>
                  <div className="collapsible-header">Como funciona o indique amigos?</div>
                  <div className="collapsible-body">
                    <ol>
                      <li>Ao <a href="/indiqueamigos" target="_blank">Indicar Amigos</a> você tem direito a receber 1% do primeiro aporte
                      do seu indicado.</li>
                      <li>Este valor pode ser retirado ao fim de um ano ou investido em produtos da plataforma.</li>
                        Obs.:
                        <li>Recebidos de Indicações não rendem durante o ano.</li></ol>
                  </div>
                </li>
                <li>
                  <div className="collapsible-header">Como adquirir os cursos?</div>
                  <div className="collapsible-body">
                    <ol>
                      <li>No nosso <a href="/produtos" target="_blank">catálogo de produtos</a> você pode adquirir o curso por QTC 1000,00.</li>
                      <li>Os QTC 1000,00 não serão descontados da sua carteira.</li>
                        Obs.:
                        <li>O curso tem valor de QTC 1000,00 que pode ser retirado ao final de um ano ou utilizado para adquirir novos produtos.</li></ol>
                  </div>
                </li>
                <li>
                  <div className="collapsible-header">Como é o curso de investimento?</div>
                  <div className="collapsible-body">
                    <ol>
                      <li>O curso de <a href="/cursos/1">Invesimento</a> é separado por módulos do básico ao avançado.</li>
                      <li><a href="/produtos">Adquirir Curso</a>.</li>
                    </ol>
                  </div>
                </li>
                <li>
                  <div className="collapsible-header">Como é o curso de negócios?</div>
                  <div className="collapsible-body">
                    <ol>
                      <li>O curso de <a href="/cursos/2">Negócios</a> é separado por módulos do básico ao avançado.</li>
                      <li><a href="/produtos">Adquirir Curso</a>.</li>
                    </ol>
                  </div>
                </li>
                <li>
                  <div className="collapsible-header">Onde consigo atendimento para falar com um atendente?</div>
                  <div className="collapsible-body">
                    <ol>
                      <li>Você consegue ser atendido em:</li>
                      <li>Nosso email suporte@investquattro.com.br</li>
                      <li>Ou em nosso whatsapp +55 21 99173-1632 clicando <a href="https://wa.me/5521991731632" target="_blank">aqui</a>.</li>
                    </ol>
                  </div>
                </li>
            </ul>
            </div>
          </div>
        </div>
    );
}
}
export default Informativo;