import React, { Component } from 'react';

import moment from 'moment';
//Paginas
import apiUrl from '../../../axiosApi';
import './index.css';
/* img */


function nl2br(texto) {
  return texto.replace(new RegExp('\r?\n','g'), '<br />');
}

class AulaAdmin extends Component{
    constructor(props){
      super(props)
      this.state = {        
        carregou:'0',
        mensagem:'',
        id: '',
        lista:[],
        cursos: [],

        idCurso: 0,
        idVideo: 0,
        nomeVideo: "",
        descricaoVideo: "",
        textoVideo: "",
        temaVideo: "",
        urlVideo: "",
        duracaoVideo: "",
        linkBannerVideo: "",
        linkThumbnailVideo: "",
        tituloPDFVideo: "",
        linkPDFVideo: "",
        numeroVideo: "",
        videoAtivo: true,
        publicacaoVideo: true,
        aulasCadastradas: "1",
        ultimaAula:"0",
        
        idAovivo: 0,
        tituloAovivo: '',
        descricaoAovivo: '',
        linkVideoAovivo: '',
        codigoCursoAovivo: '',
        dataAovivo: '',            
        aulasAoVivo: [],

        alterarIdAovivo: 0,
        alterarTituloAovivo: '',
        alterarDescricaoAovivo: '',
        alterarLinkVideoAovivo: '',
        alterarCodigoCursoAovivo: '',
        alterarDataAovivo: '',     
      }

      this.listarCursos();
      this.listarAulas(0);
      this.listarAulasAoVivo();
      
    }

    changeHandler = e => {
      this.setState({[e.target.name]: e.target.value})
      if(e.target.name == "idCurso"){
        this.pegaUltimoNumeroVideoCadastrado();
      }
    }
    listarCursos = () =>{
      apiUrl.get("/cursos/resumo")
      .then(res => {
          this.setState({ 
            cursos: res.data
          });

          console.log(res.data);
      })
      .catch(error => {
        console.log(error);
      })
    }
    listarAulas = (cod) =>{
      apiUrl.get("/cursos")
      .then(res => {
          console.log(res.data);
          for(var i = 0; i < res.data.length; i++){
            if(res.data[i].id == cod){
              this.setState({ lista: JSON.parse(JSON.stringify(res.data[i].aulas))});
              break;
            }
          }
          
      })
      .catch(error => {
        this.toastMensagem(error);
      })
    }
    chamaAulasCadastradas = e =>{      
      e.preventDefault();
      this.listarAulas(this.state.aulasCadastradas);
      /*this.toastMensagem(this.state.aulasCadastradas);
      if(this.state.aulasCadastradas == '1'){
        console.log("Investimento")
        this.listarAulas(0);
      }else if(this.state.aulasCadastradas == '3'){
        console.log("Dev Full Stack")
        this.listarAulas(1);
      }else if(this.state.aulasCadastradas == '2'){
        console.log("Negócio")
        this.listarAulas(2);
      }
      if(this.state.aulasCadastradas == '3'){
        console.log("Grátis")
      }*/
    }
    limparCampos = () => {
      this.setState({"idVideo": ""});          
      this.setState({"nomeVideo": ""})
      this.setState({"temaVideo": ""})
      this.setState({"descricaoVideo": ""})
      this.setState({"textoVideo": ""})
      this.setState({"urlVideo": ""})
      this.setState({"duracaoVideo": ""})
      this.setState({"linkBannerVideo": ""})
      this.setState({"linkThumbnailVideo": ""})
      this.setState({"tituloPDFVideo": ""})
      this.setState({"linkPDFVideo": ""})
      this.setState({"numeroVideo": ""})
      this.setState({"videoAtivo": ""})
      this.setState({"idCurso": ""})
      // true == CURSO , flase == Resumido (GRÁTIS)
      this.setState({"publicacaoVideo": true})
    }
    limparCamposAulaAoVivo = () => {
      this.setState({ 
        idAovivo: '0',
        tituloAovivo: "",
        descricaoAovivo: "",
        linkVideoAovivo: "",
        dataAovivo: "",
        alterarIdAovivo: '0',
        alterarTituloAovivo: '',
        alterarDescricaoAovivo: '',
        alterarLinkVideoAovivo: '',
        alterarDataAovivo: '',     
        alterarCodigoCursoAovivo: '',     
        codigoCursoAovivo: '',     
      });
    }
    alterarVideo = e =>{
      e.preventDefault();
      const numero = e.target.id;
      const curso = this.state.aulasCadastradas;
      this.toastMensagem("Curso: "  + curso + " Numero: " + numero)
      apiUrl.get(`/curso/${curso}/aula/${numero}`).then( res => {
          console.log(res.data.id);
          this.setState({"idVideo": res.data.id});          
          this.setState({"nomeVideo": res.data.nome})
          this.setState({"temaVideo": res.data.temas})
          this.setState({"descricaoVideo": res.data.descricao})
          this.setState({"textoVideo": res.data.texto})
          this.setState({"urlVideo": res.data.urlVideo})
          this.setState({"duracaoVideo": res.data.duracao})
          this.setState({"linkBannerVideo": res.data.urlBanner})
          this.setState({"linkThumbnailVideo": res.data.urlThumbnail})
          this.setState({"tituloPDFVideo": res.data.pdf})
          this.setState({"linkPDFVideo": res.data.urlPdf})
          this.setState({"numeroVideo": res.data.numero})
          this.setState({"videoAtivo": res.data.ativo})
          this.setState({"idCurso": res.data.idCurso})
          // true == CURSO , flase == Resumido (GRÁTIS)
          this.setState({"publicacaoVideo": true})
      })
      .catch( error => {
          console.log("Buscar Aula");
          console.log(error);
          this.setState({"mensagem": "Erro ao carregar os cursos"});
      });
    }
    pegaUltimoNumeroVideoCadastrado = async () =>{
      //e.preventDefault();
      await apiUrl.get("/cursos")
      .then(res => {
          console.log(res.data);
          console.log(res.data[1].aulas.length);
          if(this.state.idCurso == "1"){
            this.setState({"numeroVideo": res.data[0].aulas.length+1});
          }
          if(this.state.idCurso == "2"){
            this.setState({"numeroVideo": res.data[1].aulas.length+1}); 
          }
      })
      .catch(error => {
        alert(error);
      })
    }
    cadastrarAlterarAulas = async e => {
      e.preventDefault();      
      this.toastMensagem("Id VIDEO: " + this.state.idVideo + this.state.videoAtivo);
      var ativo = true;
      if(this.state.videoAtivo == "false"){
        ativo = false
      }else if(this.state.videoAtivo == "true"){
        ativo = true;
      }
      console.log(e.target.id)
      const idAula = this.state.idVideo;
      const curs = this.state.idCurso;
      console.log("Curso: " + curs + " Aula: " + idAula);
      if(this.state.idVideo == 0){
        await apiUrl.put(`/aula`, {        
          idCurso: this.state.idCurso,
          nome: this.state.nomeVideo,
          descricao: this.state.descricaoVideo,
          numero: this.state.numeroVideo,
          ativo: ativo,
          duracao: this.state.duracaoVideo,        
          temas: this.state.temaVideo,
          texto: this.state.textoVideo,
          urlVideo: this.state.urlVideo,
          urlPdf: this.state.linkPDFVideo,
          urlBanner: this.state.linkBannerVideo,
          urlThumbnail: this.state.linkThumbnailVideo,
        })
        .then(res => {
          //Resposta do Servidor
          this.setState({"mensagem":  "Vídeo Atualizado"});
          console.log("Dados Cadastrados")        
          this.toastMensagem("Vídeo Cadastrado");
          this.limparCampos();
        })
        .catch(error => {        
          this.toastMensagem("Error ao cadastrar o Vídeo");
          this.setState({"mensagem": "Error ao atualizar o Vídeo"});
          console.log(error);
        })
      }else{
        await apiUrl.put(`/aula`, {   
          id: parseInt(this.state.idVideo),     
          idCurso: this.state.idCurso,
          nome: this.state.nomeVideo,
          descricao: this.state.descricaoVideo,
          numero: this.state.numeroVideo,
          ativo: ativo,
          duracao: this.state.duracaoVideo,        
          temas: this.state.temaVideo,
          texto: this.state.textoVideo,
          urlVideo: this.state.urlVideo,
          urlPdf: this.state.linkPDFVideo,
          urlBanner: this.state.linkBannerVideo,
          urlThumbnail: this.state.linkThumbnailVideo,
        })
        .then(res => {
          //Resposta do Servidor
          this.setState({"mensagem":  "Vídeo Atualizado"});
          console.log("Dados Cadastrados")        
          this.toastMensagem("Vídeo Atualizado com Sucesso");
          this.limparCampos();
        })
        .catch(error => {        
          this.toastMensagem("Error ao atualizar o Vídeo");
          this.setState({"mensagem": "Error ao atualizar o Vídeo"});
          console.log(error);
        })
      }
    }
    componentDidMount(){
      const M = window.M;
      document.addEventListener('DOMContentLoaded', function() {
        var elems = document.querySelectorAll('.collapsible');
        var instances = M.Collapsible.init(elems, {});
      });
      document.addEventListener('DOMContentLoaded', function() {
        var elems = document.querySelectorAll('.modal');
        var instances = M.Modal.init(elems, {});
      })
      
      document.addEventListener('DOMContentLoaded', function() {
        var elems = document.querySelectorAll('.tabs');
        var instance = M.Tabs.init(elems, {});
      });

      document.addEventListener('DOMContentLoaded', function() {
        var elems = document.querySelectorAll('select');
        var instances = M.FormSelect.init(elems, {});
      });
      document.addEventListener('DOMContentLoaded', function() {
        var elems = document.querySelectorAll('.materialboxed');
        var instances = M.Materialbox.init(elems, {});
      });
      document.addEventListener('DOMContentLoaded', function() {
        var elems = document.querySelectorAll('.scrollspy');
        var instances = M.ScrollSpy.init(elems, {
          scrollOffset: 150,
        });
      });
      
    }
    cadastrarAtualizarAulaAovivo = async e => {
      e.preventDefault();
      //this.toastMensagem(this.state.idAovivo);
      
      
      
      let _body = {}
      if ( this.state.alterarIdAovivo != 0 ){
        _body = {
          id: this.state.alterarIdAovivo,
          titulo: this.state.alterarTituloAovivo,
          descricao: this.state.alterarDescricaoAovivo,
          link: this.state.alterarLinkVideoAovivo,
          idCurso: this.state.alterarCodigoCursoAovivo || null,
          data: moment(this.state.alterarDataAovivo),
        };
        await apiUrl.post(`/video-live/`, _body )
      .then(res => {
        //Resposta do Servidor
        this.toastMensagem("Aula Ao vivo Alterada com sucesso")
        console.log("result: ")
        console.log(res.data)

      })
      .catch(error => {      
          this.toastMensagem("Erro ao ALTERAR a Aula Ao vivo. Error: " + error);
          console.log(error);
      })
      }else{
        _body = {
          titulo: this.state.tituloAovivo,
          descricao: this.state.descricaoAovivo,
          link: this.state.linkVideoAovivo,
          idCurso: this.state.codigoCursoAovivo || null,
          data: this.state.dataAovivo,
        };
        await apiUrl.post(`/video-live/`, _body)
      .then(res => {
        //Resposta do Servidor
        this.toastMensagem("Aula Ao vivo Cadastrada com sucesso");
        console.log("result: ")
        console.log(res.data)
      })
      .catch(error => { 
        this.toastMensagem("Erro ao CADASTRAR a Aula Ao vivo. Error: " + error);
        console.log(error);
      })
    }
        
      this.listarAulasAoVivo();
      this.limparCamposAulaAoVivo();
    }
    listarAulasAoVivo = () => {
      this.setState({ 
        aulasAoVivo: [],
      });
      apiUrl.get("/video-live")
      .then(res => {
          console.log("Aulas Ao VIVO");
          console.log(res.data);
          
          this.setState({ 
            aulasAoVivo: res.data, 
          });
          
      })
      .catch(error => {
        console.log(error);
      })
    }
    btnAlterarAulaAoVivo = async e =>{  
      e.preventDefault();
      var id = e.target.id;
      apiUrl.get("/video-live/")
      .then(res => {
          for(var i = 0; i < res.data.length; i++){
            if(res.data[i].id == id){   
              this.toastMensagem("Achou, data: " + res.data[i].data);
              this.setState({ 
                alterarIdAovivo: parseInt(res.data[i].id),
                alterarTituloAovivo: res.data[i].titulo,
                alterarDescricaoAovivo: res.data[i].descricao,
                alterarLinkVideoAovivo: res.data[i].link,
                alterarCodigoCursoAovivo: res.data[i].idCurso,
                alterarDataAovivo: res.data[i].data,     
              });
            }
          }         
      })
      .catch(error => {
        console.log(error);
      })
    }
    toastMensagem(message){
      const M = window.M;
      M.toast({html: message, classes: 'rounded'})
    }

    render(){
      const {lista, idVideo, cursos, nomeVideo, temaVideo, descricaoVideo, textoVideo,
      urlVideo, duracaoVideo, linkBannerVideo, linkThumbnailVideo, tituloPDFVideo, 
      linkPDFVideo, numeroVideo, videoAtivo, publicacaoVideo, idCurso, idAovivo, 
      tituloAovivo, descricaoAovivo, linkVideoAovivo, codigoCursoAovivo, dataAovivo, aulasAoVivo, alterarIdAovivo, 
      alterarTituloAovivo, alterarDescricaoAovivo, alterarLinkVideoAovivo, alterarCodigoCursoAovivo, alterarDataAovivo, alterarAulasAoVivo} = this.state;

      return( 
        <div className="aulaAdmin"> 
          <div className="row">
            <ul className="collection with-header">
              <li className="collection-header"><h3>Aulas do Curso</h3></li>
                <ul className="collapsible ">
                    <li className="collection-item">
                      <div className="collapsible-header">
                       <h5>Cadastrar</h5>
                      </div>
                      <div className="collapsible-body">
                      <form name="cadastrarAlterarAulas" onSubmit={this.cadastrarAlterarAulas}>
                  <ul className="collection cadastro">
                    <div className="row">
                      <div className="row">
                        <div className="input-field col s10 m8 push-m2">
                          <select name="idCurso" id="idCurso" className="browser-default" value={idCurso} onChange={this.changeHandler} required>
                            <option value="0" disabled>Curso</option>
                            <option value="1">Investimento</option>
                            <option value="2">Negócio</option>
                            <option value="3">Dev Full Stack</option>
                          </select>
                          <label className="active" htmlFor="idCurso" >Selecione o curso do vídeo</label>   
                        </div>
                      </div>
                      <div className="row">
                        <div className="input-field col s10 m8 push-m2">
                          <input id="idVideo" type="number" placeholder="Codigo do Vídeo" disabled className="col s8"
                              name="idVideo" value={idVideo} onChange={this.changeHandler}/>
                          <label className="active" htmlFor="idVideo" >ID</label>   
                        </div>
                      </div>
                      <div className="row">
                        <div className="input-field col s10 m8 push-m2">
                          <input id="nomeVideo" type="text" placeholder="Titulo do Vídeo" required
                              name="nomeVideo" value={nomeVideo} onChange={this.changeHandler}/>
                          <label className="active" htmlFor="nomeVideo" >Título</label>   
                        </div>
                      </div>
                      <div className="row">
                        <div className="input-field col s10 m8 push-m2">
                          {idCurso == "1" ?
                            <select id="temaVideo" name="temaVideo" className="browser-default" value={temaVideo} onChange={this.changeHandler} required>
                              <option value="Introdução">Introdução</option>
                              <option value="Construção de conceito, desenvolvimento">Construção de conceito, desenvolvimento</option>
                              <option value="Tipos de investimentos">Tipos de investimentos</option>
                              <option value="Custos">Custos</option>
                              <option value="Perfil do investidor">Perfil do investidor</option>
                              <option value="Introdução ao avançado">Introdução ao avançado</option>
                              <option value="Renda variável">Renda variável</option>
                            </select>
                          :
                          idCurso == "2" ?
                            <select id="temaVideo" name="temaVideo" className="browser-default" value={temaVideo} onChange={this.changeHandler} required>
                              <option value="Introdução">Introdução</option>
                              <option value="Planejamento">Planejamento</option>
                              <option value="Contratos">Contratos</option>
                              <option value="Abrindo o CNPJ">Abrindo o CNPJ</option>
                              <option value="Marketing">Marketing</option>
                              <option value="Máquina de venda">Máquina de venda</option>
                              <option value="Ferramentas">Ferramentas</option>
                              <option value="Custos">Custos</option>
                              <option value="Escala">Escala</option>
                            </select>
                        :
                          ""
                        }  
                          <label className="active" htmlFor="temaVideo" >Tema</label>   
                        </div>
                      </div>
                      <div className="row">
                        <div className="input-field col s10 m8 push-m2">
                          <textarea id="descricaoVideo" placeholder="Descrição do Vídeo" required
                             name="descricaoVideo" value={descricaoVideo} onChange={this.changeHandler}></textarea>
                          <label className="active" htmlFor="descricaoVideo" >Descrição</label>   
                        </div>
                      </div>
                      <div className="row">
                        <div className="input-field col s10 m8 push-m2">
                          <textarea  id="textoVideo" placeholder="Texto do Vídeo" required
                            name="textoVideo" value={textoVideo} onChange={this.changeHandler}></textarea>
                          <label className="active" htmlFor="textoVideo" >Texto</label>   
                        </div>
                      </div>
                      <div className="row">
                        <div className="input-field col s10 m8 push-m2">
                          <input id="urlVideo" type="text" placeholder="URl com /embed/" required
                            name="urlVideo" value={urlVideo} onChange={this.changeHandler}/>
                          <label className="active" htmlFor="idCurso" >URL Vídeo</label>   
                        </div>
                      </div>
                      <div className="row">
                        <div className="input-field col s10 m8 push-m2">
                          <input id="duracaoVideo" type="number" placeholder="Duração do Vídeo Sem Pontos" required
                            name="duracaoVideo" value={duracaoVideo} onChange={this.changeHandler}/>
                          <label className="active" htmlFor="duracaoVideo" >Duração do Vídeo Ex.: 9:40 = 940</label>   
                        </div>
                      </div>
                      <div className="row">
                        <div className="input-field col s10 m8 push-m2">
                          <input id="linkBannerVideo" type="text" placeholder="https://quattro.com/banner/banner02.jpg" required
                            name="linkBannerVideo" value={linkBannerVideo} onChange={this.changeHandler}/>
                          <label className="active" htmlFor="linkBannerVideo" >Link do Banner</label>   
                        </div>
                      </div>
                      <div className="row">
                        <div className="input-field col s10 m8 push-m2">
                          <input id="linkThumbnailVideo" type="text" placeholder="https://quattro.com/thumbnail/thumbnail01.jpg" required 
                            name="linkThumbnailVideo" value={linkThumbnailVideo} onChange={this.changeHandler}/>
                          <label className="active" htmlFor="linkThumbnailVideo" >Link da Thumbnail</label>   
                        </div>
                      </div>
                      <div className="row">
                        <div className="input-field col s10 m8 push-m2">
                          <input id="tituloPDFVideo" type="text" placeholder="Tesouro Direto" required
                           name="tituloPDFVideo" value={tituloPDFVideo} onChange={this.changeHandler}/>
                          <label className="active" htmlFor="tituloPDFVideo" >Título do PDF</label>   
                        </div>
                      </div>
                      <div className="row">
                        <div className="input-field col s10 m8 push-m2">
                          <input id="linkPDFVideo" type="text" placeholder="https://quattro.com/pdf/tesouroPre.pdf" required
                            name="linkPDFVideo" value={linkPDFVideo} onChange={this.changeHandler}/>
                          <label className="active" htmlFor="linkPDFVideo" >Link do PDF</label>   
                        </div>
                      </div>
                      <div className="row">
                        <div className="input-field col s10 m8 push-m2">
                          <input id="numeroVideo" type="text" placeholder="05" required 
                            name="numeroVideo" value={numeroVideo} onChange={this.changeHandler}/>
                          <label className="active" htmlFor="numeroVideo" >Número da Aula</label>   
                        </div>
                      </div>
                      <div className="row">
                        <div className="input-field col s10 m8 push-m2">
                          <select id="videoAtivo" name="videoAtivo" className="browser-default" value={videoAtivo} onChange={this.changeHandler} required>
                            <option value="true">Sim</option>
                            <option value="false">Não</option>
                          </select>
                          <label className="active" htmlFor="videoAtivo" >Deixar Aula Visível?</label>   
                        </div>
                      </div>
                      <div className="row">
                        <div className="input-field col s10 m8 push-m2">
                          <select id="publicacaoVideo" name="publicacaoVideo" className="browser-default" value={publicacaoVideo} onChange={this.changeHandler} required>
                            <option value="true">Curso</option>
                            <option value="false">Grátis</option>
                          </select>
                          <label className="active" htmlFor="idCurso" >Vídeo do Curso ou Grátis?</label>   
                        </div>
                      </div>
                      <div className="row">
                        <div className="input-field col s10 m8 push-m2">
                          <input type="Submit" id="Cadastrar" name="Cadastrar" defaultValue="Cadastrar" /> 
                        </div>
                      </div>

                    </div>
                  </ul>
                  </form>
                      </div>
                    </li>
                </ul>
                <ul className="collapsible ">
                    <li className="collection-item">
                      <div className="collapsible-header">
                       <h5>Aulas</h5>
                      </div>
                      <div className="collapsible-body">
                      <select name="aulasCadastradas" className="browser-default" value={this.state.aulasCadastradas} onChange={this.changeHandler} onClick={this.chamaAulasCadastradas}>
                      <option value="1">Investimento</option>
                      <option value="2">Negócio</option>
                      <option value="3">Dev Full Stack</option>
                      <option value="4">Grátis</option>
                    </select>
                        <ul className="collapsible">
                          {lista.map(lista => (  
                            <li key = {lista.id}>
                              <div className="collapsible-header" key = {lista.id}><h4>{lista.nome}</h4></div>
                              <div className="collapsible-body teste1">
                                <ul className="collection">
                                  <li className="collection-item">Id Aula: : {lista.id + " "} Curso: {lista.idCurso == 1 ? "Investimento":"Negócio"}</li>
                                  <li className="collection-item">Data Cadastro: {lista.dataCadastro + " "} Aula: {lista.numero}</li>
                                  <li className="collection-item">Ativo: {lista.ativo+""} </li>
                                  <li className="collection-item">Duração: {lista.duracao/100}min </li>
                                  <li className="collection-item">Descrição: {lista.descricao}</li>
                                  <li className="collection-item">Tema: {lista.temas}</li>
                                  <li className="collection-item">Texto: {lista.texto}</li>
                                  <li className="collection-item">PDF: {lista.pdf}</li>
                                  <li className="collection-header"><h4>Links</h4></li>                                  
                                  <li className="collection-item">Video: {lista.urlVideo}</li>
                                  <li className="collection-item">PDF: {lista.urlPdf}</li>                                  
                                  <li className="collection-header"><h4>Imagens</h4></li>  
                                  <li className="collection-item">Thumbnail <img className="materialboxed responsive-img " src={lista.urlThumbnail} /></li>
                                  <li className="collection-item">Banner <img className="materialboxed responsive-img " src={lista.urlBanner} /></li>                          
                                  <li className="collection-header"><h4>Funções</h4></li>  
                                  <li className="collection-item"><a className="waves-effect waves-light btn modal-trigger" href="#modal" name={""+lista.numero} id={""+lista.numero} onClick={this.alterarVideo}>Alterar</a>
                                  </li>
                                </ul>
                              </div>                          
                            </li>                          
                          ))}
                       </ul>
                      </div>
                    </li>
                </ul>
            </ul>  
          </div>  

          {/*MODAL AULAS */}
          <div id="modal" className="modal modal-fixed-footer">
            <form className="section scrollspy col s10 push-s1 m8 push-m2 l6 push-l3"
            name="alterarAulas" id="alterarAulas" onSubmit={this.cadastrarAlterarAulas}>
              <div className="modal-content">
                <h4>Atualizar</h4>
                <div className="row">
                <div className="row">
                        <div className="input-field col s10 m8 push-m2">
                          <select name="idCurso" id="idCurso" className="browser-default" value={idCurso} onChange={this.changeHandler} required>
                            <option value="0" disabled>Curso</option>
                            <option value="1">Investimento</option>
                            <option value="2">Negócio</option>
                            <option value="3">Dev Full Stack</option>
                          </select>
                          <label className="active" htmlFor="idCurso" >Selecione o curso do vídeo</label>   
                        </div>
                      </div>
                      <div className="row">
                        <div className="input-field col s10 m8 push-m2">
                          <input id="idVideo" type="number" placeholder="Codigo do Vídeo" disabled className="col s8"
                              name="idVideo" value={idVideo} onChange={this.changeHandler}/>
                          <label className="active" htmlFor="idVideo" >ID</label>   
                        </div>
                      </div>
                      <div className="row">
                        <div className="input-field col s10 m8 push-m2">
                          <input id="nomeVideo" type="text" placeholder="Titulo do Vídeo" required
                              name="nomeVideo" value={nomeVideo} onChange={this.changeHandler}/>
                          <label className="active" htmlFor="nomeVideo" >Título</label>   
                        </div>
                      </div>
                      <div className="row">
                        <div className="input-field col s10 m8 push-m2">
                          {idCurso == "1" ?
                            <select id="temaVideo" name="temaVideo" className="browser-default" value={temaVideo} onChange={this.changeHandler} required>
                              <option value="Introdução">Introdução</option>
                              <option value="Construção de conceito, desenvolvimento">Construção de conceito, desenvolvimento</option>
                              <option value="Tipos de investimentos">Tipos de investimentos</option>
                              <option value="Custos">Custos</option>
                              <option value="Perfil do investidor">Perfil do investidor</option>
                              <option value="Introdução ao avançado">Introdução ao avançado</option>
                              <option value="Renda variável">Renda variável</option>
                            </select>
                          :
                          idCurso == "2" ?
                            <select id="temaVideo" name="temaVideo" className="browser-default" value={temaVideo} onChange={this.changeHandler} required>
                              <option value="Introdução">Introdução</option>
                              <option value="Planejamento">Planejamento</option>
                              <option value="Contratos">Contratos</option>
                              <option value="Abrindo o CNPJ">Abrindo o CNPJ</option>
                              <option value="Marketing">Marketing</option>
                              <option value="Máquina de venda">Máquina de venda</option>
                              <option value="Ferramentas">Ferramentas</option>
                              <option value="Custos">Custos</option>
                              <option value="Escala">Escala</option>
                            </select>
                        :
                          ""
                        }  
                          <label className="active" htmlFor="temaVideo" >Tema</label>   
                        </div>
                      </div>
                      <div className="row">
                        <div className="input-field col s10 m8 push-m2">
                          <textarea id="descricaoVideo" placeholder="Descrição do Vídeo" required
                             name="descricaoVideo" value={descricaoVideo} onChange={this.changeHandler}></textarea>
                          <label className="active" htmlFor="descricaoVideo" >Descrição</label>   
                        </div>
                      </div>
                      <div className="row">
                        <div className="input-field col s10 m8 push-m2">
                          <textarea  id="textoVideo" placeholder="Texto do Vídeo" required
                            name="textoVideo" value={textoVideo} onChange={this.changeHandler}></textarea>
                          <label className="active" htmlFor="textoVideo" >Texto</label>   
                        </div>
                      </div>
                      <div className="row">
                        <div className="input-field col s10 m8 push-m2">
                          <input id="urlVideo" type="text" placeholder="URl com /embed/" required
                            name="urlVideo" value={urlVideo} onChange={this.changeHandler}/>
                          <label className="active" htmlFor="idCurso" >URL Vídeo</label>   
                        </div>
                      </div>
                      <div className="row">
                        <div className="input-field col s10 m8 push-m2">
                          <input id="duracaoVideo" type="number" placeholder="Duração do Vídeo Sem Pontos" required
                            name="duracaoVideo" value={duracaoVideo} onChange={this.changeHandler}/>
                          <label className="active" htmlFor="duracaoVideo" >Duração do Vídeo Ex.: 9:40 = 940</label>   
                        </div>
                      </div>
                      <div className="row">
                        <div className="input-field col s10 m8 push-m2">
                          <input id="linkBannerVideo" type="text" placeholder="https://quattro.com/banner/banner02.jpg" required
                            name="linkBannerVideo" value={linkBannerVideo} onChange={this.changeHandler}/>
                          <label className="active" htmlFor="linkBannerVideo" >Link do Banner</label>   
                        </div>
                      </div>
                      <div className="row">
                        <div className="input-field col s10 m8 push-m2">
                          <input id="linkThumbnailVideo" type="text" placeholder="https://quattro.com/thumbnail/thumbnail01.jpg" required 
                            name="linkThumbnailVideo" value={linkThumbnailVideo} onChange={this.changeHandler}/>
                          <label className="active" htmlFor="linkThumbnailVideo" >Link da Thumbnail</label>   
                        </div>
                      </div>
                      <div className="row">
                        <div className="input-field col s10 m8 push-m2">
                          <input id="tituloPDFVideo" type="text" placeholder="Tesouro Direto" required
                           name="tituloPDFVideo" value={tituloPDFVideo} onChange={this.changeHandler}/>
                          <label className="active" htmlFor="tituloPDFVideo" >Título do PDF</label>   
                        </div>
                      </div>
                      <div className="row">
                        <div className="input-field col s10 m8 push-m2">
                          <input id="linkPDFVideo" type="text" placeholder="https://quattro.com/pdf/tesouroPre.pdf" required
                            name="linkPDFVideo" value={linkPDFVideo} onChange={this.changeHandler}/>
                          <label className="active" htmlFor="linkPDFVideo" >Link do PDF</label>   
                        </div>
                      </div>
                      <div className="row">
                        <div className="input-field col s10 m8 push-m2">
                          <input id="numeroVideo" type="text" placeholder="05" required 
                            name="numeroVideo" value={numeroVideo} onChange={this.changeHandler}/>
                          <label className="active" htmlFor="numeroVideo" >Número da Aula</label>   
                        </div>
                      </div>
                      <div className="row">
                        <div className="input-field col s10 m8 push-m2">
                          <select id="videoAtivo" name="videoAtivo" className="browser-default" value={videoAtivo} onChange={this.changeHandler} required>
                            <option value="true">Sim</option>
                            <option value="false">Não</option>
                          </select>
                          <label className="active" htmlFor="videoAtivo" >Deixar Aula Visível?</label>   
                        </div>
                      </div>
                      <div className="row">
                        <div className="input-field col s10 m8 push-m2">
                          <select id="publicacaoVideo" name="publicacaoVideo" className="browser-default" value={publicacaoVideo} onChange={this.changeHandler} required>
                            <option value="true">Curso</option>
                            <option value="false">Grátis</option>
                          </select>
                          <label className="active" htmlFor="idCurso" >Vídeo do Curso ou Grátis?</label>   
                        </div>
                      </div>
                </div>
              </div>
              <div className="modal-footer">
                <button className="btn waves-effect waves-light col s10 m10 push-m1" type="submit" name="action">ATUALIZAR</button>
                <a className="modal-close btn waves-effect waves-light col s10 m10 push-m1">Cancelar</a>
              </div>            
            </form> 
          </div>      
       
          <div className="row section scrollspy" id="aoVivo">
            <ul className="collection with-header">
              <li className="collection-header"><h3>Aulas ao Vivo</h3></li>
                <ul className="collapsible ">
                    <li className="collection-item">
                      <div className="collapsible-header">
                       <h5>Cadastrar</h5>
                      </div>
                      <div className="collapsible-body">
                        <div className="row">
                              <form className="section scrollspy col s10 push-s1 m8 push-m2 l6 push-l3"
                            name="cdtMorningCall" id="cdtMorningCall" onSubmit={this.cadastrarAtualizarAulaAovivo}>
                            <div className="row">
                              <div className="input-field col s10 m8 push-m2">
                                <input id="idAovivo" type="text" className="validate" required placeholder="Id"
                                  name="idAovivo" value={idAovivo} onChange={this.changeHandler} disabled />
                                <label className="active" htmlFor="idAovivo" >ID</label>   
                              </div>
                            </div>
                            <div className="row">
                              <div className="input-field col s10 m8 push-m2">
                                <input id="tituloAovivo" type="text" className="validate" required placeholder="Titulo"
                                  name="tituloAovivo" value={tituloAovivo} onChange={this.changeHandler} />
                                <label className="active" htmlFor="tituloAovivo" >Titulo do Video</label>   
                              </div>
                            </div>
                            <div className="row">
                              <div className="input-field col s10 m8 push-m2">
                                <textarea id="descricaoAovivo" className="validate" required placeholder=" Texto . . ."
                                  name="descricaoAovivo" value={descricaoAovivo} onChange={this.changeHandler} data-length="5000" maxLength="5000">
                                </textarea >
                                <label htmlFor="descricaoAovivo">Descrição</label>
                              </div>
                            </div>
                            <div className="row">
                              <div className="input-field col s10 m8 push-m2">
                                <input id="linkVideoAovivo" type="text" className="validate" required placeholder="Link"
                                  name="linkVideoAovivo" value={linkVideoAovivo} onChange={this.changeHandler} />
                                <label className="active" htmlFor="linkVideoAovivo" >Codigo do Link YT</label>   
                              </div>
                            </div>
                            <div className="input-field col s10 m8 push-m2"> 
                              <label className="active" htmlFor="codigoCursoAovivo" >Codigo do Curso</label>                              
                              <select onChange={this.changeHandler} name="codigoCursoAovivo" id="codigoCursoAovivo" className="browser-default" required>
                                <option disabled selected>Escolha o Curso</option>
                                <option value="">Livre</option>
                                {cursos.map(c =>(
                                  <option key={c.id} value={c.id}>{c.nome}</option>
                                ))}
                              </select>
                            </div>
                            <div className="row">
                              <div className="input-field col s10 m8 push-m2">
                                <input id="dataAovivo" type="datetime-local" className="validate"
                                  name="dataAovivo" value={dataAovivo} onChange={this.changeHandler}/>
                                <label htmlFor="dataAovivo"></label>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col s12 m12 l12">
                                <button className="btn waves-effect waves-light col s10 m10 push-m1" type="submit" name="action">CADASTRAR</button> 
                              </div>
                            </div>     
                          </form>
                        </div>
                      </div>
                    </li>
                </ul>
                <ul className="collapsible ">
                    <li className="collection-item">
                      <div className="collapsible-header">
                       <h5>Aulas</h5>
                      </div>
                      <div className="collapsible-body">
                      <table className="responsive-table">
                          <thead>
                            <tr>
                                <th>ID</th>
                                <th>PROGRAMADO PARA</th>
                                <th>TITULO</th>
                                <th>DESCRIÇÃO</th>
                                <th>LINK</th>
                                <th>CODIGO CURSO</th>
                            </tr>
                          </thead>
                          <tbody>
                            {aulasAoVivo.map(c => 
                                          // <p key={c.id} className="white-text" dangerouslySetInnerHTML={{ __html: `${moment(c.data).format('DD/MM/YYYY')} - ${nl2br(c.texto)}`}}></p>            
                            <tr key={c.id}>
                              <td>{c.id}</td>
                              <td>{moment(c.data).format('DD/MM/YYYY HH:mm')}</td>
                              <td>{c.titulo}</td>
                              <td><p className="white-black" dangerouslySetInnerHTML={{ __html: `${nl2br(c.descricao)}`}}></p></td>
                              <td>{c.link}</td>
                              <td>{c.idCurso}</td>
                              <td><a className="waves-effect waves-light btn modal-trigger" href="#modal1" name={c.id} id={c.id} onClick={this.btnAlterarAulaAoVivo}>Alterar</a></td>
                            </tr> )}
                          </tbody>
                        </table>
                      </div>
                    </li>
                </ul>
            </ul>  
          </div>  
          {/*MODAL AULA AO VIVO */}
          <div id="modal1" className="modal modal-fixed-footer">
            <form className="section scrollspy col s10 push-s1 m8 push-m2 l6 push-l3"
            name="cdtMorningCall" id="cdtMorningCall" onSubmit={this.cadastrarAtualizarAulaAovivo}>
              <div className="modal-content">
                <h4>Atualizar</h4>
                <div className="row">
                  <div className="row">
                    <div className="input-field col s10 m8 push-m2">
                      <input id="alterarIdAovivo" type="text" className="validate" required placeholder="Id"
                        name="alterarIdAovivo" value={alterarIdAovivo} onChange={this.changeHandler} disabled />
                      <label className="active" htmlFor="alterarIdAovivo" >ID</label>   
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="input-field col s10 m8 push-m2">
                    <input id="alterarTituloAovivo" type="text" className="validate" required placeholder="Titulo"
                      name="alterarTituloAovivo" value={alterarTituloAovivo} onChange={this.changeHandler} />
                    <label className="active" htmlFor="alterarTituloAovivo" >Titulo do Video</label>   
                  </div>
                </div>
                <div className="row">
                  <div className="input-field col s10 m8 push-m2">
                    <textarea id="alterarDescricaoAovivo" className="validate" required placeholder=" Texto . . ."
                      name="alterarDescricaoAovivo" value={alterarDescricaoAovivo} onChange={this.changeHandler} data-length="5000" maxLength="5000">
                    </textarea >
                    <label htmlFor="alterarDescricaoAovivo">Descrição</label>
                  </div>
                </div>
                <div className="row">
                  <div className="input-field col s10 m8 push-m2">
                    <input id="alterarLinkVideoAovivo" type="text" className="validate" required placeholder="Link"
                      name="alterarLinkVideoAovivo" value={alterarLinkVideoAovivo} onChange={this.changeHandler} />
                    <label className="active" htmlFor="alterarLinkVideoAovivo" >Codigo do Video do YT</label>   
                  </div>
                </div>
                <div className="row">
                  <div className="input-field col s10 m8 push-m2"> 
                    <label className="active" htmlFor="alterarCodigoCursoAovivo" >Codigo do Curso</label>                              
                    <select onChange={this.changeHandler} value={alterarCodigoCursoAovivo} name="alterarCodigoCursoAovivo" id="alterarCodigoCursoAovivo" className="browser-default" required>
                      <option disabled selected>Escolha o Curso</option>
                      <option value="">Livre</option>
                      {cursos.map(c =>(
                        <option key={c.id} value={c.id}>{c.nome}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="input-field col s10 m8 push-m2">
                    <input id="alterarDataAovivo" type="datetime-local" className="validate"
                      name="alterarDataAovivo" value={alterarDataAovivo} onChange={this.changeHandler}/>
                    <label htmlFor="alterarDataAovivo"></label>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button className="btn waves-effect waves-light col s10 m10 push-m1" type="submit" name="action">ATUALIZAR</button>
                <a className="modal-close btn waves-effect waves-light col s10 m10 push-m1">Cancelar</a>
              </div>            
            </form> 
          </div>  
                                
        </div>
    );
}
}
export default AulaAdmin;