import React, { Component } from 'react';
import moment from 'moment';

//Paginas
import apiUrl from '../../../axiosApi';
import './index.css';
/* img */



function nl2br(texto) {
  return texto.replace(new RegExp('\r?\n','g'), '<br />');
}
class CursoAdmin extends Component{
    constructor(props){
      super(props)
      this.state = {        
        carregou:'0',
        mensagem:'',
        id: '',
        lista:[],
        cursos:[],
      }
      this.listarCursos();
    }
    changeHandler = e => {
      this.setState({[e.target.name]: e.target.value})
      if(e.target.name == "idCurso"){
        this.pegaUltimoNumeroVideoCadastrado();
      }
    }

    listarCursos = () =>{
      apiUrl.get("/cursos/resumo")
      .then(res => {
          console.log(res.data);
          console.log(res.data[0].aulas);
          this.setState({cursos: res.data});
          let temas = {};
          for ( let c of res.data ) {
            if ( temas[ c.temas ] )
              temas[ c.temas ].push( c );
            else
              temas[ c.temas ] = [ c ]; 
          }

          this.setState({ 
            cursos: JSON.parse(JSON.stringify(res.data)),
            temas: temas,
          });

          console.log(temas);
      })
      .catch(error => {
        console.log(error);
      })
    }
    componentDidMount(){
      const M = window.M;
      document.addEventListener('DOMContentLoaded', function() {
        var elems = document.querySelectorAll('.collapsible');
        var instances = M.Collapsible.init(elems, {});
      });
      document.addEventListener('DOMContentLoaded', function() {
        var elems = document.querySelectorAll('.modal');
        var instances = M.Modal.init(elems, {});
      })
      
      document.addEventListener('DOMContentLoaded', function() {
        var elems = document.querySelectorAll('.tabs');
        var instance = M.Tabs.init(elems, {});
      });

      document.addEventListener('DOMContentLoaded', function() {
        var elems = document.querySelectorAll('select');
        var instances = M.FormSelect.init(elems, {});
      });
      document.addEventListener('DOMContentLoaded', function() {
        var elems = document.querySelectorAll('.materialboxed');
        var instances = M.Materialbox.init(elems, {});
      });
      
    }
    toastMensagem(message){
      const M = window.M;
      M.toast({html: message, classes: 'rounded'})
    }

    render(){
      const {idMorningCall, textoMorningCall, dataMorningCall, morningCall, lista, cursos} = this.state;

      return( 
        <div className="cursoAdmin"> 
          <div className="row">
            <ul className="collection with-header">
              <li className="collection-header"><h3>Curso </h3></li>
                <ul className="collapsible ">
                    <li className="collection-item">
                      <div className="collapsible-header">
                       <h5>Cadastrar (Indisponível)</h5>
                      </div>
                      <div className="collapsible-body">
                        <div className="row">
                          <form className="section scrollspy col s10 push-s1 m8 push-m2 l6 push-l3"
                            name="cdtMorningCall" id="cdtMorningCall" onSubmit={this.cadastrarMorningCall}>                        
                            <div className="row">
                              <div className="input-field col s10 m8 push-m2">
                                <input id="idMorningCall" type="number" className="validate" required placeholder="Id"
                                  name="idMorningCall" value={idMorningCall} onChange={this.changeHandler} disabled />
                                <label className="active" htmlFor="idMorningCall" >ID</label>   
                              </div>
                            </div>
                            <div className="row">
                              <div className="input-field col s10 m8 push-m2">
                                <textarea id="textoMorningCall" className="validate" required placeholder="Texto . . ."
                                  name="textoMorningCall" value={textoMorningCall} onChange={this.changeHandler} maxLength="5000">
                                </textarea >
                                <label htmlFor="textoMorningCall">Texto</label>
                              </div>
                            </div>
                            <div className="row">
                              <div className="input-field col s10 m8 push-m2">
                                <input id="dataMorningCall" type="date" className="validate"
                                  name="dataMorningCall" value={dataMorningCall} onChange={this.changeHandler}/>
                                <label htmlFor="dataMorningCall">Data</label>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col s12 m12 l12">
                                { idMorningCall == 0 ?
                                  <button className="btn waves-effect waves-light col s10 m10 push-m1" type="submit" name="action">CADASTRAR</button>
                                :
                                  <button className="btn waves-effect waves-light col s10 m10 push-m1" type="submit" name="action">ATUALIZAR</button>
                                }
                              </div>
                            </div> 
                          </form>    
                        </div>
                      </div>
                    </li>
                </ul>
                <ul className="collapsible ">
                    <li className="collection-item">
                      <div className="collapsible-header">
                       <h5>Cursos</h5>
                      </div>
                      <div className="collapsible-body">
                        <table className="responsive-table">
                          <thead>
                            <tr>
                                <th>ID</th>
                                <th>Status</th>
                                <th>Nome</th>
                                <th>Temas</th>
                                <th>Aulas</th>
                                <th>Data Cadastro</th>
                                <th>Thumbnail</th>
                                <th>Banner</th>
                                <th>Alterar</th>
                            </tr>
                          </thead>
                          <tbody>
                            {cursos.map(c => 
                                          // <p key={c.id} className="white-text" dangerouslySetInnerHTML={{ __html: `${moment(c.data).format('DD/MM/YYYY')} - ${nl2br(c.texto)}`}}></p>            
                            <tr key={c.id}>
                              <td>{c.id}</td>
                              <td>{c.ativo == true?"Disponível":"Indisponível"}</td>
                              <td>{c.nome}</td>
                              <td>{c.temas}</td>
                              <td>{c.aulas.length}</td>
                              <td>{moment(c.dataCadastro).format('DD/MM/YYYY')}</td>
                              <td>{c.urlBanner}</td>
                              <td>{c.urlThumbnail}</td>
                              <td><input type="button" value="Alterar" name={c.id} id={c.id} onClick={this.btnAtualizar}/> </td>
                            </tr> )}
                          </tbody>
                        </table>
                      </div>
                    </li>
                </ul>
            </ul>  
          </div> 
                 
        </div>
    );
}
}
export default CursoAdmin;