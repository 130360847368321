import apiUrl from '../axiosApi';
import config from '../config';

const pageView = {
    
    log: async function(pagina, parametros = null) {

        let body = {
            idUsuario: localStorage.getItem("idUsuario"),
            idLoginHistorico: localStorage.getItem("idSessao"),
            pagina: pagina,
            parametro: parametros,
            versaoApp: config.versaoApp,
        };

        await apiUrl.post(`/pageview`, body)
            .then(res => {
                //Resposta do Servidor
                //console.log(res);    
            })
            .catch(error => {
                console.log("Erro no PageView");
                console.log(error);
            })
    }

};


export default pageView