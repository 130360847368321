import React, { Component } from 'react';
class Logout extends Component{

    constructor(props) {
      super(props);
      this.state = {
        id:"",
      }  
  }
    render(){
        localStorage.removeItem('idUsuario')        
        localStorage.removeItem('idStatus');
        localStorage.setItem('idUsuario' , '0')
        localStorage.removeItem("X-Cookie-Status");
        document.location.replace('/login');
      return (
          <h2>Deslogando....</h2>
        );
    };
};
export default Logout;