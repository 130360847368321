import React, { Component } from 'react';
import {Bar, Line, Pie, Polar} from 'react-chartjs-2';

//Paginas
import apiUrl from '../../../axiosApi';
import './index.css';
/* img */



class RelatorioAdmin extends Component{
    constructor(props){
      super(props)
      this.state = {        
        carregou:'0',
        mensagem:'',
        id: '',
        lista:[],
        dashboard:[{
            hoje: '',
            semana: '',
            mes: '',
            total:''
          }],
        alunosTotal: '0',        
        alunosHoje: '0',        
        alunosMes: '0',        
        alunosSemana: '0',        
        aportesTotal: '0',        
        aportesHoje: '0',        
        aportesMes: '0',        
        aportesSemana: '0',
      }
      this.relatorio()
      this.listarAlunos();
      this.listarAportes();
    
    }

    changeHandler = e => {
      this.setState({[e.target.name]: e.target.value})
    }
    listarAlunos = () =>{
      apiUrl.get("/usuario")
      .then(res => {
          //console.log(res.data);
          this.alunos(res.data);
          //this.setState({ listaAlunos: JSON.parse(JSON.stringify(res.data))});
      })
      .catch(error => {
        alert(error);
      })
    }
    alunos = (data) =>{
      var date = new Date();
      var hoje = 0;
      var semana = 0;
      var mes = 0;
      const total = data.length;
      //console.log(date.formateDate('dd/mm/yy', new Date()));
      //console.log(data.length);
      //console.log(this.alunos.total);
      let today = new Date().getDate();
      today +="/"+ new Date().getMonth();
      today +="/"+ new Date().getFullYear();
      let diaSemana = new Date().getDay();
      //console.log(today);
      for(var i = 0; i < data.length; i++){
        var cadastro = new Date(data[i].dadosCadastro.dataCadastro);
        var dataCadastro = new Date(data[i].dadosCadastro.dataCadastro).getDate();
        dataCadastro +="/"+ new Date(data[i].dadosCadastro.dataCadastro).getUTCMonth();
        dataCadastro +="/"+ new Date(data[i].dadosCadastro.dataCadastro).getFullYear();
        if(dataCadastro === today){
          //console.log("Hoje" + i);
          hoje ++;
        }
        if(dataCadastro.substring(3) === today.substring(3)){
          //console.log("Mes Cadastro: " + dataCadastro.substring(2,5));
          mes ++;
        }
        
        var difrencaDatas = Math.ceil(Math.abs(new Date().getTime() - cadastro.getTime())/ (1000 * 60 * 60 * 24));
        //console.log(data[i].dadosCadastro.dataCadastro);
        switch(diaSemana){
          case 0:
            if(difrencaDatas == 1){
              //console.log("Hoje: " + cadastro);
              semana ++;
            }
            break;
          case 1:
            if(difrencaDatas == 1 || difrencaDatas == 2){
              //console.log("Hoje" + i);
              semana ++;
            }
            break;
          case 2:
            if(difrencaDatas == 1 || difrencaDatas == 2 || difrencaDatas == 3){
              //console.log("Hoje" + i);
              semana ++;
            }
              break;
        case 3:
          if(difrencaDatas == 1 || difrencaDatas == 2 || difrencaDatas == 3 || difrencaDatas == 4){
            //console.log("Hoje" + i);
            semana ++;
          }
          break;
        case 4:
          if(difrencaDatas == 1 || difrencaDatas == 2 || difrencaDatas == 3 || difrencaDatas == 4 
            ||difrencaDatas == 5){
            //console.log("Hoje" + i);
            semana ++;
          }
          break;
        case 5:
          if(difrencaDatas == 1 || difrencaDatas == 2 || difrencaDatas == 3 || difrencaDatas == 4 
            ||difrencaDatas == 5 || difrencaDatas == 6){
            //console.log("Hoje" + i);
            semana ++;
          }
          break
        case 3:
          if(difrencaDatas == 1 || difrencaDatas == 2 || difrencaDatas == 3 || difrencaDatas == 4 
            ||difrencaDatas == 5 || difrencaDatas == 6 || difrencaDatas == 7){
            //console.log("Hoje" + i);
            semana ++;
          }
          break;
        }
      }
      this.setState({alunosHoje: hoje});
      this.setState({alunosMes: mes});
      this.setState({alunosSemana: semana});      
      this.setState({alunosTotal: total})
    }
    listarAportes = () => {
      try{
        apiUrl.get('/admin/aporte/novos')
        .then(res => {
            this.aportes(res.data);
            //this.setState({ lista: res.data});
        })
      }catch(error){
          this.setState({"mensagem":error});
      } 
    }
    aportes = (data) => {
      var date = new Date();
      var hoje = 0;
      var semana = 0;
      var mes = 0;
      const total = data.length;
      console.log(data);
      let today = new Date().getDate();
      today +="/"+ new Date().getMonth();
      today +="/"+ new Date().getFullYear();
      let diaSemana = new Date().getDay();
      //console.log(today);
      for(var i = 0; i < data.length; i++){
        var cadastro = new Date(data[i].dataCadastro);
        var dataCadastro = new Date(data[i].dataCadastro).getDate();
        dataCadastro +="/"+ new Date(data[i].dataCadastro).getUTCMonth();
        dataCadastro +="/"+ new Date(data[i].dataCadastro).getFullYear();
        if(dataCadastro === today){
          //console.log("Hoje" + i);
          hoje ++;
        }
        if(dataCadastro.substring(3) === today.substring(3)){
          //console.log("Mes Cadastro: " + dataCadastro.substring(2,5));
          mes ++;
        }
        
        var difrencaDatas = Math.ceil(Math.abs(new Date().getTime() - cadastro.getTime())/ (1000 * 60 * 60 * 24));
        //console.log(data[i].dadosCadastro.dataCadastro);
        switch(diaSemana){
          case 0:
            if(difrencaDatas == 1){
              //console.log("Hoje: " + cadastro);
              semana ++;
            }
            break;
          case 1:
            if(difrencaDatas == 1 || difrencaDatas == 2){
              //console.log("Hoje" + i);
              semana ++;
            }
            break;
          case 2:
            if(difrencaDatas == 1 || difrencaDatas == 2 || difrencaDatas == 3){
              //console.log("Hoje" + i);
              semana ++;
            }
              break;
        case 3:
          if(difrencaDatas == 1 || difrencaDatas == 2 || difrencaDatas == 3 || difrencaDatas == 4){
            //console.log("Hoje" + i);
            semana ++;
          }
          break;
        case 4:
          if(difrencaDatas == 1 || difrencaDatas == 2 || difrencaDatas == 3 || difrencaDatas == 4 
            ||difrencaDatas == 5){
            //console.log("Hoje" + i);
            semana ++;
          }
          break;
        case 5:
          if(difrencaDatas == 1 || difrencaDatas == 2 || difrencaDatas == 3 || difrencaDatas == 4 
            ||difrencaDatas == 5 || difrencaDatas == 6){
            //console.log("Hoje" + i);
            semana ++;
          }
          break
        case 6:
          if(difrencaDatas == 1 || difrencaDatas == 2 || difrencaDatas == 3 || difrencaDatas == 4 
            ||difrencaDatas == 5 || difrencaDatas == 6 || difrencaDatas == 7){
            //console.log("Hoje" + i);
            semana ++;
          }
          break;
        }
      }
        this.setState({aportesHoje: hoje});
        this.setState({aportesMes: mes});
        this.setState({aportesSemana: semana});      
        this.setState({aportesTotal: total})
    }
    relatorio = () => {
      this.setState({"dashboard.hoje": "5"})
      this.setState({"dashboard.semana": "5"})
      this.setState({"dashboard.mes": "5"})
      this.setState({"dashboard.total": "5"})

      this.setState({"carregou": "1"})
    }
    componentDidMount(){
      const M = window.M;
      document.addEventListener('DOMContentLoaded', function() {
        var elems = document.querySelectorAll('.collapsible');
        var instances = M.Collapsible.init(elems, {});
      });
      document.addEventListener('DOMContentLoaded', function() {
        var elems = document.querySelectorAll('.modal');
        var instances = M.Modal.init(elems, {});
      })
      
      document.addEventListener('DOMContentLoaded', function() {
        var elems = document.querySelectorAll('.tabs');
        var instance = M.Tabs.init(elems, {});
      });

      document.addEventListener('DOMContentLoaded', function() {
        var elems = document.querySelectorAll('select');
        var instances = M.FormSelect.init(elems, {});
      });
      document.addEventListener('DOMContentLoaded', function() {
        var elems = document.querySelectorAll('.materialboxed');
        var instances = M.Materialbox.init(elems, {});
      });
      document.addEventListener('DOMContentLoaded', function() {
        var elems = document.querySelectorAll('.scrollspy');
        var instances = M.ScrollSpy.init(elems, {
          scrollOffset: 50,
        });
      });
    }
    render(){
      const {carregou, dashboard} = this.state;
      
      return( 
        <div className="relatorioAdmin">
          <div className="row">
            <ul className="collection with-header">
              <li className="collection-header section scrollspy" id="dashboard"><h3>Dashboard</h3></li>
                <ul className="collapsible ">
                    <li className="collection-item">
                      <div className="collapsible-header">
                       <h5>Views</h5>
                      </div>
                      <div className="collapsible-body">
                        <table className="centered">
                          <thead>
                            <tr>
                                <th>Hoje</th>
                                <th>Semana</th>
                                <th>Mês</th>
                                <th>Total</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>0</td>
                              <td>0</td>
                              <td>0</td>
                              <td>0</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </li>
                </ul>
                <ul className="collapsible ">
                    <li className="collection-item">
                      <div className="collapsible-header">
                       <h5>Time</h5>
                      </div>
                      <div className="collapsible-body">
                        <table className="centered">
                          <thead>
                            <tr>
                                <th>Hoje</th>
                                <th>Semana</th>
                                <th>Mês</th>
                                <th>Total</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>0 minutos</td>
                              <td>0 minutos</td>
                              <td>0 minutos</td>
                              <td>0 minutos</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </li>
                </ul>
            </ul>  
          </div> 

          <div className="row">
            <ul className="collection with-header">
              <li className="collection-header section scrollspy" id="alunos"><h3>Alunos</h3></li>
                <ul className="collapsible ">
                    <li className="collection-item">
                      <div className="collapsible-header">
                       <h5>Cadastrados</h5>
                      </div>
                      <div className="collapsible-body">
                        <table className="centered">
                          <thead>
                            <tr>
                                <th>Hoje</th>
                                <th>Semana</th>
                                <th>Mês</th>
                                <th>Total</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{this.state.alunosHoje}</td>
                              <td>{this.state.alunosSemana}</td>
                              <td>{this.state.alunosMes}</td>
                              <td>{this.state.alunosTotal}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </li>
                </ul>
                <ul className="collapsible ">
                    <li className="collection-item">
                      <div className="collapsible-header">
                       <h5>Indicações</h5>
                      </div>
                      <div className="collapsible-body">
                        <table className="centered">
                          <thead>
                            <tr>
                                <th>Hoje</th>
                                <th>Semana</th>
                                <th>Mês</th>
                                <th>Total</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>0</td>
                              <td>0</td>
                              <td>0</td>
                              <td>0</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </li>
                </ul>
            </ul>  
          </div>

          <div className="row">
            <ul className="collection with-header">
              <li className="collection-header section scrollspy" id="aporte"><h3>Aportes</h3></li>
                <ul className="collapsible ">
                    <li className="collection-item">
                      <div className="collapsible-header">
                       <h5>QuattroCoins</h5>
                      </div>
                      <div className="collapsible-body">
                        <table className="centered">
                          <thead>
                            <tr>
                                <th>Hoje</th>
                                <th>Semana</th>
                                <th>Mês</th>
                                <th>Total</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{this.state.aportesHoje}</td>
                              <td>{this.state.aportesSemana}</td>
                              <td>{this.state.aportesMes}</td>
                              <td>{this.state.aportesTotal}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </li>
                </ul>
                <ul className="collapsible ">
                    <li className="collection-item">
                      <div className="collapsible-header">
                       <h5>Produtos</h5>
                      </div>
                      <div className="collapsible-body">
                        <table className="centered">
                          <thead>
                            <tr>
                                <th>Hoje</th>
                                <th>Semana</th>
                                <th>Mês</th>
                                <th>Total</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>0</td>
                              <td>0</td>
                              <td>0</td>
                              <td>0</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </li>
                </ul>
            </ul>  
          </div>
        
        </div>
    );
    }
}
export default RelatorioAdmin;