import React, { Component } from 'react';

import {Sidenav, Dropdown} from 'materialize-css';
//Paginas
import './menu.css';
import M from  'materialize-css/dist/js/materialize.min.js';

// Imagens
import logoImg from '../assets/miniLogo.png';

class Menu extends Component{
    
    componentDidMount(){
        //const M = window.M;
        document.addEventListener('DOMContentLoaded', function() {
            var elems = document.querySelectorAll('.sidenav');
            var instances = M.Sidenav.init(elems, {});
          });
        document.addEventListener('DOMContentLoaded', function() {
          var elems = document.querySelectorAll('.dropdown-trigger');
          var instances = M.Dropdown.init(elems, 
            {
              alignment: "center",
              hover: false, 
              inDuration: 10,
              coverTrigger: false,
            });
            }
        );
    }
    
    render() {
        return(
            <div>
                <div id="menu" className="navbar-fixed">
                <nav>
                    <div className="nav-wrapper #01579b light-blue darken-4">
                        <a href="#" data-target="slide-out" className="sidenav-trigger">&#9776;</a>
                        <div className="row">
                            <div className="col s1 push-s4 m1 push-m5 l1">
                                <a href="/" className="brand-logo left">
                                    <img src={logoImg} alt="Logo QUATTRO FINTECH" />
                                </a>
                            </div>
                            <div className="col m8 pull-m1 l10">
                                <ul className="right hide-on-med-and-down">
                                    <li><a className="menuSidenav" href="/">Home</a></li>
                                    <li><a className="menuSidenav" href="/cursos">Cursos</a></li>
                                    <li><a className="menuSidenav" href="/aporte">Aporte</a></li>
                                    <li><a className="menuSidenav" href="/informativo">Informativo</a></li>
                                    {/*<li><a className="menuSidenav" href="/suporte">Suporte</a></li>*/}
                                    <li><a className="menuSidenav" className="dropdown-trigger" href="#!" data-target="aluno">Aluno</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
                <ul id="slide-out" className="sidenav">
                    <li><a className="menuSidenav" href="/">Home</a></li>
                    <li><a className="menuSidenav" href="/cursos">Cursos</a></li>
                    <li><a className="menuSidenav" href="/produtos">Produtos</a></li>
                    <li><a className="menuSidenav" href="/aporte">Aporte</a></li>
                    <li><a className="menuSidenav" href="/informativo">Informativo</a></li>
                    {/*<li><a className="menuSidenav" href="/suporte">Suporte</a></li>*/}
                    <li><a className="menuSidenav" href="/perfil">Perfil</a></li>
                    <li><a className="menuSidenav" href="/carteira">Carteira</a></li>
                    <li><a className="menuSidenav" href="/indiqueamigos">Indique Amigos</a></li>
                    <li><a className="menuSidenav" href="/logout">Sair</a></li>
                </ul>
                <ul id="aluno" className="dropdown-content">
                    <li><a href="/perfil">Perfil</a></li>
                    <li><a href="/carteira">Carteira</a></li>
                    <li className="divider" tabIndex="-1"></li>
                    <li><a href="/logout">Sair</a></li>
                </ul>             
        </div>
        );
    };
};
export default Menu;