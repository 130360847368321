import React, { Component } from 'react';
import moment from 'moment';
import CurrencyInput from 'react-currency-masked-input'

import apiUrl from '../../../axiosApi';
import './index.css';


class CarteiraQuattro extends Component{
    constructor(props){
      super(props)
      this.state = {        
        carregou:'0',
        mensagem:'',

        idAtivo: '',
        codigoAtivo: '',
        nomeAtivo: '',
        tipoAtivo: '',
        listaTipoAtivos: ['Ação', 'FII', 'Renda Fixa', 'ETFs'],
        listaAtivos:[],

        listaCarteira:[],
        listaCarteiraResumida:[],

        movimentacaoIdAtivo: '',
        movimentacaoTipoMovimentacao: '',
        listaTipoMovimentacao: [ 'C', 'V', 'D', 'T' ], // (compra, venda, dividendos, taxas)
        movimentacaoValor: 0,
        movimentacaoQuantidade: '',
        movimentacaoData: '',
        listaMovimentacoes: [],
      }

      this.listarAtivos();
      this.listarCarteira();
      this.listarMovimentacoes();
    }

    changeHandler = e => {
      this.setState({[e.target.name]: e.target.value})
    }

    listarAtivos = (cod) =>{
      apiUrl.get("/carteira-quattro/ativo")
      .then(res => {
          console.log("Ativos");
          console.log(res.data);

          this.setState({ listaAtivos: res.data });
      })
      .catch(error => {
        this.toastMensagem(error);
      })
    }
    listarCarteira = (cod) =>{
      apiUrl.get("/carteira-quattro/carteira")
      .then(res => {
          console.log("Carteira");
          console.log(res.data);

          this.setState({ listaCarteira: res.data });
      })
      .catch(error => {
        this.toastMensagem(error);
      })

      apiUrl.get("/carteira-quattro/carteira/resumido")
      .then(res => {
          console.log("Carteira Resumida");
          console.log(res.data);

          this.setState({ listaCarteiraResumida: res.data });
      })
      .catch(error => {
        this.toastMensagem(error);
      })
    }
    listarMovimentacoes = (cod) =>{
      apiUrl.get("/carteira-quattro/movimentacao")
      .then(res => {
          console.log("Movimentações");
          console.log(res.data);

          this.setState({ listaMovimentacoes: res.data });
      })
      .catch(error => {
        this.toastMensagem(error);
      })
    }
    cadastrarAlterarAtivo = async e => {
      e.preventDefault();      

      this.toastMensagem("Id Ativo: " + this.state.idAtivo);

      let ativo = {
        codigo: this.state.codigoAtivo,
        nome: this.state.nomeAtivo,
        tipo: this.state.tipoAtivo,
      };

      if (this.state.idAtivo)
        ativo.id = this.state.idAtivo;

      console.log("Ativo: ");
      console.log(ativo);

      //await apiUrl.put(`/carteira-quattro/ativo`, ativo)
      apiUrl.put(`/carteira-quattro/ativo`, ativo)
      .then(res => {
        this.setState({"mensagem":  "Ativo Atualizado"});       
        this.toastMensagem("Ativo Atualizado");

        console.log("Ativo Cadastrado/Atualizado");
        console.log(res.data);

        this.limparCamposAtivos();
        this.listarAtivos();
      })
      .catch(error => {        
        this.toastMensagem("Error ao cadastrar Ativo");
        this.setState({"mensagem": "Error ao atualizar Ativo"});
        console.log(error);
      });
    }
    cadastrarMovimentacao = async e => {
      e.preventDefault();      

      /*
        movimentacaoIdAtivo: '',
        movimentacaoTipoMovimentacao: '',
        listaTipoMovimentacao: [ 'C', 'V', 'D', 'T' ], // (compra, venda, dividendos, taxas)
        movimentacaoValor: 0,
        movimentacaoQuantidade: 0,
        movimentacaoData: '',
        listaMovimentacoes: [],
      */

      let movimentacao = {
        idAtivo: this.state.movimentacaoIdAtivo,
        tipoMovimentacao: this.state.movimentacaoTipoMovimentacao,
        valor: parseInt(this.state.movimentacaoValor*1000),
        data: moment(this.state.movimentacaoData).format(),
      };
      if ( this.state.movimentacaoQuantidade )
        movimentacao.quantidade = parseInt(this.state.movimentacaoQuantidade);

      console.log(" >> Movimentacao: ");
      console.log(movimentacao);

      //await apiUrl.post(`/carteira-quattro/movimentacao`, movimentacao)
      apiUrl.post(`/carteira-quattro/movimentacao`, movimentacao)
      .then(res => {
        this.setState({"mensagem":  "Movimentação Cadastrada"});       
        this.toastMensagem("Movimentação Cadastrada");

        console.log("Movimentação Cadastrado");
        console.log(res.data);

        this.limparCamposMovimentacao();
        this.listarCarteira();
        this.listarMovimentacoes();
      })
      .catch(error => {        
        this.toastMensagem("Error ao cadastrar Movimentacão");
        this.setState({"mensagem": "Error ao cadastrar movimentação"});
        console.log(error);
      });
    }
    limparCamposAtivos = () => {
      this.setState({
        idAtivo: '',
        codigoAtivo: '',
        nomeAtivo: '',
        tipoAtivo: '',
      });
    }
    limparCamposMovimentacao = () => {
      this.setState({
        movimentacaoIdAtivo: '',
        movimentacaoTipoMovimentacao: '',
        movimentacaoValor: '',
        movimentacaoQuantidade: 0,
        movimentacaoData: '',
      });
    }
    componentDidMount(){
      const M = window.M;
      document.addEventListener('DOMContentLoaded', function() {
        var elems = document.querySelectorAll('.collapsible');
        var instances = M.Collapsible.init(elems, {});
      });
      document.addEventListener('DOMContentLoaded', function() {
        var elems = document.querySelectorAll('.modal');
        var instances = M.Modal.init(elems, {});
      })
      
      document.addEventListener('DOMContentLoaded', function() {
        var elems = document.querySelectorAll('.tabs');
        var instance = M.Tabs.init(elems, {});
      });

      document.addEventListener('DOMContentLoaded', function() {
        var elems = document.querySelectorAll('select');
        var instances = M.FormSelect.init(elems, {});
      });
      document.addEventListener('DOMContentLoaded', function() {
        var elems = document.querySelectorAll('.materialboxed');
        var instances = M.Materialbox.init(elems, {});
      });
      
    }
    mascaraValor = (valor) =>{
      return valor.toFixed(2).replace(".", ",")
    }
    toastMensagem(message){
      const M = window.M;
      M.toast({html: message, classes: 'rounded'})
    }

    render(){

      const {idAtivo, codigoAtivo, nomeAtivo, tipoAtivo,
        listaTipoAtivos, listaAtivos, listaCarteira, listaCarteiraResumida,
        movimentacaoIdAtivo, movimentacaoTipoMovimentacao, listaTipoMovimentacao,  movimentacaoValor,
        movimentacaoQuantidade, movimentacaoData, listaMovimentacoes} = this.state;

      return ( 
        <div className="empConteudo">
          
          <ul className="collapsible">
            {/* Cadastro de Ativos */}
            <li>
              <div className="collapsible-header"><h3>Cadastrar Ativos</h3></div>
                <div className="collapsible-body">
                <form name="cadastrarAlterarAulas" onSubmit={this.cadastrarAlterarAtivo}>
                  <ul className="collection cadastro">
                    <div className="row">
                      <div className="input-field col s10 m8 push-m2">
                        <input id="idAtivo" type="number" className="validate" placeholder="Id"
                          name="idAtivo" value={idAtivo} onChange={this.changeHandler} disabled />
                        <label className="active" htmlFor="idAtivo" >ID</label>   
                      </div>
                    </div>
                    <div className="row">
                      <div className="input-field col s10 m8 push-m2">
                        <input id="nomeAtivo" type="text" className="validate" required placeholder="Nome"
                          name="nomeAtivo" value={nomeAtivo} onChange={this.changeHandler}  />
                        <label className="active" htmlFor="nomeAtivo" >Nome</label>   
                      </div>
                    </div>
                    <div className="row">
                      <div className="input-field col s10 m8 push-m2">
                        <input id="codigoAtivo" type="text" className="validate" required placeholder="TICKET"
                          name="codigoAtivo" value={codigoAtivo} onChange={this.changeHandler}  />
                        <label className="active" htmlFor="codigoAtivo" >Codigo</label>   
                      </div>
                    </div>
                    <div className="row">
                      <div className="input-field col s10 m8 push-m2">
                      <select name="tipoAtivo" className="browser-default" value={tipoAtivo} onChange={this.changeHandler} required>
                          <option value="" ></option>
                         {listaTipoAtivos.map( t => <option key={t} value={t}>{t}</option>)}
                      </select>
                        <label className="active" htmlFor="tipoAtivo" >Selecione o tipo do ativo</label>   
                      </div>
                    </div>
                    <button className="btn waves-effect waves-light col s10 m10 push-m1" type="submit" name="action">CADASTRAR</button>
                  </ul>
                </form>
              </div>
            </li>

            {/* Lista de Ativos */}
            <li>
              <div className="collapsible-header"><h3>Ativos</h3></div>
              <div className="collapsible-body">
                <ul className="collapsible">
                <a className="waves-effect waves-light btn modal-trigger" href="#movimentarAtivo">Movimentar</a>
                  <table className="ativos responsive-table striped centered">
                    <thead>
                      <tr>
                          <th>ID</th>
                          <th>Nome</th>
                          <th>Ticket</th>
                          <th>Atualizar</th>
                      </tr>
                    </thead>
                    <tbody>
                      {listaAtivos.map(ativo => (         
                        <tr key = {ativo.id}>
                          <td>{ativo.id}</td>
                          <td>{ativo.nome}</td>
                          <td>{ativo.codigo}</td>
                          <td><a className="waves-effect waves-light btn modal-trigger col s10 m10 push-m1"  href="#alterarAtivo">Alterar</a></td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </ul>
              </div>
            </li>


            {/* Cadastro de Movimentações */}
            <li>
              <div className="collapsible-header"><h3>Cadastrar Movimentação</h3></div>
                <div className="collapsible-body">
                <form name="cadastrarAlterarAulas" onSubmit={this.cadastrarMovimentacao}>
                  <ul className="collection cadastro">
                    <li className="collection-item col s5">
                      Selecione o ativo
                      <select name="movimentacaoIdAtivo" className="browser-default" value={movimentacaoIdAtivo} onChange={this.changeHandler} required>
                          <option value="" ></option>
                         {listaAtivos.map( l => <option key={l.id} value={l.id}>{l.codigo} - {l.nome}</option>)}
                      </select>
                    </li>
                    <li className="collection-item col s5">
                      Selecione o tipo de movimentacao
                      <select name="movimentacaoTipoMovimentacao" className="browser-default" value={movimentacaoTipoMovimentacao} onChange={this.changeHandler} required>
                          <option value="" ></option>
                         {listaTipoMovimentacao.map( t => <option key={t} value={t}>{t}</option>)}
                      </select>
                    </li>
                    <li className="collection-item">
                      Valor
                      <input type="text" placeholder="12300" required
                              name="movimentacaoValor" value={movimentacaoValor} onChange={this.changeHandler}/>
                    </li>
                    <li className="collection-item">
                      Quantidade
                      <input type="text" placeholder=""
                              name="movimentacaoQuantidade" value={movimentacaoQuantidade} onChange={this.changeHandler}/>
                    </li>
                    <li className="collection-item">
                      Data
                      <input type="text" placeholder="2020-01-01" required
                              name="movimentacaoData" value={movimentacaoData} onChange={this.changeHandler}/>
                    </li>

                    <button className="btn waves-effect waves-light col s10 m10 push-m1" type="submit" name="action">CADASTRAR</button>
                  </ul>
                </form>
              </div>
            </li>

            {/* Lista de Movimentações */}
            <li>
              <div className="collapsible-header"><h3>Movimentaçes</h3></div>
              <div className="collapsible-body">
                <ul className="collapsible">
                  <table className="ativos responsive-table striped centered">
                      <thead>
                        <tr>
                            <th>ID</th>
                            <th>Ticket</th>
                            <th>Tipo</th>
                            <th>VL UNT</th>
                            <th>QNT</th>
                            <th>Total</th>
                            <th>Data</th>
                        </tr>
                      </thead>
                      <tbody>
                        {listaMovimentacoes.map(movimentacao => (         
                          <tr key={movimentacao.id}>
                            <td>{movimentacao.id}</td>
                            <td>{movimentacao.ativo.codigo}</td>
                            <td>{movimentacao.tipoMovimentacao == 'C' ? "COMPRA" : movimentacao.tipoMovimentacao == 'V' ? "VENDA" : movimentacao.tipoMovimentacao == 'D' ? "DIVIDENDOS" : movimentacao.tipoMovimentacao == 'T' ? "TAXA" : ""}</td>
                            <td>R$ {this.mascaraValor(movimentacao.valor/100)}</td>
                            <td>{movimentacao.quantidade}</td>
                            <td>R$ {this.mascaraValor(movimentacao.quantidade * (movimentacao.valor/100))}</td>
                            <td>{moment(movimentacao.data).format("DD/MM/YYYY")}</td>
                            <td><button id={movimentacao.id} className="btn waves-effect waves-light col s10 m10 push-m1" type="submit" name="action">Alterar</button></td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  
                </ul>
              </div>
            </li>

            {/* Lista Carteira */}
            <li>
              <div className="collapsible-header"><h3>Carteira</h3></div>
              <div className="collapsible-body">
                <ul>
                <table className="ativos responsive-table striped centered">
                    <thead>
                      <tr>
                          <th>ID</th>
                          <th>Ticket</th>
                          <th>QNT</th>
                          <th>VL UNT</th>
                          <th>Total</th>
                          <th>Dividendos</th>
                          <th>Taxas</th>
                      </tr>
                    </thead>
                    <tbody>
                      {listaCarteira.map(carteira => (         
                        <tr key = {carteira.id}>
                          <td>{carteira.id}</td>
                          <td>{carteira.ativo.codigo}</td>
                          <td>{carteira.quantidade}</td>
                          <td>R$ {this.mascaraValor(carteira.valorCompra/100)}</td>
                          <td>R$ {this.mascaraValor(carteira.valorCompra/100 * carteira.quantidade)}</td>
                          <td>R$ {this.mascaraValor(carteira.dividendos/100)}</td>
                          <td>R$ {this.mascaraValor(carteira.taxas/100)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </ul>
              </div>
            </li>

            {/* Lista Carteira resumida */}
            <li>
              <div className="collapsible-header"><h3>Carteira Resumida</h3></div>
              <div className="collapsible-body">
                <ul >
                <table className="ativos responsive-table striped centered">
                    <thead>
                      <tr>
                          <th>Investimetno</th>
                          <th>Porcentagem</th>
                      </tr>
                    </thead>
                    <tbody>
                      {listaCarteiraResumida.map(carteira => (         
                        <tr key = {carteira.tipo}>
                          <td>{carteira.tipo}</td>
                          <td>{this.mascaraValor( carteira.porcentagem)}%</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </ul>
              </div>
            </li>
            
          </ul>
                        {/* Jabenal Movimentar Ativo */}
          <div id="movimentarAtivo" className="modal modal-fixed-footer">
            <form className="section scrollspy col s10 push-s1 m8 push-m2 l6 push-l3"
            name="cadastrarAlterarAulas" id="cadastrarAlterarAulas" onSubmit={this.cadastrarMovimentacao}>
              <div className="modal-content">
                <h4>Movimentar Ativo</h4>
                <div className="row">
                    <div className="row">
                      <div className="input-field col s10 m8 push-m2">
                      <select name="movimentacaoIdAtivo" className="browser-default" value={movimentacaoIdAtivo} onChange={this.changeHandler} required>
                          <option value="" >Escolha um ativo</option>
                         {listaAtivos.map( l => <option key={l.id} value={l.id}>{l.codigo} - {l.nome}</option>)}
                      </select>
                        <label className="active" htmlFor="movimentacaoIdAtivo">Ativo</label>   
                      </div>
                    </div>
                    <div className="row">
                      <div className="input-field col s10 m8 push-m2">
                      <select name="movimentacaoTipoMovimentacao" className="browser-default" value={movimentacaoTipoMovimentacao} onChange={this.changeHandler} required>
                          <option value="" >Selecione o tipo de movimentação</option>
                         {listaTipoMovimentacao.map( t => <option key={t} value={t}>{t}</option>)}
                      </select>
                        <label className="active" htmlFor="alterarTituloAovivo" >Movimentação</label>   
                      </div>
                    </div>
                    <div className="row">
                      <div className="input-field col s10 m8 push-m2">
                        <CurrencyInput id="movimentacaoValor" type="number" className="validate"  required
                          name="movimentacaoValor"  onChange={this.changeHandler}/>
                        <label htmlFor="movimentacaoValor">Valor Unitário</label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="input-field col s10 m8 push-m2">
                      <input type="number" placeholder=""
                              name="movimentacaoQuantidade" value={movimentacaoQuantidade} onChange={this.changeHandler}/>
                        <label className="active" htmlFor="movimentacaoQuantidade" >Quantidade</label>   
                      </div>
                    </div>
                    <div className="row">
                      <div className="input-field col s10 m8 push-m2">
                      <input type="date" placeholder="" required
                              name="movimentacaoData" value={movimentacaoData} onChange={this.changeHandler}/>
                        <label htmlFor="movimentacaoData">Data</label>
                      </div>
                    </div>
                </div>
              </div>
              <div className="modal-footer">
                <button className="btn waves-effect waves-light col s10 m10 push-m1" type="submit" name="action">Cadastrar</button>
                <a className="modal-close btn waves-effect waves-light col s10 m10 push-m1">Cancelar</a>
              </div>            
            </form> 
          </div>           

                         {/* Janela Alterar ativo */}
          <div id="alterarAtivo" className="modal modal-fixed-footer">
            <form className="section scrollspy col s10 push-s1 m8 push-m2 l6 push-l3"
            name="cadastrarAlterarAulas" id="cadastrarAlterarAulas" onSubmit={this.cadastrarMovimentacao}>
              <div className="modal-content">
                <h4>Movimentar Ativo</h4>
                <div className="row">
                    <div className="row">
                      <div className="input-field col s10 m8 push-m2">
                      <select name="movimentacaoIdAtivo" className="browser-default" value={movimentacaoIdAtivo} onChange={this.changeHandler} required>
                          <option value="" >Escolha um ativo</option>
                         {listaAtivos.map( l => <option key={l.id} value={l.id}>{l.codigo} - {l.nome}</option>)}
                      </select>
                        <label className="active" htmlFor="movimentacaoIdAtivo">Ativo</label>   
                      </div>
                    </div>
                    <div className="row">
                      <div className="input-field col s10 m8 push-m2">
                      <select name="movimentacaoTipoMovimentacao" className="browser-default" value={movimentacaoTipoMovimentacao} onChange={this.changeHandler} required>
                          <option value="" >Selecione o tipo de movimentação</option>
                         {listaTipoMovimentacao.map( t => <option key={t} value={t}>{t}</option>)}
                      </select>
                        <label className="active" htmlFor="alterarTituloAovivo" >Movimentação</label>   
                      </div>
                    </div>
                    <div className="row">
                      <div className="input-field col s10 m8 push-m2">
                        <CurrencyInput id="movimentacaoValor" type="number" className="validate"  required
                          name="movimentacaoValor"  onChange={this.changeHandler}/>
                        <label htmlFor="movimentacaoValor">Valor Unitário</label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="input-field col s10 m8 push-m2">
                      <input type="number" placeholder=""
                              name="movimentacaoQuantidade" value={movimentacaoQuantidade} onChange={this.changeHandler}/>
                        <label className="active" htmlFor="movimentacaoQuantidade" >Quantidade</label>   
                      </div>
                    </div>
                    <div className="row">
                      <div className="input-field col s10 m8 push-m2">
                      <input type="date" placeholder="" required
                              name="movimentacaoData" value={movimentacaoData} onChange={this.changeHandler}/>
                        <label htmlFor="movimentacaoData">Data</label>
                      </div>
                    </div>
                </div>
              </div>
              <div className="modal-footer">
                //<button className="btn waves-effect waves-light col s10 m10 push-m1" type="submit" name="action">Cadastrar</button>
                <a className="modal-close btn waves-effect waves-light col s10 m10 push-m1">Cancelar</a>
              </div>            
            </form> 
          </div> 
        </div>
      );
    }
}
export default CarteiraQuattro;