import React, { Component } from 'react';
import moment from 'moment';
//import { Select } from 'react-materialize';
import InputMask from 'react-input-mask';

import axios from 'axios';
//Paginas
import apiUrl from '../../axiosApi';
import './index.css';

import pageView from '../../utils/pageView';


const listaBancos = {
  '001': 'BANCO DO BRASIL',
  '033': 'BANCO SANTANDER',
  '077': 'BANCO INTER S.A.',
  '104': 'CAIXA ECON. FEDERAL',
  '208': 'BTG PACTUAL S.A.',
  '212': 'ORIGINAL S.A.',
  '237': 'BANCO BRADESCO S.A.',
  '260': 'NU PAGAMENTOS S.A.',
  '341': 'BANCO ITAÚ S.A.',
  '356': 'BANCO REAL S.A.(ANTIGO)',
  '389': 'BANCO MERC. DO BRASIL S.A.',
  '399': 'HSBC BANK S.A.',
  '422': 'BANCO SAFRA S.A.',
  '453': 'BANCO RURAL S.A',
  '633': 'BANCO RENDIMENTO S.A.',
  '652': 'ITAU HOLDING FIN. S.A',
  '735': 'BANCO NEON S.A.',
  '745': 'BANCO CITIBANK S.A.',
  '746': 'BANCO MODAL S.A.',
  '003': 'BASA',
  '004': 'BNB',
  '014': 'NATIXIS BRA. S.A. - BANCO MULT',
  '017': 'BNY MELLON S.A.',
  '019': 'AZTECA DO BRASIL S.A.',
  '021': 'BANESTES',
  '025': 'BANCO ALFA',
  '036': 'BEM',
  '037': 'BANCO DO ESTADO DO PARÁ S.A',
  '040': 'CARGILL S.A',
  '041': 'BANRISUL',
  '045': 'OPPORTUNITY S.A.',
  '047': 'BANESE',
  '062': 'HIPERCARD BANCO MULTIPLO S.A',
  '063': 'IBI',
  '065': 'BRACCE S.A.',
  '066': 'MORGAN STANLEY DEAN WITTER S.A',
  '069': 'BPN BRASIL BANCO MULTIPLO S.A',
  '070': 'BRB',
  '072': 'RURAL MAIS S.A',
  '074': 'J. SAFRA S.A.',
  '075': 'CR2 S.A',
  '076': 'KDB DO BRASIL S.A',
  '078': 'BES INVEST. DO BRA SA - BANCO DE INVESTIM.',
  '079': 'ORIGINAL DO AGRONEGOCIO S.A.',
  '081': 'BBN BANCO BRASILEIRO DE NEGOCIOS S.A',
  '082': 'TOPAZIO S.A.',
  '083': 'BANCO DA CHINA BRASIL S.A.',
  '084': 'CC UNIPRIME NORTE DO PARANA',
  '085': 'CECREDI',
  '087': 'UNICRED CENTRAL SANTA CATARINA',
  '088': 'RANDON S.A.',
  '089': 'COOP. DE CREDITO RURAL DA REGIAO DA MOGIANA',
  '090': 'COOP. CENTRAL DE CREDITO DO ESTADO DE SP',
  '091': 'UNICRED CENTRAL RS - CENTRAL DE COOP ECON CRED MUT',
  '092': 'BRICKELL S A CREDITO, FINANCIAMENTO E INVESTIMENTO',
  '094': 'PETRA S.A.',
  '096': 'BM&F DE SERV. DE LIQUIDACAO E CUSTODIA S.A',
  '097': 'COOPERATIVA CENTRAL DE CREDITO NOROESTE BRASILEIRO',
  '098': 'CREDIALIANCA COOPERATIVA DE CREDITO RURAL',
  '099': 'UNIPRIME CENTRAL - CENTRAL INT DE COOP DE CRED LTD',
  '107': 'BBM S.A',
  '112': 'CC UNICRED BRASIL CENTRAL',
  '114': 'CECOOPES-CENTRAL DAS COOP DE ECON E CRED MUTUO DO',
  '119': 'WESTERN UNION DO BRASIL S.A.',
  '120': 'RODOBENS S.A',
  '121': 'GERADOR S.A.',
  '122': 'BERJ S.A',
  '124': 'WOORI BANK DO BRASIL S.A',
  '125': 'BRASIL PLURAL S.A. BANCO MULTIPLO',
  '184': 'Itaú-BBA',
  '213': 'ARBI S.A.',
  '214': 'DIBENS S.A.',
  '217': 'JOHN DEERE S.A.',
  '218': 'BONSUCESSO S.A.',
  '222': 'CREDIT AGRICOLE BRASIL S.A.',
  '224': 'FIBRA',
  '225': 'BRASCAN S.A.',
  '230': 'UNICARD BANCO MULTIPLO S.A',
  '233': 'CIFRA S.A.',
  '241': 'Banco Classico S.A',
  '243': 'MAXIMA S.A.',
  '246': 'ABC-BRASIL S.A.',
  '248': 'BOA VISTA INTERATLANTICO S.A',
  '249': 'Investcred Unibanco',
  '250': 'BCV - BANCO DE CREDITO E VAREJO S.A',
  '254': 'PARANA BANCO S.A.',
  '263': 'CACIQUE S.A.',
  '265': 'FATOR S.A.',
  '266': 'CEDULA S.A.',
  '300': 'BANCO DE LA NACION ARGENTINA',
  '318': 'BMG S.A.',
  '320': 'BIC BANCO ',
  '366': 'SOCIETE GENERALE BRASIL S.A',
  '370': 'BANCO WESTLB DO BRASIL',
  '376': 'J.P. MORGAN S.A.',
  '394': 'BMC S.A.',
  '412': 'CAPITAL S.A.',
  '456': 'BANCO TOKYO MITSUBISH UFJ BRASIL S.A',
  '464': 'SUMITOMO MITSUI BRASILEIRO S.A.',
  '473': 'CAIXA GERAL - BRASIL S.A.',
  '477': 'Citibank N.A.',
  '487': 'DEUTSCHE BANK S. A. - BANCO ALEMAO',
  '488': 'JPMORGAN CHASE BANK',
  '492': 'ING BANK N.V.',
  '494': 'BANCO DE LA REPUBLICA ORIENTAL DEL URUGUAY',
  '495': 'BANCO DE LA PROVINCIA DE BUENOS AIRES',
  '505': 'CREDIT SUISSE (BRASIL) S.A.',
  '600': 'Luso Brasileiro',
  '604': 'INDUSTRIAL DO BRASIL S. A.',
  '610': 'VR S.A.',
  '611': 'PAULISTA',
  '612': 'GUANABARA S.A.',
  '613': 'PECUNIA S.A.',
  '623': 'PANAMERICANO',
  '626': 'FICSA S.A.',
  '630': 'INTERCAP S.A.',
  '634': 'Triangulo',
  '637': 'SOFISA S.A.',
  '643': 'PINE S.A.',
  '653': 'INDUSVAL S.A.',
  '654': 'A.J. RENNER S.A.',
  '655': 'VOTORANTIM',
  '707': 'DAYCOVAL',
  '719': 'BANIF-BANCO INTERN. DO FUNCHAL (BRASIL) S.A',
  '739': 'BGN S.A.',
  '740': 'BARCLAYS S.A.',
  '741': 'RIBEIRÃO PRETO',
  '743': 'SEMEAR S.A.',
  '747': 'RABOBANK INTERNATIONAL BRASIL S.A.',
  '748': 'SICREDI',
  '749': 'SIMPLES S.A.',
  '751': 'SCOTIABANK BRASIL S.A BANCO MULTIPLO',
  '752': 'BNP PARIBAS BRASIL S.A',
  '753': 'NBC BANK BRASIL S.A.- BANCO MULTIPLO',
  '755': 'BANK OF AMER. MERRILL LYNCH BANCO MULT. S.A.',
  '756': 'BANCOOB',
  '757': 'KEB DO BRASIL S.A.',
}

const listaEstados = ['AC','AL','AP','AM','BA','CE','DF','ES','GO','MA','MT','MS','MG','PA','PB','PR','PE','PI','RJ','RN','RS','RO','RR','SC','SP','SE','TO'];

class Perfil extends Component{

    constructor(props){
      super(props);
      this.state = {
        carregou:'0',
        mensagem:'',
        id: localStorage.getItem('idUsuario'),
        nome: '',
        senha: '',
        email: '',
        cpf: '',
        telefone: '',
        dtCadastro: '',
        dtNascimento: '',
        icon:'',
        status:'',
        cep: '',
        rua: '',
        numero: '',
        complemento: '',
        bairro: '',
        cidade: '',
        estado: '',
        codigoBanco: '',
        banco: '',
        tipo: '',
        conta: '',
        contaDv: '',
        agencia: '',
        agenciaDv: '',
      };
      this.getHttp();      
      //this.listaSelectEstados();
      //this.listaSelectBancos();

      pageView.log('perfil');
    }

    changeHandler = e => {
      this.setState({[e.target.name]: e.target.value})
    }
    getHttp = () =>{
      const {id} = this.state;

      apiUrl.get(`/usuario/${id}/`).then( res => {
        console.log(res.data);
        //Dados Pessoais
        this.setState({"id": res.data.id});
        this.setState({"nome": res.data.nome});
        this.setState({"cpf": res.data.cpf});
        this.setState({"email": res.data.dadosCadastro.email});
        this.setState({"telefone": res.data.dadosCadastro.telefone || ""});
        this.setState({"dtCadastro": res.data.dadosCadastro.dataCadastro});
        this.setState({"dtNascimento": res.data.dadosCadastro.dataNascimento || ""});
        this.setState({"sexo": res.data.dadosCadastro.sexo || ""});
        this.setState({"status": "2"});
        this.setState({"icon": ""});

        //Endereço
        if ( res.data.endereco ) {
          this.setState({"cep": res.data.endereco.cep || ""});
          this.setState({"rua": res.data.endereco.endereco || ""});
          this.setState({"numero": res.data.endereco.numero || ""});
          this.setState({"complemento": res.data.endereco.complemento || ""});
          this.setState({"bairro": res.data.endereco.bairro || ""});
          this.setState({"cidade": res.data.endereco.cidade || ""});
          this.setState({"estado": res.data.endereco.estado || ""});
        }
        
        //Banco
        if ( res.data.dadosBancarios.length > 0 ) {
          let conta = res.data.dadosBancarios[0];
          this.setState({"idConta": conta.id || ""});
          this.setState({"codigoBanco": conta.codigoBanco || ""});
          this.setState({"banco": listaBancos[conta.codigoBanco || ""]});
          this.setState({"tipo": conta.tipoConta || ""});
          this.setState({"agencia": conta.agencia || ""});
          this.setState({"agenciaDv": conta.agenciaDv || ""});
          this.setState({"conta": conta.conta || ""});
          this.setState({"contaDv": conta.contaDv || ""});
        }
        
        //Iniciando Componentes
        //this.listaSelectBancos();
        //this.listaSelectEstados();
        this.iniciaCheckbox();

           //Carrega Dados
        this.setState({"carregou": "1"});
        this.setState({"mensagem": ""});

        })
        .catch( error => {
            console.log(error);
            this.toastMensagem("Erro ao trazer dados do usuário, faça login novamente. Error: " + error);
            this.setState({"mensagem": "Erro ao carregar os Dados Pessoais. Faça login Novamente."});
        });
    }
    atualizarDadosPessoais = async e => {
      e.preventDefault();
      this.setState({"mensagem": ""}) 
      
      const {id} = this.state;
      var tel = this.state.telefone.replace('(', '').replace(')', '').replace(' ', '').replace('-', '');
      await apiUrl.put(`/usuario/${id}`, {
        nome: this.state.nome,
        email: this.state.email,        
        senha: this.state.senha,
        dtNascimento: this.state.dtNascimento,
        cpf: this.state.cpf,
        telefone: tel,
      })
      .then(res => {
        //Resposta do Servidor
        this.setState({"mensagem":  res.data.nome + " Atualizado"});
        this.atualizarDadosLogin();
        this.toastMensagem("Dados pessoais de " + res.data.nome + " foram atualizados com sucesso!")

      })
      .catch(error => {
        this.setState({"mensagem": "Erro ao atualizar o Perfil"});        
        this.toastMensagem("Erro ao atualizar o Perfil. Error: " + error);
      })
    }
    atualizarDadosLogin = async e => {
      //e.preventDefault();
      this.setState({"mensagem": ""}) 
      
      const {id} = this.state;

      let body = {};

      if ( this.state.senha )
        body.senha = this.state.senha;

      if (!body.senha)
        return;

      await apiUrl.put(`/usuario/login/${id}`, body)
      .then(res => {
        this.setState({
          "mensagem": "Login Atualizado",
        });
      })
      .catch(error => {
        this.setState({"mensagem": "Erro ao atualizar o Login"});
      })

    }
    atualizarEndereco = async e => {
      e.preventDefault();
      this.setState({"mensagem": ""}) 
      const id = this.state.id;
      await apiUrl.put(`/usuario/${id}/endereco`, {
        idUsuario: this.state.id,
        cep: this.state.cep,
        endereco: this.state.rua,        
        numero: this.state.numero,
        complemento: this.state.complemento,
        bairro: this.state.bairro,
        cidade: this.state.cidade,
        estado: this.state.estado,
      })
      .then(res => {
        //Resposta do Servidor
        this.setState({"mensagem":"Endereço Atualizado"}); 
        this.toastMensagem("Endereço atualizado com sucesso!");
      })
      .catch(error => {
        this.setState({"mensagem": "Erro ao atualizar o Endereço"});         
        this.toastMensagem("Erro ao atualizar o endereço. Error: " + error);
      })
    }
    atualizarBanco = async e => {
      e.preventDefault();
      if ( !this.state.idConta )
        return this.cadastrarBanco(e);

      this.setState({"mensagem": ""}) 
      const id = this.state.id;
      await apiUrl.put(`/usuario/${id}/conta-bancaria`, {
        id: this.state.idConta,
        idUsuario: this.state.id,
        codigoBanco: this.state.codigoBanco,
        tipoConta: this.state.tipo,        
        agencia: this.state.agencia,
        agenciaDv: this.state.agenciaDv,
        conta: this.state.conta,
        contaDv: this.state.contaDv,
      })
      .then(res => {
        //Resposta do Servidor
        this.setState({"mensagem":  "Dados Bancários Atualizados"});         
        this.toastMensagem("Dados Bancários Atualizados com sucesso!");
      })
      .catch(error => {
        this.setState({"mensagem": "Error ao atualizar os Dados Bancários"});  
        this.toastMensagem("Erro ao atualizar os dados bancários. Error: " + error);
      })
    }
    cadastrarBanco = async e => {
      e.preventDefault();
      this.setState({"mensagem": ""}) 
      const id = this.state.id;
      await apiUrl.put(`/usuario/${id}/conta-bancaria`, {
        idUsuario: this.state.id,
        codigoBanco: this.state.codigoBanco,
        tipoConta: this.state.tipo,        
        agencia: this.state.agencia,
        agenciaDv: this.state.agenciaDv,
        conta: this.state.conta,
        contaDv: this.state.contaDv,
      })
      .then(res => {
        //Resposta do Servidor
        this.setState({"mensagem":  "Dados Bancários Cadastrados"});          
        this.toastMensagem("Dados bancários cadastrados com sucesso!");
      })
      .catch(error => {
        this.setState({"mensagem": "Erro ao Cadastrar Dados Bancários"});  
        this.toastMensagem("Erro ao cadastrar dados bancários. Error: " + error);
      })
    }
    procuraCep = async e => {
      const {cep} = this.state;
      const cep1 = cep.replace('-','')
        axios.get(`https://viacep.com.br/ws/${cep1}/json/`)
        .then( res => {
          //Endereço
          this.setState({"cep": res.data.cep});
          this.setState({"rua": res.data.logradouro});
          this.setState({"bairro": res.data.bairro});
          this.setState({"cidade": res.data.localidade});
          this.setState({"estado": res.data.uf});
          //this.listaSelectEstados();
          this.setState({"mensagem": ""}); 
          //this.toastMensagem("CEP encontrado!");
          if(res.data.erro){
            this.setState({"mensagem": "CEP não Encontrado, digite novamente "  + cep})  
            this.toastMensagem("CEP não encontrado, digite novamente. CEP:" + cep);
          }
        })
        .catch( error => {
          this.setState({"mensagem":"CEP não Encontrado, digite novamente " + cep})  
          this.toastMensagem("CEP não encontrado, digite novamente. CEP: " + cep);
        }) 
    }
    /*
    listaSelectBancos = async () =>{
      var selectBancos = document.getElementById("listaBancos");

      for (let codigo in bancos) {
        var option = new Option( codigo+' - '+bancos[codigo], codigo);
        if(option.value == this.state.codigoBanco){
          option.defaultSelected = true;
        }
       selectBancos.options[selectBancos.options.length] = option;
      }
    }
    */
    /*
    listaSelectEstados = async () =>{
      var selectEstados = document.getElementById("listaEstados");
      
      await listaEstados.forEach((b) => {
        var option = new Option(b);
        if(option.value == this.state.estado){
          option.defaultSelected = true;
          selectEstados.options[selectEstados.options.length] = option;
        }else{
          selectEstados.options[selectEstados.options.length] = option;
        }      
      });

    }
    */
    iniciaCheckbox(){
      var combo = document.getElementById("status");
      this.state.status > 1 ? combo.checked = true : combo.checked = false;
    }
    componentDidMount(){
      const M = window.M;
      M.updateTextFields();
      document.addEventListener('DOMContentLoaded', function() {
        var elems = document.querySelectorAll('select');
        var instances = M.FormSelect.init(elems, {});
      });
      //Scrool 
      document.addEventListener('DOMContentLoaded', function() {
        var elems = document.querySelectorAll('.scrollspy');
        var instances = M.ScrollSpy.init(elems, {
          scrollOffset: 50,
        });
      });


      let sel = document.getElementById('estado'); //.material_select(this.changeHandler.bind(this));
      // $(document).ready(function() {
      //   $('select').material_select();
      // });		  
      // $(ReactDOM.findDOMNode(this.refs.testSelect)).on('change',this.handleSelectChange.bind(this));


      //this.listaSelectEstados();
      //this.listaSelectBancos();

    }
    toastMensagem(message){
      const M = window.M;
      M.toast({html: message, classes: 'rounded'})
    }
    
    render(){
      const {nome, senha, email, cpf, telefone, dtNascimento, dtCadastro,
        cep, rua, numero, complemento, bairro, cidade, estado, codigoBanco, tipo, agencia, 
        agenciaDv, conta, contaDv} = this.state;

      
      return(
          <div className="perfil">
            <div className="row">
              <div className="col s2  m2 l2">   
                  <blockquote>
                    <ul className="menuLateral">
                      <li><a href="#pessoais">Dados de Perfil</a></li>
                      <li><a href="#endereco">Dados de Endereço</a></li>
                      <li><a href="#banco">Dados de Bancários</a></li>
                    </ul>
                  </blockquote>
              </div>
            </div>
            <div className="row">
              <form className="section scrollspy col s10 push-s1 m8 push-m2 l6 push-l3"
                name="ddP" id="pessoais" onSubmit={this.atualizarDadosPessoais}>
                  <blockquote className=" col s12 m12 l12">
                      <p className="flow-text">Dados de Perfil</p>
                  </blockquote>
                  <p>Cadastrado em {moment(dtCadastro, "YYYY-MM-DD").format("DD/MM/YYYY")}</p>
                  <p>Aportes rendem 7% a.a</p>
                <div className="row">
                  <div className="input-field col s10 m8 push-m2">
                    <input id="nome" type="text" className="validate" required placeholder="Nome Completo"
                      name="nome" value={nome} onChange={this.changeHandler}/>
                    <label className="active" htmlFor="nome" >Nome Completo</label>   
                  </div>
                </div>
                <div className="row">
                  <div className="input-field col s10 m8 push-m2">
                    <input id="email" type="email" className="validate" required placeholder="email@email.com"
                      name="email" value={email} onChange={this.changeHandler}/>
                    <label htmlFor="email">Email</label>
                  </div>
                </div>
                <div className="row">
                  <div className="input-field col s10 m8 push-m2">
                    <input id="senha" type="password" className="validate" placeholder="*******"
                      name="senha" value={senha} onChange={this.changeHandler}/>
                    <label htmlFor="senha">Senha</label>
                  </div>
                </div>
                <div className="row">
                  <div className="input-field col s10 m8 push-m2">
                    <InputMask  id="cpf" type="text" className="validate" mask="999.999.999-99" placeholder="CPF" 
                      name="cpf" value={cpf} disabled />
                    <label htmlFor="cpf">CPF</label>
                  </div>
                </div>
                <div className="row">
                  <div className="input-field col s10 m8 push-m2">
                    <input id="dataNasc" type="date" className="validate" required
                      name="dtNascimento" value={dtNascimento} onChange={this.changeHandler}/>
                    <label htmlFor="dataNasc">Data de Nascimento</label>
                  </div>
                </div>
                <div className="row">
                  <div className="input-field col s10 m8 push-m2">
                    <InputMask id="tel" type="tel" mask="(99)9 9999-9999" required placeholder="Cel: (99)9 9999-9999 / Fixo Cel: (99)0 9999-9999"
                      name="telefone" value={telefone} onChange={this.changeHandler}/>
                    <label htmlFor="telefone">Telefone</label>
                  </div>
                </div>
                <div className="row">
                  <div className="input-field col s10 m8 push-m2">
                    <label>
                      <input type="checkbox" required nome="status" id="status"/>
                      <span>Aceite os <a target="_blank" href="/termosdeuso">Termos de uso</a></span>
                    </label>
                  </div>
                </div>
                <br />
                <div className="row">
                  <div className="col s12 m12 l12">
                  <button className="btn waves-effect waves-light col s10 push-s1 m10 push-m1 l10 push-l1" type="submit" name="action">ATUALIZAR</button>
                  </div>
                </div>            
              </form>
              <br />
            </div>
            <div className="row">
              <form className="section scrollspy col s10 push-s1 m8 push-m2 l6 push-l3"
                name="ddE" id="endereco" onSubmit={this.atualizarEndereco}>
                  <blockquote className="col s12 m12 l12">
                      <p className="flow-text">Endereço</p>
                    </blockquote>
                <div className="row">
                  <div className="input-field col s10 m8 push-m2">
                    <InputMask id="cep" type="text" className="validate" mask="99999-999" placeholder="CEP" required
                      name="cep" value={cep} onChange={this.changeHandler}
                      onBlur={this.procuraCep} />
                    <label htmlFor="cep">CEP</label>
                  </div>
                </div>
                <div className="row">
                  <div className="input-field col s10 m8 push-m2">
                    <input id="logradouro" type="text" className="validate" required placeholder="Rua , Avenida, Estrada . . ."
                      name="rua" value={rua} onChange={this.changeHandler}/>
                    <label htmlFor="logradouro">logradouro</label>
                  </div>
                </div>
                <div className="row">
                  <div className="input-field col s10 m8 push-m2">
                    <input id="numero" type="number" className="validate" required placeholder="00"
                      name="numero" value={numero} onChange={this.changeHandler}/>
                    <label htmlFor="numero">Numero</label>
                  </div>
                </div>
                <div className="row">
                  <div className="input-field col s10 m8 push-m2">
                    <input id="complemento" type="text" className="validate" placeholder="apto 00"
                      name="complemento" value={complemento} onChange={this.changeHandler}/>
                    <label htmlFor="complemento">Complemento</label>
                  </div>
                </div>
                <div className="row">
                  <div className="input-field col s10 m8 push-m2">
                    <input id="bairro" type="text" className="validate" required placeholder="Bairro"
                      name="bairro" value={bairro} onChange={this.changeHandler}/>
                    <label htmlFor="bairro">Bairro</label>
                  </div>
                </div>
                <div className="row">
                  <div className="input-field col s10 m8 push-m2">
                    <input id="cidade" type="text" className="validate" required placeholder="Cidade"
                      name="cidade" value={cidade} onChange={this.changeHandler}/>
                    <label htmlFor="cidade">Cidade</label>
                  </div>
                </div>
                <div className="row">
                  <div className="input-field col s10 m8 push-m2">
                  <select className="browser-default" name="estado" id="estado" value={estado} placeholder="Estado" onChange={this.changeHandler} required>
                    {listaEstados.map((e) => <option key={e} value={e}>{e}</option>)}
                  </select>
                    {/*<label htmlFor="cidade">Estado</label>*/}
                  </div>
                </div>
                <div className="row">
                  <div className="col s12 m12 l12">
                  <button className="btn waves-effect waves-light col s10 push-s1 m10 push-m1 l10 push-l1" type="submit" name="action">ATUALIZAR</button>
                  </div>
                </div>            
              </form>
            </div>
              <br />
            <div className="row">
              <form className="section scrollspy col s10 push-s1 m8 push-m2 l6 push-l3"
                name="ddB" id="banco" onSubmit={this.atualizarBanco}>
                  <blockquote className=" col s12 m12 l12">
                      <p className="flow-text">Banco</p>
                    </blockquote>
                <div className="row">
                  <div className="input-field col s10 m8 push-m2" required>
                    <select className="browser-default" name="codigoBanco" id="codigoBanco" value={codigoBanco} onChange={this.changeHandler}>
                      {Object.keys(listaBancos).map((key) => <option key={key} value={key}>{listaBancos[key]}</option>)}
                    </select>
                    {/** <label htmlFor="banco">Banco</label> */}
                  </div>
                </div>
                <div className="row">
                  <div className="input-field col s10 m8 push-m2">
                    <select className="browser-default" name="tipo" value={tipo} onChange={this.changeHandler} required>
                          <option value="Selecione" disabled>Tipo de Conta</option>
                          <option value="CC">Conta Corrente</option>
                          <option value="CP">Conta Poupança</option>
                          <option value="CS">Conta Salário</option>
                        </select>
                    {/*<label htmlFor="logradouro">Tipo da Conta Bancária</label>*/}
                  </div>
                </div>
                <div className="row">
                  <div className="input-field col s6 push-s1 m6 push-m2 l6 push-l2">
                    <input id="agencia" type="text" className="validate" minLength="4" required placeholder="0102"
                      name="agencia" value={agencia} onChange={this.changeHandler}/>
                    <label htmlFor="agencia">Agência</label>
                  </div>
                  <div className="input-field col s4 push-s1 m2 push-m2 l2 push-l2">
                    <input id="adv" type="number" className="validate" maxLength="1" placeholder="0"
                      name="agenciaDv" value={agenciaDv} onChange={this.changeHandler}/>
                    <label htmlFor="adv">DV</label>
                  </div>
                </div>
                <div className="row">
                  <div className="input-field col s6 push-s1 m6 push-m2 l6 push-l2">
                    <input id="conta" type="text" className="validate" maxLength="15" required placeholder="0102030201"
                      name="conta" value={conta} onChange={this.changeHandler}/>
                    <label htmlFor="conta">Conta</label>
                  </div>
                  <div className="input-field col s4 push-s1 m2 push-m2 l2 push-l2">
                    <input id="cdv" type="number" className="validate" maxLength="1" placeholder="0" required
                      name="contaDv" value={contaDv} onChange={this.changeHandler}/>
                    <label htmlFor="cdv">DV</label>
                  </div>
                </div>
                <div className="row">
                  <div className="col s12 m12 l12">
                  <button className="btn waves-effect waves-light col s10 push-s1 m10 push-m1 l10 push-l1" type="submit" name="action">CADASTRAR</button>
                  </div>
                </div>  
                <div className="row">
                  <div className="col s12 m12 l12">
                  <button className="btn waves-effect waves-light col s10 push-s1 m10 push-m1 l10 push-l1" type="submit" name="action">ATUALIZAR</button>
                  </div>
                </div>            
              </form>
              <br />
            </div>
          </div>
      );
    }

}
export default Perfil;




