import React, { Component } from 'react';

//Paginas
import apiUrl from '../../../axiosApi';
import './index.css';
/* img */



class Aluno extends Component{
    constructor(props){
      super(props)
      this.state = {        
        carregou:'0',
        mensagem:'',
        id: '',
        listaAlunos:[],
        listaAportesPendentes:[],
        listaDadosUsuariosAportesPendentes:[],
      }
      this.listarAlunos();
      this.listarAportesPendentes();
    }
    changeHandler = e => {
      this.setState({[e.target.name]: e.target.value})
    }
    listarAlunos = () =>{
      apiUrl.get("/usuario")
      .then(res => {
          console.log(res.data);
          this.setState({ listaAlunos: JSON.parse(JSON.stringify(res.data))});
      })
      .catch(error => {
        alert(error);
      })
    }
    listarAportesPendentes = () => {
      try{
        apiUrl.get('/admin/aporte/novos')
        .then(res => {
    
            console.log("====== APORTES ======");
            console.log(res.data);
            
            for(var i=0; i < res.data.length; i++){
              if(res.data[i].status == 1){
                
                console.log("====== APORTESi[i] ======");
                console.log(res.data[i]);

                this.setState({ listaAportesPendentes: res.data});
                
              }
            }
            
        })
      }catch(error){
          this.setState({"mensagem":error});
      } 
    }
    setAporteStatus = (guid, status) =>{
      this.toastMensagem("Operação realizada "  + guid);
      console.log(guid);
      
      return apiUrl.put(`/admin/aporte/status/${guid}`, {
        status: status,         
      });
    }
    setAporteRecebido = async guid =>{
      this.toastMensagem("Operação realizada "  + guid);
      console.log(guid);
      
      if (window.confirm("Tem certeza que deseja receber essa transferencia?")) {
        this.toastMensagem("> "  + guid);
        await this.setAporteStatus(guid, 2)  // to-do: Mudar para enum-constante!
        .then(res => {
          //Resposta do Servidor
          this.setState({"mensagem":"Aporte Recebido"});
          console.log(res.data);
          this.toastMensagem("Operação realizada "  + guid);
          
          this.listarAportesPendentes();
        })
        .catch(error => {
          this.setState({"mensagem": "Erro ao receber Aportes"});
          console.log("Error" + error);
        });
      }

      this.toastMensagem("! "  + guid);
      
    }
    setAporteCancelado = async guid =>{
      console.log(guid);
      
      if (window.confirm("Tem certeza que deseja cancelar essa transferencia?")) {
        this.toastMensagem("> "  + guid);
        await this.setAporteStatus(guid, 3)  // to-do: Mudar para enum-constante!
        .then(res => {
          //Resposta do Servidor
          this.setState({"mensagem":"Aporte Cancelado"});
          console.log(res.data);
          this.toastMensagem("Operação realizada "  + guid);

          this.listarAportesPendentes();
        })
        .catch(error => {
          this.setState({"mensagem": "Erro ao cancelar Aporte"});
          console.log("Error" + error);
        });
      }
      
    }
    setAporteEstornado =  async guid =>{
      console.log(guid);
      
      if (window.confirm("Tem certeza que deseja estornar essa transferencia?")) {
        this.toastMensagem("> "  + guid);
        await this.setAporteStatus(guid, 4)  // to-do: Mudar para enum-constante!
        .then(res => {
          //Resposta do Servidor
          this.setState({"mensagem":"Aporte Estornado"});
          console.log(res.data);
          this.toastMensagem("Operação realizada "  + guid);

          this.listarAportesPendentes();
        })
        .catch(error => {
          this.setState({"mensagem": "Erro ao estornar Aportes"});
          console.log("Error" + error);
        });
      }
      
    }
    componentDidMount(){
      const M = window.M;
      document.addEventListener('DOMContentLoaded', function() {
        var elems = document.querySelectorAll('.collapsible');
        var instances = M.Collapsible.init(elems, {});
      });
      document.addEventListener('DOMContentLoaded', function() {
        var elems = document.querySelectorAll('.modal');
        var instances = M.Modal.init(elems, {});
      })
    }
    toastMensagem(message){
      const M = window.M;
      M.toast({html: message, classes: 'rounded'})
    }
    
    render(){
      const {listaAlunos, listaAportesPendentes, listaDadosUsuariosAportesPendentes} = this.state;
      
      return( 
        <div className="adminAluno">
          <div className="row">
            <ul className="collection with-header">
              <li className="collection-header"><h3>Alunos</h3></li>
                <ul className="collapsible ">
                  {listaAlunos.map(lista => (
                    <li className="collection-item" key = {lista.id}>
                      <div className="collapsible-header">
                        <table className="responsive-table">
                          <thead>
                            <tr>
                                <th>Nome</th>
                                <th>CPF</th>
                                <th>Email</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{lista.nome}</td>
                              <td>{lista.cpf + " "} </td>
                              <td>{lista.dadosCadastro.email}</td>
                            </tr>
                            
                          </tbody>
                        </table>
                      </div>
                      <div className="collapsible-body">
                      <table className="responsive-table">
                          <thead>
                            <tr>
                                <th>Telefone</th>
                                <th>Data Cadastro</th>
                                <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{lista.dadosCadastro.telefone}</td>
                              <td>{lista.dadosCadastro.dataCadastro}</td>
                              <td>{""+lista.ativo}</td>
                            </tr>
                            
                          </tbody>
                          <thead>
                            <tr>
                                <th>CEP</th>
                                <th>Endereço</th>
                                <th>N°</th>
                                <th>Complemento</th>
                                <th>Bairro</th>
                                <th>Cidade</th>
                                <th>Estado</th>
                            </tr>
                          </thead>
                          <tbody>
                              {lista.endereco == null
                                ? 
                                <tr>
                                    <td>-</td>
                                    <td>Endereço não cadastrado</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                </tr>
                                :
                                <tr>
                                    <td>{lista.endereco.cep}</td>
                                    <td>{lista.endereco.endereco}</td>
                                    <td>{lista.endereco.numero}</td>
                                    <td>{lista.endereco.complemento}</td>
                                    <td>{lista.endereco.bairro}</td>
                                    <td>{lista.endereco.cidade}</td>
                                    <td>{lista.endereco.estado}</td>
                                </tr>
                              }
                          </tbody>
                          <thead>
                            <tr>
                                <th>Banco</th>
                                <th>Tipo de Contra</th>
                                <th>Agencia</th>
                                <th>Conta</th>
                            </tr>
                          </thead>
                          <tbody>
                              {lista.dadosBancarios.length == 0
                                ? 
                                <tr>
                                    <td>-</td>
                                    <td>Banco não cadastrado</td>
                                    <td>-</td>
                                    <td>-</td>
                                </tr>
                                :
                                <tr>
                                    <td>{lista.dadosBancarios[0].codigoBanco}</td>
                                    <td>{lista.dadosBancarios[0].tipoConta}</td>
                                    <td>{lista.dadosBancarios[0].agencia + "-" + 
                                      lista.dadosBancarios[0].agenciaDv}</td>
                                    <td>{lista.dadosBancarios[0].conta + "-" +
                                          lista.dadosBancarios[0].contaDv}</td>
                                </tr>
                              }
                          </tbody>
                        </table>
                      </div>
                    </li>
                  ))}
                </ul>
            </ul>  
          </div> 
        </div>
    );
}
}
export default Aluno;