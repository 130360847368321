import React, { Component } from 'react';

//Paginas
import './footer.css';
import apiUrl from '../axiosApi';

//Img
import iconFacebookImg from '../assets/facebookicon01.png';
import iconInstagramImg from '../assets/instagramicon01.png';
import iconTwitterImg from '../assets/twitter01.png';
import iconYoutubeImg from '../assets/youtubeicon01.png';
import iconPlayImg from '../assets/Playstoreicon01.png';
import iconAppleImg from '../assets/Applericon01.png';
import parceiroImg from '../assets/parceiro1.png';


class Footer extends Component{
    constructor(props){
        super(props)
        this.state = {
          emailNews:''
        }
    }

    changeHandler = e => {
        this.setState({[e.target.name]: e.target.value})
    }
    cadastrarEmailLead = async e => {
        e.preventDefault();
        await apiUrl.post(`/lead`, {
            email: this.state.emailNews,
        })
        .then(res => {
          //Resposta do Servidor
          console.log(JSON.parse(JSON.stringify(res.data)));
          this.toastMensagem("Email Cadastrado para receber notícias com sucesso!");
        })
        .catch(error => {
          this.setState({"mensagem":  error});
          this.toastMensagem("Erro ao tentar cadastrar o email: "  + error);
        })
        //document.getElementById('pop').style.display = "block";
        this.setState({"emailNews": ""});
    }
    toastMensagem(message){
        const M = window.M;
        M.toast({html: message, classes: 'rounded'})
      }
    render(){
        return(
            /*<div className="footer">
            <div className="pop" id="pop">
            <input type="button" onClick={this.fechaPopUp} value="X"/>
                <div className="up" >
                    <h3>Email Cadastrado com Sucesso!</h3>
                </div>
            </div>
                <h5>QUATTRO - Todos os direitos reservados. 2020</h5>
                    <div className='sobre'>
                        <ul>
                            <li><h3>Sobre</h3></li>
                            <li><a rel="" href="/">Curso</a></li>
                            <li><a rel="" href="/">Quem Somos</a></li>
                            <li><a rel="" href="/">Contato</a></li>
                            <li><a rel="" href="/suporte">Suporte</a></li>
                            <li><a rel="" href="/politicadeprivacidade">Política de Privacidade</a></li>
                            <li><a rel="" href="/termosdeuso">Termos de Uso</a></li>
                        </ul>
                    </div>
                    <div className='faleConosco'>
                        <h3>Receba Novidades e Lançamentos</h3>
                        <form action="" onSubmit={this.cadastrarEmailLead}>
                            <input type="email" placeholder="email" onChange={this.changeHandler} 
                            name="email" value={this.state.email}  required/>
                            <input type="submit" value="Enviar" />
                        </form>
                        <ul>
                        <li><h3>Fale Conosco</h3></li>
                        <li>Telefone: (21)x xxxx-xxxx</li>
                        <li>support@quattro.com</li>
                    </ul>
                    </div>
                    <div className='redeSocial'>
                    <ul className='socialImg'>
                        <h3>Redes Sociais e Aplicativos</h3>
                        <li><a rel="noopener noreferrer" target="#" href="https://www.instagram.com/quattrofintech/"> <img src={iconInstagramImg} alt="" /></a></li>
                        <li><a rel="noopener noreferrer" target="#" href="https://www.instagram.com/quattrofintech/"> <img src={iconFacebookImg} alt="" /></a></li>
                        <li><a rel="noopener noreferrer" target="#" href="https://www.instagram.com/quattrofintech/"> <img src={iconYoutubeImg} alt="" /></a></li>
                        <li><a rel="noopener noreferrer" target="#" href="https://www.instagram.com/quattrofintech/"> <img src={iconTwitterImg} alt="" /></a></li>
                        <li><a rel="noopener noreferrer" target="#" href="https://www.instagram.com/quattrofintech/"> <img src={iconPlayImg} alt="" /></a></li>
                        <li><a rel="noopener noreferrer" target="#" href="https://www.instagram.com/quattrofintech/"> <img src={iconAppleImg} alt="" /></a></li>
                    </ul>
                    <ul className='parceirosImg'>
                        <h3>Parceiros</h3>
                        <li><a rel="noopener noreferrer" target="#" href="https://www.instagram.com/quattrofintech/"> <img src={parceiroImg} alt="" /></a></li>
                        <li><a rel="noopener noreferrer" target="#" href="https://www.instagram.com/quattrofintech/"> <img src={parceiroImg} alt="" /></a></li>
                        <li><a rel="noopener noreferrer" target="#" href="https://www.instagram.com/quattrofintech/"> <img src={parceiroImg} alt="" /></a></li>
                    </ul>
                </div>
            </div>*/
            <div>
                <footer className="page-footer">
                    <div className="container">
                        {/*<div className="row">
                            <div className="col s10 push-s1 m8 push-m2 l6 push-l3">
                                <h4 className="flow-text">Receba Novidades e Lançamentos</h4>
                                <form action="" onSubmit={this.cadastrarEmailLead}>
                                    <div className="row">
                                        <div className="input-field col s12  m12  l12">
                                            <input id="emailNews" type="email" className="validate col s9  m9  l9 " required
                                                name="emailNews" value={this.state.emailNews} onChange={this.changeHandler}/>
                                            <label htmlFor="emailNews">Email</label>   
                                            <button className="btn waves-effect waves-light col s3  m3  l3 flow-text" type="submit" name="action">RECEBER</button>
                                        </div>
                                    </div>
                                </form>                    
                            </div>
                        </div>*/ }
                        <div className="row">
                            <div className="col s4 push-s1  m2 push-m1  l2 push-l2">
                                <h5 className="white-text flow-text center ">Sobre</h5>
                                <li><a href="/informativo">Sobre</a></li>
                                <li><a href="/cursos">Cursos</a></li>
                                <li><a href="/quemsomos">Quem Somos</a></li>
                                {/*<li><a href="/suporte">Suporte</a></li>*/}
                                <li><a rel="" href="/politicadeprivacidade">Política de Privacidade</a></li>
                                <li><a rel="" href="/termosdeuso">Termos de Uso</a></li>
                            </div>
                            
                            <div className="col s7 push-s1 m2 push-m7 m2 l3 push-l6">
                            <h5>Contatos</h5>
                            <ul>
                            <li><a href="https://wa.me/5521991731632" target="_blank">Telefone: +55 21 99173-1632</a></li>
                            <li>suporte@investquattro.com.br</li>
                            <li>CNPJ: 25.203.723/0001-19</li>
                            </ul>
                        </div>
                        </div>
                        <div className="container">
                            <div className="row">
                                <div className="col s6  m6">
                                    <ul className='socialImg'>
                                        <h4 className="flow-text">Redes Sociais</h4>
                                        <li><a rel="noopener noreferrer" target="#" href="https://www.instagram.com/quattrofintech/"> <img className="responsive-img" src={iconInstagramImg} alt="" /></a></li>
                                        <li><a rel="noopener noreferrer" target="#" href="https://www.facebook.com/quattrofintech-107503547575402/"> <img className="responsive-img" src={iconFacebookImg} alt="" /></a></li>
                                        <li><a rel="noopener noreferrer" target="#" href="https://www.instagram.com/quattrofintech/"> <img className="responsive-img" src={iconYoutubeImg} alt="" /></a></li>
                                        <li><a rel="noopener noreferrer" target="#" href="https://www.linkedin.com/in/quattro-fintech-4336771b6"> <img className="responsive-img" src={iconTwitterImg} alt="" /></a></li>
                                        {/*<li><a rel="noopener noreferrer" target="#" href="https://www.instagram.com/quattrofintech/"> <img className="responsive-img" src={iconPlayImg} alt="" /></a></li>
                                        <li><a rel="noopener noreferrer" target="#" href="https://www.instagram.com/quattrofintech/"> <img className="responsive-img" src={iconAppleImg} alt="" /></a></li>*/}
                                    </ul>
                                </div>
                                <div className="col s6 pull-s1 m6">
                                    <ul className='parceirosImg'>
                                        <h4 className="flow-text">Parceiros</h4>
                                        <li><a rel="noopener noreferrer" target="#" href="https://www.airbridge.com.br/index.jsp"> <img className="responsive-img" src={parceiroImg} alt="" /></a></li>{/*
                                        <li><a rel="noopener noreferrer" target="#" href="https://www.instagram.com/quattrofintech/"> <img className="responsive-img" src={parceiroImg} alt="" /></a></li>
                                    <li><a rel="noopener noreferrer" target="#" href="https://www.instagram.com/quattrofintech/"> <img className="responsive-img" src={parceiroImg} alt="" /></a></li>*/}
                                    </ul>
                                </div>
                            </div>
                        </div>                      
                    </div>                       
                    <div className="footer-copyright">
                        <div className="container col s12 m12 l12">
                            QUATTRO FINTECH © 2019 Copyright
                        </div>
                    </div>
                </footer>   
            </div>
        );
    }
};
export default Footer;