import React, { Component } from 'react';
import moment from 'moment';

//Paginas
import apiUrl from '../../axiosApi';
import config from '../../config';
import './index.css';

//Imagens
import logo from '../../assets/logoteste.png'

class Status extends Component{
      constructor(props){
        super(props)
        this.state = {
          env: config.env,
          versaoApp: config.versaoApp,
          mobile: '',
          navegador: '',
          versaoNavegador: '',
          plataforma: '',
          localstorage: '',
          data: moment().format('YYYY/MM/DD HH:mm'),
        }        
      }

      getStatus = async () => {
        let _meta = {
          mobile: this.isMobile(),
          navegador: this.getBrowser().name,
          versaoNavegador: this.getBrowser().version,
          plataforma: this.getOS(),
        };

        let _localStorage = ''; 
        for (let idx in JSON.parse(JSON.stringify(localStorage)))
        if (localStorage[ idx ])
          _localStorage += `<b>${idx}</b>: ${localStorage[ idx ]} <br/>`;


        this.setState({ "mobile": _meta.mobile });
        this.setState({ "navegador": _meta.navegador });
        this.setState({ "versaoNavegador": _meta.versaoNavegador });
        this.setState({ "plataforma": _meta.plataforma });
        this.setState({ "localstorage": _localStorage });        
      }

      getBrowser() {
        var ua=navigator.userAgent,tem,M=ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || []; 
        if(/trident/i.test(M[1])){
            tem=/\brv[ :]+(\d+)/g.exec(ua) || []; 
            return {name:'IE',version:(tem[1]||'')};
        }   
        if(M[1]==='Chrome'){
            tem=ua.match(/\bOPR\/(\d+)/)
            if(tem!=null)   {return {name:'Opera', version:tem[1]};}
        }   
        M=M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
        if((tem=ua.match(/version\/(\d+)/i))!=null) {M.splice(1,1,tem[1]);}
        return {
          name: M[0],
          version: M[1]
        };
      }

      getOS() {
        var OSNome = "undefined";
        if (window.navigator.userAgent.indexOf("Windows NT 10.0")!= -1) OSNome="Windows 10";
        if (window.navigator.userAgent.indexOf("Windows NT 6.2") != -1) OSNome="Windows 8";
        if (window.navigator.userAgent.indexOf("Windows NT 6.1") != -1) OSNome="Windows 7";
        if (window.navigator.userAgent.indexOf("Windows NT 6.0") != -1) OSNome="Windows Vista";
        if (window.navigator.userAgent.indexOf("Windows NT 5.1") != -1) OSNome="Windows XP";
        if (window.navigator.userAgent.indexOf("Windows NT 5.0") != -1) OSNome="Windows 2000";
        if (window.navigator.userAgent.indexOf("Mac")            != -1) OSNome="Mac/iOS";
        if (window.navigator.userAgent.indexOf("X11")            != -1) OSNome="UNIX";
        if (window.navigator.userAgent.indexOf("Linux")          != -1) OSNome="Linux";
        return OSNome;
      }

      isMobile() {
        const isMobileDevice = /Mobi/i.test(window.navigator.userAgent)
        return isMobileDevice;
      }

      componentDidMount(){
        this.getStatus();
      }

      toastMensagem(message){
        const M = window.M;
        M.toast({html: message, classes: 'rounded'})
      }

      render(){
        const {
          env,
          versaoApp,
          mobile,
          navegador,
          versaoNavegador,
          plataforma,
          localstorage,
          data
        } = this.state;       
        
        return(
          <div className="login">
           <section className="destaque" style={{
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              background: '#000',
            }}>
              
              <div className="col s10 push-s1 m8 push-m2 l6 push-l3" >
                <img className="responsive-img" src={logo} alt="Logo Quattro Edtech" /><br/>
                <table>
                  <thead>
                    <tr><th>var</th><th>value</th></tr>
                  </thead>
                  <tbody>
                    <tr><td>env</td><td>{env}</td></tr>
                    <tr><td>versaoApp</td><td>{versaoApp}</td></tr>
                    <tr><td>mobile</td><td>{mobile ? 'sim' : 'não'}</td></tr>
                    <tr><td>navegador</td><td>{navegador}</td></tr>
                    <tr><td>versaoNavegador</td><td>{versaoNavegador}</td></tr>
                    <tr><td>plataforma</td><td>{plataforma}</td></tr>
                    <tr><td>localStorage</td><td dangerouslySetInnerHTML={{__html: localstorage}}></td></tr>
                    <tr><td>data</td><td>{data}</td></tr>
                    <tr><td></td><td>{}</td></tr>
                  </tbody>
                </table>
              </div>

            </section>                            
           
            <footer className="page-footer">
              <div className="container">
                  <img className="responsive-img" src={logo} alt="Logo Quattro Edtech" /><br/>                    
                    <p className="flow-text"> 
                      © 2019 Quattro Fintech. Todos os direitos reservados.<br/>
                      contato@investquattro.com
                    </p>
              </div>
            </footer>           
          </div>
      );
    }
}
export default Status;