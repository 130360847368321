import React, { Component } from 'react';
//import { Button, Card, Row, Col, Image, CardTitle } from 'react-materialize';
import moment from 'moment';
import { Pie } from 'react-chartjs-2';
//import { Link }  from 'react-router-dom';

//Paginas

import apiUrl from '../../axiosApi';
import './index.css';

/* img */
import investimentoImg  from '../../assets/investimento.jpg';
import negocioImg  from '../../assets/negocio.jpg';
import aporteImg  from '../../assets/aportes.jpg';
import indiqueImg  from '../../assets/indiqueAmigo.jpg';
import play from '../../assets/play.png';
import suporteImg  from '../../assets/suporte.jpg';
import carteiraImg  from '../../assets/carteira.jpg';

import pageView from '../../utils/pageView';

function nl2br(texto) {
  return texto.replace(new RegExp('\r?\n','g'), '<br />');
}

class Home extends Component{
      constructor(props){
        super(props)
        this.state = {
          carregou:'0',
          mensagem:'Seja bem vindo. Temos novas Ofertas para você!',
          nome: '',
          dadosEndereco: false,
          dadosBanco: false,
          id: localStorage.getItem('idUsuario'),
          status: localStorage.getItem('idStatus'),          
          idUsuario: localStorage.getItem('idUsuario'),
          cursos: [],
          tenhoCurso: 0,
          meusCursos: [],          
          temas: [],
          idCarteira:0,
          saldo:0,
          morningCall: [],
          morningCallPage: 0,
          lives: [],
          listaCarteira:[],
          listaCarteiraResumida:[],
          aulasAoVivo: [],
          dataCarteiraQuattro: {},
          codigoProduto: 1,
        }
        
        this.listarCarteiras();
        this.getHttp();  
        this.listarCursos();
        this.listarMeusCursos();
        this.listarMorningCalls();
        this.listarAulasAoVivo();
        //this.listarLives();        
        this.listarCarteiraQuattro();

        pageView.log('home');
      }
      getHttp = () =>{
        const {id} = this.state;
  
        apiUrl.get(`/usuario/${id}/`).then( res => {
            if( res.data.dadosBancarios && res.data.dadosBancarios.length > 0 ){
              this.setState({dadosBanco: true});
            }
            if( res.data.endereco ){
              this.setState({dadosEndereco: true});
            }

            //Dados Pessoais
            this.setState({
              "id": res.data.id,
              "nome": res.data.nome,
              "cpf": res.data.cpf,
              "email": res.data.dadosCadastro.email,
              "telefone": res.data.dadosCadastro.telefone || "",
              "dtCadastro": res.data.dadosCadastro.dataCadastro,
              "dtNascimento": res.data.dadosCadastro.dataNascimento || "",
              "sexo": res.data.dadosCadastro.sexo || "",
              "taxa": "7",
              "status": "2",
              "icon": ""
            });
  
          })
          .catch( error => {
              console.log(error);
              this.toastMensagem("Erro ao trazer dados do usuário, faça login novamente. Error: " + error);
          });
      }
      listarCursos = () =>{
        apiUrl.get("/cursos/resumo")
        .then(res => {
  
            let temas = {};
            for ( let c of res.data ) {
              if ( temas[ c.temas ] )
                temas[ c.temas ].push( c );
              else
                temas[ c.temas ] = [ c ]; 
            }
  
            this.setState({ 
              cursos: JSON.parse(JSON.stringify(res.data)),
              temas: temas,
            });
  
        })
        .catch(error => {
          console.log(error);
        })
      }
      listarMeusCursos = () =>{
        apiUrl.get("/usuario-curso/" + this.state.idUsuario)
        .then(res => {            
            this.setState({ 
              meusCursos: JSON.parse(JSON.stringify(res.data)),
              tenhoCurso: res.data.length,
            });
        })
        .catch(error => {
          console.log(error);
        })
      }
      listarMorningCalls = () => {
        this.setState({ 
          morningCall: [],
        });
        apiUrl.get("/morning-call/" + this.state.morningCallPage)
        .then(res => {
            this.setState({ 
              morningCall: [].concat( this.state.morningCall, res.data ),
            });
        })
        .catch(error => {
          console.log(error);
        })
      }
      listarLives = () => {
        apiUrl.get("/video-live/")
        .then(res => {
            this.setState({ 
              lives: res.data,
            });
        })
        .catch(error => {
          console.log(error);
        })
      }
      carregarMaisMorningCalls = () => {
        this.setState({ morningCallPage: this.state.morningCallPage + 1});
        this.listarMorningCalls();
      }
      listarAulasAoVivo = () => {
        var aulasVivo = new Array();
        var cont = -1;
        this.setState({ 
          aulasAoVivo: [],
        });
        
        apiUrl.get("/video-live/")
        .then(res => {
          for(var i = res.data.length; i>-1; i--){
            aulasVivo[cont] = res.data[i];
            cont ++;
          }

          this.setState({ 
            aulasAoVivo: [].concat( this.state.aulasAoVivo, aulasVivo),
          });
        })
        .catch(error => {
          console.log(error);
        })
      }

      /*
      verificaHoraAula = () => {
        var horaAtual = new Date();
        var atual = parseInt(moment(horaAtual).format('HH'));
        var programado = parseInt(moment(this.hora).format('HH'));
        if( atual >=  ( programado - 1)  ||  atual <=  (programado + 4)){
          return true;
        }else{
          return false;
        }
      }
      */

      listarCarteiras = async () => {
        try{
          await apiUrl.get('/carteiras/')
          .then(res => {
              this.setState({ 
                idCarteira: res.data[0].id,
                aportes: res.data[0].aportes,
                carteira: res.data[0],
              });
              this.obterSaldoCarteira( res.data[0].id );
          })
        }catch(error){
            this.setState({"mensagem":error});
        } 
      } 
      listarCarteiraQuattro = (cod) =>{
        var label = new Array();
        var data = new Array();
        
        apiUrl.get("/carteira-quattro/carteira")
        .then(res => { 
            this.setState({ listaCarteira: res.data });
        })
        .catch(error => {
          this.toastMensagem(error);
        })
        
  
        apiUrl.get("/carteira-quattro/carteira/resumido")
        .then(res => {
            this.setState({ listaCarteiraResumida: res.data });
            for(var i = 0; i < res.data.length; i++){
              label[i] = res.data[i].tipo;
              data[i] = (res.data[i].porcentagem).toFixed(2, ",");
            }
            this.dadoosGraficoCarteiraQuattroResumida(label,data);
        })
        .catch(error => {
          this.toastMensagem(error);
        })
      }
      obterSaldoCarteira = async (idCarteira) => {
        try{
          await apiUrl.get(`/carteira/${idCarteira}/saldo`)
          .then(res => {
              this.setState({ saldo: res.data / 100 });
          })
        }catch(error){
            this.setState({"mensagem":error});
        } 
      }
      componentDidMount(){
        const M = window.M;
        document.addEventListener('DOMContentLoaded', function() {
          var elems = document.querySelectorAll('.slider');
          var instances = M.Slider.init(elems, {interval: 5000});
        });
        document.addEventListener('DOMContentLoaded', function() {
          var elems = document.querySelectorAll('.carousel');
          var instances = M.Carousel.init(elems, {});
        });
        
      document.addEventListener('DOMContentLoaded', function() {
        var elems = document.querySelectorAll('.modal');
        var instances = M.Modal.init(elems, {});
      })
      }
      toastMensagem(message){
        const M = window.M;
        M.toast({html: message, classes: 'rounded'})
      }
      dadoosGraficoCarteiraQuattroResumida(labelDados, dados){
          const data = {
            labels: labelDados,
            datasets: [{
              data: dados,
              backgroundColor: [
              '#062f4f',
              '#8b2621',
              '#0b0025',
              '#778899',
              '#2E8B57',
              '#8B4513',
              ],
              hoverBackgroundColor: [
              '#062f4f',
              '#8b2621',
              '#0b0025',
              '#778899',
              '#2E8B57',
              '#8B4513',
              ]
            }]
          };
          this.setState({dataCarteiraQuattro: data})
      }
      mensagemAdquiriCursos = async e => {
        e.preventDefault();
        this.toastMensagem("Aquiria nosso Curso de Investimentos e Negócios");
      }
      retornaAsterisco = (tamanho) => {
        var texto = "";
        for(var i = 0; i < tamanho; i++){
          texto+= "* "
        }
        return texto;
      }
      render(){
        const {nome, cursos, meusCursos, morningCall, aulasAoVivo, saldo,  
          listaCarteiraResumida, listaCarteira, dataCarteiraQuattro, dadosEndereco, dadosBanco,
          tenhoCurso, codigoProduto} = this.state;
        return(
          <div className="home"> 
            <div className="row roi">
              <div className="col s5 m3">
                <h5>{nome.split(" ")[0]}</h5>
              </div>
              <div className="col s7 m9">
                <h5>
                  <marquee width="100%" scrollamount="100" scrolldelay="1000" loop="7">
                    {"Desculpe o transtorno, estamos fazendo melhorias para você!"}
                    {tenhoCurso == 0 ? " Faça o primeiro aporte para receber acesso ao nossos cursos de Investimentos e Negócios |": " "}
                    {dadosEndereco == false ? " ** Atualize seu endereço ** |": " "}
                    {dadosBanco == false ? " ** Atualize seus dados bancários ** |": " "}
                  </marquee>
                </h5>
              </div>
            </div>

            <div className="row meusCursos">
              {cursos.map(c => (
                <div key={c.id}>
                  {(() => {
                          return (meusCursos.some(mc => mc.idCurso == c.id)) ? 
                            c.id == 1 
                            ?
                              <div className="col s10 push-s1 m4 push-m2">
                                <a href={"/cursos/" + c.id}>   
                                  <img src={investimentoImg} alt="Curso de investimentos" className="responsive-img" />
                                  <h4 className="center">Investimentos</h4>
                                </a>
                              </div>
                            :
                              <div className="col s10 push-s1 m4 push-m2">
                                <a href={"/cursos/" + c.id}>  
                                  <img src={negocioImg} alt="Curso de negócios" className="responsive-img" />
                                  <h4 className="center">Negócios</h4>
                                </a>
                              </div>
                          :
                          <a href={"/aporte"}>
                            <div className="continuar">
                              <h4 className="flow-text">Faça a primeira transferência</h4>
                            </div>                 
                          </a>
                  })()}
                </div>
              ))}                          
            </div>
  
            <div className="row atalhos">
              <div className="col s5 push-s1 m2 push-m2">
                <a href="/aporte">
                  <img src={aporteImg} alt="Faça um aporte" className="responsive-img" />
                  <h5 className="center">Aporte</h5>
                </a>
              </div>
              <div className="col s5 push-s1 m2 push-m2">
                <a href="/indiqueamigos">
                  <img src={indiqueImg} alt="Carteira resumida" className="responsive-img" />
                  <h5 className="center">Indique Amigos</h5>
                </a>
              </div>
              <div className="col s5 push-s1 m2 push-m2">
                <a href="/carteira">
                  <img src={carteiraImg} alt="Minha Carteira" className="responsive-img" />
                  <h5 className="center">Minha Carteira</h5>
                  <h5 className="center quattrocoins">QCs {saldo.toFixed(2, ",")}</h5> 
                </a>
              </div>
              <div className="col s5 push-s1 m2 push-m2">
                <a href="/informativo">
                  <img src={suporteImg} alt="Como Funciona" className="responsive-img" />
                  <h5 className="center">Como Funciona</h5> 
                </a>
              </div>     
            </div>

            <div className="row">
              <div className="card-content morning col s10 push-s1  m8 push-m2">
                <div className="tituloMorning ">
                  <h5>MORNING CALL</h5>
                </div>
                <div className="textoMorning ">
                  <ul >
                      {morningCall.map(c => 
                          <li key={c.id}>
                            <p className="dataNoticia">{moment(c.data).format('DD/MM/YYYY')}</p>
                            <p  className="noticia" dangerouslySetInnerHTML={{ __html: `${nl2br(c.texto)}`}}></p>
                          </li>                       
                      )}
                  </ul>
                  <p><a onClick={this.carregarMaisMorningCalls}>ler mais</a></p>                 
                </div>                            
              </div>      
            </div> 
           
            <div className="row">
              <div className="card calendario col s5 m3 push-m2">
                  <h5 className="center">Calendário de Aulas ao Vivo</h5>
                  <div className="aulasaovivo">
                    { tenhoCurso == 0
                    ?
                      <p>
                        <b>Faça o primeiro aporte para receber acesso aos nossos cursos e as aulas ao vivo</b><br/>
                        <a href="/aporte">Aporte</a>
                        {aulasAoVivo.map(c =>
                          <a target="blank" key={c.id} onClick={this.mensagemAdquiriCursos}><p key={c.id}>{c.titulo} - {moment(c.data).format('DD/MM/YYYY HH:mm')}</p></a>
                        )}                        
                      </p>  
                        
                    :
                      aulasAoVivo.map(c =>
                        <a href={c.link} target="blank" key={c.id}><p key={c.id}>{c.titulo} - {moment(c.data).format('DD/MM/YYYY HH:mm')}</p></a>
                      )
                    }
                  </div>
              </div>    
              <div className="card calendario col s5 push-s1 m5 push-m2">
                  <h5 className="center">Carteira Resumida Quattro</h5>
                  <div className="aulasaovivo">
                    {listaCarteiraResumida.map( (carteira, indice) => (   
                      <a key={'a_' + indice}><p key={'p_' + indice}><b>{carteira.tipo}</b> -  {carteira.porcentagem.toFixed(2, ",")} %</p></a>                               
                    ))}
                    <a className="waves-effect waves-light btn modal-trigger" href="#graficoModal">Gráfico</a>
                    
                  </div>
              </div>    
            </div>
            {/*MODAL AULAS */}
          <div id="graficoModal" className="modal modal-fixed-footer">           
              <div className="modal-content">
                <h4>Gráfico Carteira Quattro</h4>
                <div className="row">
                  <Pie data={dataCarteiraQuattro} />
                  {/* codigoProduto == 1
                    ?
                      <a href="/produto" className="col s12">Adquirir Acesso a Carteira da Quattro</a>
                    :
                      ""
                  */}
                  <table className="responsive-table striped col s12 m10 push-m1 ">
                    <thead>
                      <tr>
                          <th>Tipo</th>
                          <th>Ticket</th>
                          <th>Empresa</th>
                      </tr>
                    </thead>
                    { codigoProduto == 1
                      ?
                        <tbody>
                          {listaCarteira.map( (carteira, indice) => (   
                            <tr key={'a_' + indice}>
                              <td>{carteira.ativo.tipo}</td>
                              <td>{this.retornaAsterisco(carteira.ativo.codigo.length)}</td>
                              <td>{this.retornaAsterisco(carteira.ativo.nome.length)}</td>
                            </tr>
                          ))}                         
                      </tbody>
                      :
                        <tbody>
                          {listaCarteira.map( (carteira, indice) => (   
                            <tr key={'a_' + indice}>
                              <td>{carteira.ativo.tipo}</td>
                              <td>{carteira.ativo.codigo.toUpperCase()}</td>
                              <td>{carteira.ativo.nome.toUpperCase()}</td>
                            </tr>
                          ))}
                      </tbody>

                    }
                    
                  </table>
                </div>
              </div>
              <div className="modal-footer">
                <a className="modal-close btn waves-effect waves-light col s10 m10 push-m1">Fechar</a>
              </div>  
          </div> 
        </div>
      );
    }
}
export default Home;