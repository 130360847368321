import React, { Component } from 'react';
import moment from 'moment';
import InputMask from 'react-input-mask';
import CurrencyInput from 'react-currency-masked-input'

//Paginas
import apiUrl from '../../axiosApi';
import './index.css';

import pageView from '../../utils/pageView';


class Aporte extends Component {

    constructor(props) {
      super(props)
      this.state = {
        carregou:'0',
        mensagem:'',
        id: localStorage.getItem('idUsuario'),
        idCarteira: 0,
        valor: 0,
        data: '',
        comprovante: '',
        tipo: '',
        status:'1',
        aportes: [],
        i: 1,
        valorCurso: '1000,00',
        qntCurso: 0,
        idUsuario: localStorage.getItem('idUsuario'),
        meusCursos: [],
      }
      this.listarCarteiras();
      this.listarMeusCursos();

      pageView.log('aporte');
    }
    changeHandler = e => {
      this.setState({[e.target.name]: e.target.value});
    }

    uploadFileHandler = e => {
      const maxSize = 41943040;
      if (e.target.files.length > 0) {
        if (e.target.files[0].size > maxSize) {
          this.toastMensagem(e.target.files[0].type+' O arquivo selecionado é muito grande, escolha um arquivo menor.');
          this.setState({comprovante: ''});
          return;
        }
        this.setState({comprovante: e.target.files[0]});
      }
    }

    cadastrarAporte = async e => {
      this.setState({"mensagem": ""});
      e.preventDefault();
      var val = parseInt(this.state.valor.replace('.',''));
      //this.toastMensagem(this.state.comprovante) 

      const data = new FormData();
      data.append('idCarteira', this.state.idCarteira);
      data.append('valor', val);
      data.append('comprovante', this.state.comprovante);
      data.append('data', this.state.data);
      data.append('tipo', this.state.tipo);

      console.log(data);
      
      await apiUrl.post(`/aporte`, data)
      .then(res => {
        //Resposta do Servidor
        this.setState({"mensagem": "Aporte Realizado no Valor de R$ " + res.data.valor/100 });
        this.limpaCampos();
        
        this.toastMensagem("Aporte cadastrado com sucesso!");
        this.listarCarteiras();
      })
      .catch(error => {
        this.setState({"mensagem": "Error ao Realizar o Aporte. Tente Novamente"});
        this.toastMensagem("Error ao Realizar o Aporte. Tente Novamente" + error);          
        this.limpaCampos()
      })
    }

    limpaCampos() {
      this.setState({
        valor: 0,
        data: '',
        comprovante: '',
        tipo: '',
      });
    }

    listarCarteiras = () => {
      try{
        apiUrl.get('/carteiras/')
        .then(res => {
            this.setState({ idCarteira: res.data[0].id });
            this.setState({ aportes: res.data[0].aportes });
        })
      }catch(error){
          this.setState({"mensagem":error});
      } 
    }

    obterStatus = (id) => {
      switch(id) {
        case 1: return "PENDENTE";
        case 2: return "OK";
        case 3: return "CANCELADO";
        case 4: return "DEVOLVIDO";
        case 5: return "AGUARDANDO";
        case 6: return "RETIRADO";
        case 7: return "REINVESTIR";
      }
    }

    componentDidMount(){
      const M = window.M;
      document.addEventListener('DOMContentLoaded', function() {
        var elems = document.querySelectorAll('select');
        var instances = M.FormSelect.init(elems, {});
      });
      document.addEventListener('DOMContentLoaded', function() {
        var elems = document.querySelectorAll('.collapsible');
        var instances = M.Collapsible.init(elems, {});
      });
    }

    toastMensagem(message){
      const M = window.M;
      M.toast({html: message, classes: 'rounded'})
    }

    toastMensagemConfirmacao(message){
      const M = window.M;
      var toastHTML = `<span>${message}</span><button className="btn-flat toast-action">Ok</button>`;
      M.toast({html: toastHTML, classes: 'rounded'});
    }

    botaoRetirar = (data) => {
      const now = moment(new Date()); // Data de hoje
      const past = moment(data); // Outra data no passado
      const duration = moment.duration(now.diff(past));

      // Mostra a diferença em dias
      const days = parseInt(duration.asDays());
      if(days >350){
        return true;
      }else{        
        return false;
      }
    }

    pedidoRetirada = e =>{
      e.preventDefault();
      if (window.confirm("Tem certeza que deseja cancelar essa transferencia?")) {
        this.setAporteStatus(e.target.name, 5);
        this.toastMensagem("Sua RETIRADA foi solicitada realizada com sucesso. Aguarde o término do seu contrato")
      }else{
        this.toastMensagem("Operação cancelada");
      }
    }

    pedidoReinvestir = e =>{
      e.preventDefault();
      if (window.confirm("Tem certeza que deseja cancelar essa transferencia?")) {
        this.setAporteStatus(e.target.name, 7);
        this.toastMensagem("Sua aporte será REINVESTIDO. Aguarde o término do seu contrato");
      }else{
        this.toastMensagem("Operação cancelada");
      }
    }

    setAporteStatus = (guid, status) =>{
      this.toastMensagem("Operação realizada "  + guid);
      return apiUrl.put(`/admin/aporte/status/${guid}`, {
        status: status,         
      });
    }

    listarMeusCursos = () =>{
      apiUrl.get("/usuario-curso/" + this.state.idUsuario)
      .then(res => {
          this.quantidadeCursos(res.data.length);
          this.setState({qntCurso: res.data.length})
      })
      .catch(error => {
        console.log(error);
      })
    }

    quantidadeCursos = (qnt) =>{
      return qnt;
    }
    
    render(){
      const {valor, data,  comprovante, aportes, valorCurso, qntCurso} = this.state;  
      
      return(
          <div className="aporte">
            <div className="row">
              <div className="col s10 push-s1 m10 push-m1 l10 push-l1">      
                <blockquote>
                  <p >Faça sua transferência para a conta abaixo, anexe o comprovante e nos envie pelos meios abaixo. Em até 2 dias úteis nossa equipe irá validar seu aporte para que você tenha creditado na sua conta nosso quattrocoin para que possa aproveitar de acordo com os termos acordados. Aproveite nosso conteúdo e o melhor programa de cashback do mercado. Entenda melhor nosso programa no <a href="/informativo">link</a></p>
                </blockquote>
              </div>
            </div>
            <div className="row">
              <div className="col s10 push-s1 m8 push-m2 l4 push-l2">      
                <blockquote >
                    <h4>Faça a transfêrência para </h4>
                    <h4>Banco: 077 INTER - Agência: 0001–9</h4>
                    <h4>Conta Corrente: 4678101-3</h4>
                </blockquote>
                <blockquote >
                    <h4>A compra de QuattroCoins será liberada após adquirir o curso.</h4>
                </blockquote>
              </div>
              <form className="col s10 push-s1 m8 push-m2 l4 push-l2"
                onSubmit={this.cadastrarAporte}>
                <div className="row">
                  <div className="input-field col s10 push-s1 ">
                    <CurrencyInput id="valor" type="number" className="validate"  required
                      name="valor"   onChange={this.changeHandler}/>
                    <label htmlFor="valor">Valor do Aporte</label>
                  </div>
                </div>
                <div className="row">
                  <div className="input-field col s10 push-s1">
                    <input id="data" type="date" className="validate" required
                      name="data" value= {data} onChange={this.changeHandler}/>
                    <label htmlFor="data">Data da transferência</label>
                  </div>
                </div>
                <div className="row">
                  <div className="input-field col s10 push-s1 ">
                    {qntCurso > 0
                      ? 
                        <select className="browser-default" required name="tipo" required onChange={this.changeHandler}>
                          <option value="Aporte">QuattroCoins</option>
                        </select>
                      :
                        <select className="browser-default" required name="tipo" required onChange={this.changeHandler}>
                          <option value="Aporte">Curso R$ {valorCurso}</option>
                        </select>
                    } 
                  </div>
                </div>
                {/*}
                <div className="row">
                  <div className="file-field input-field input-field col s10 push-s1 ">
                    <div className="btn">
                      <span htmlFor="_comprovante">Comprovante</span>
                      <input type="file"/>
                    </div>
                    <div className="file-path-wrapper">
                      <input  id="comprovante" className="file-path validate" type="text" accept="image/*, .pdf" value={comprovante}
                        name="comprovante" onChange={this.uploadFileHandler}/>
                    </div>
                  </div>
                </div>
                  */}
                <div className="row">
                  <div className="col s10 push-s1 ">
                    {/*<div className="btn">
                      <span htmlFor="_comprovante">Comprovante</span>
                    </div>*/}
                    <div>
                      <input id="_comprovante" type="file" accept="image/*, .pdf"  className="btn waves-effect waves-light"
                        name="_comprovante" onChange={this.uploadFileHandler} />
                        <label htmlFor="_comprovante" id="toFile">Selecione o comprovante</label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col s12 m12 l12">
                  <button className="btn waves-effect waves-light col s10 push-s1" type="submit" name="action">Investir</button>
                  </div>
                </div>
              </form>
            </div>
            <div className="row">
              <div className="col s12 m10 push-l1 l10 push-l1">      
                <table className="responsive-table striped" id="aportes">
                  <thead>
                    <tr>
                        <th>Data Transferência</th>
                        <th>Valor</th>
                        <th>Status</th>
                        <th>Comprovante</th>
                        <th>Taxa</th>
                        <th>Data Cadastro</th>                        
                        <th>Tipo</th>                        
                        <th>Retirada</th>
                        <th colSpan="2" className="center">Solicitar</th>
                    </tr>
                  </thead>
                  <tbody className="">
                    {aportes.map(lista => (
                              <tr key = {lista.guid}>
                                  <td>{moment(lista.dataTransferencia, "YYYY-MM-DD").format("DD/MM/YYYY")}</td>
                                  <td>{lista.tipo == 1 ? "R$" : "QTC"}  {lista.valor / 100}</td>
                                  <td className="center">{this.obterStatus(lista.status)}</td>
                                  <td > { lista.tipo == 1 ? <a target="blank" href={apiUrl.defaults.baseURL+lista.urlComprovante} >Comprovante </a> : ''}</td>
                                  <td>{lista.taxaRendimento}%</td>
                                  <td>{moment(lista.dataCadastro, "YYYY-MM-DD").format("DD/MM/YYYY")}</td>
                                  <td className="center">{lista.tipo == "1" ? "Aporte" : lista.tipo == "2" ? "Rendimento" : "Indicação"}</td>
                                  <td>{lista.dataRetirada ? moment(lista.dataRetirada, "YYYY-MM-DD").format("DD/MM/YYYY") : '-' }</td>
                                  <td>
                                    {this.botaoRetirar(lista.dataTransferencia) && lista.status == 2
                                      ? 
                                        <button className="btn-small waves-effect waves-light " type="submit" name={lista.guid} onClick={this.pedidoRetirada}>Retirar</button>
                                      :
                                      lista.status != 2
                                      ?
                                      ""
                                      :
                                        <button className="btn-small waves-effect waves-light " type="submit" name="action" disabled>Retirar</button>
                                    }
                                  </td>
                                  <td>
                                    {this.botaoRetirar(lista.dataTransferencia) && lista.status == 2
                                      ? 
                                        <button className="btn-small waves-effect waves-light " type="submit" name={lista.guid} onClick={this.pedidoReinvestir}>Reinvestir</button>
                                      :
                                      lista.status != 2
                                      ?
                                        ""
                                      :
                                        <button className="btn-small waves-effect waves-light " type="submit" name="action" disabled>Reinvestir</button>
                                    }
                                    </td>
                              </tr>
                            ))}
                  </tbody>
                </table>
                </div>
            </div>
              
        </div>
      );
    };
};
export default Aporte;