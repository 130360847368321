import React, { Component } from 'react';
import InputMask from 'react-input-mask';
//Paginas
import apiUrl from '../../axiosApi';
import config from '../../config';
import './index.css';

//Img
//import alunoImg from '../../assets/iconAluno.png';

const ID_TIPO_ORIGEM_LOGIN = 2;

class Login extends Component{

    constructor(props){
      super(props);
      this.state = {
        carregou:'0',
        mensagem:'',
        id: null, 
        login: localStorage.getItem('emailUsuario') || '',
        senha: '',
        logado: '',
        lembrar: 0,
      };

      
    }

    changeHandler = e => {
      this.setState({[e.target.name]: e.target.value})
    }
    componentDidMount(){
      var box = document.getElementById("lembrar");
      var lembrar = false;
      if(localStorage.getItem('emailUsuario') != null){
        lembrar = true;
      }else{
        lembrar = false;
      } 
      box.checked = lembrar; 
    }  
    fazerLogin = async e => {
      e.preventDefault();
      document.getElementById('progress').style.display = 'block'
      this.setState({"mensagem": ""}) 
      if(this.state.login == "123.456.789-00"){
        this.fazerLoginAdmin();
      }else{
        var cpf = this.state.login.toString().replace('.','').replace('-','').replace('.','');
        if(!this.validadorCpf(cpf)){        
          this.toastMensagem("CPF Inválido ");
        }else{
            let _meta = {
              idTipoOrigemLogin: ID_TIPO_ORIGEM_LOGIN,
              mobile: this.isMobile(),
              navegador: this.getBrowser().name,
              versaoNavegador: this.getBrowser().version,
              plataforma: this.getOS(),
              versaoApp: config.versaoApp,
            };

            await apiUrl.post(`/login`, {
              login: cpf,
              senha: this.state.senha,
              meta: _meta,
            })
            .then(res => {
              //Resposta do Servidor
              console.log(res);

              //sessionStorage.setItem("X-Cookie-Status", res.headers["x-cookie-status"]);
              localStorage.setItem("X-Cookie-Status", res.data.sessao.token);
              localStorage.setItem("idSessao", res.data.sessao.idSessao);   // pageview

              //this.setState({"mensagem":  "Conectando . . ."});                  
              this.toastMensagem("Usuário Conectado" );

              localStorage.removeItem('logado');
              localStorage.setItem('logado', true);
              localStorage.removeItem('idUsuario');
              localStorage.setItem('idUsuario', res.data.usuario.id);

              this.setState({
                "logado": true,
                "id": res.data.usuario.id,
              });
              var box = document.getElementById("lembrar");
          
              if(box.checked == true){
                localStorage.removeItem('emailUsuario');
                localStorage.setItem('emailUsuario', this.state.login);
              }else{
                localStorage.removeItem('emailUsuario');
              }
              window.location.replace("/");

            })
            .catch(error => {                  
              document.getElementById('progress').style.display = 'none'
              this.toastMensagem("ERROR: CPF e/ou senha Incorretos" );
              console.log(error);
              localStorage.setItem('logado', false);
              localStorage.setItem('idUsuario', null);
              localStorage.setItem('idSessao', null);
            })
          
        }
      }
    }
    validadorCpf(cpf){
      var contador = 0;
      var digito1 = 0;
      var digito2 = 0;
      var resto = 0;
      var valido1 = false;
      var valido2 = false;
      for(var i = 1; i <= 9; i++ )
        digito1 = digito1 +  parseInt(cpf.substring(i-1, i)) * (11 - i); 
      resto = (digito1 * 10) % 11;
      if ((resto == 10) || (resto == 11)) 
        resto = 0;
      if (resto == parseInt(cpf.substring(9, 10)) )
        valido1 =  true;

      //Segundo Digito
      for(var i = 1; i <= 10; i++ )
        digito2 = digito2 +  parseInt(cpf.substring(i-1, i)) * (12 - i); 
      resto = (digito2 * 10) % 11;
      if ((resto == 10) || (resto == 11)) 
        resto = 0;
      if (resto == parseInt(cpf.substring(10, 11)) )
        valido2 =  true;

      for(var i = 1; i <= 10; i++){
        if(parseInt(cpf.substring(0,1)) ==  parseInt(cpf.substring(i, i+1)))
          contador++; 
      }
      console.log(contador);
      if((valido1 == true) && (valido2 == true) && (contador != 10)){
        return true;
      }else{
        return false;
      }
    }
    getBrowser() {
      var ua=navigator.userAgent,tem,M=ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || []; 
      if(/trident/i.test(M[1])){
          tem=/\brv[ :]+(\d+)/g.exec(ua) || []; 
          return {name:'IE',version:(tem[1]||'')};
      }   
      if(M[1]==='Chrome'){
          tem=ua.match(/\bOPR\/(\d+)/)
          if(tem!=null)   {return {name:'Opera', version:tem[1]};}
      }   
      M=M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
      if((tem=ua.match(/version\/(\d+)/i))!=null) {M.splice(1,1,tem[1]);}
      return {
        name: M[0],
        version: M[1]
      };
    }
    getOS() {
      var OSNome = "undefined";
      if (window.navigator.userAgent.indexOf("Windows NT 10.0")!= -1) OSNome="Windows 10";
      if (window.navigator.userAgent.indexOf("Windows NT 6.2") != -1) OSNome="Windows 8";
      if (window.navigator.userAgent.indexOf("Windows NT 6.1") != -1) OSNome="Windows 7";
      if (window.navigator.userAgent.indexOf("Windows NT 6.0") != -1) OSNome="Windows Vista";
      if (window.navigator.userAgent.indexOf("Windows NT 5.1") != -1) OSNome="Windows XP";
      if (window.navigator.userAgent.indexOf("Windows NT 5.0") != -1) OSNome="Windows 2000";
      if (window.navigator.userAgent.indexOf("Mac")            != -1) OSNome="Mac/iOS";
      if (window.navigator.userAgent.indexOf("X11")            != -1) OSNome="UNIX";
      if (window.navigator.userAgent.indexOf("Linux")          != -1) OSNome="Linux";
      return OSNome;
    }
    isMobile() {
      const isMobileDevice = /Mobi/i.test(window.navigator.userAgent)
      return isMobileDevice;
    }
    fazerLoginAdmin = async e => {
      //e.preventDefault();
      this.setState({"mensagem": ""}) 

      await apiUrl.post(`/admin`, {
        login: "admin",
        senha: this.state.senha,
      })
      .then(res => {
        //Resposta do Servidor
        console.log(res);

        //localStorage.setItem("X-Cookie-Status", res.headers["x-cookie-status"]);
        localStorage.setItem("X-Cookie-Status", res.data.sessao.token);

        this.setState({"mensagem":  "Conectando . . ."});        
        this.toastMensagem("Administrador Conectado . . ." );

        localStorage.removeItem('logado');
        localStorage.setItem('logado', true);
        localStorage.removeItem('idUsuario');
        localStorage.setItem('idUsuario', res.data.id);        
        localStorage.removeItem('idStatus');
        localStorage.setItem('idStatus', "506");

        this.setState({"logado": true});
        this.setState({"id": res.data.id});

        window.location.replace("/admin/paineldecontrole");

      })
      .catch(error => {
        //this.setState({"mensagem": "ERROR: Email e/ou senha Incorretos" });
        this.toastMensagem("ERROR: CPF INCORRETO ADM" );
        localStorage.setItem('logado', false);
        localStorage.setItem('idUsuario', null);
        localStorage.setItem('idStatus', null);
        localStorage.setItem("X-Cookie-Status", null);
      })
    }
    toastMensagem(message){
      const M = window.M;
      M.toast({html: message, classes: 'rounded'})
    }

    render(){
      const {login, senha, lembrar} = this.state;

      return(
        <div id="log">
        <div>
          <div className="row">
            <div className="col s10 push-s1 m8 push-m2 l6 push-l3" >
              <div className="form card ">
                <div className="card-content black-text">
                  <span className="card-title">LOGIN</span>
                  <p></p>
                  <form action="" name="login" onSubmit={this.fazerLogin}>
                                <div className="row">
                                    <div className="input-field col s10 push-s1 m10 push-m1 l10 push-l1">
                                        <InputMask id="login" type="text" className="validate col s12  m12  l12 " mask="999.999.999-99" required
                                            name="login" value={login} onChange={this.changeHandler}/>
                                        <label htmlFor="login">CPF</label>   
                                    </div>
                                </div>
                                  <div className="row">
                                    <div className="input-field col s10 push-s1 m10 push-m1 l10 push-l1">
                                        <input id="senha" type="password" className="validate col s12  m12  l12 " required  minLength="5"
                                            name="senha" value={senha} onChange={this.changeHandler} />
                                        <label htmlFor="senha">Senha</label>   
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="input-field col s12 push-s1 m12 push-m1  l12 push-l1">
                                      <label>
                                        <input type="checkbox"  className="lembrar" name="lembrar" id="lembrar"/>
                                        <span>Lembrar meu login?</span>
                                      </label>
                                    </div>
                                  </div>
                                  <br />
                                    <div className="row">    
                                        <button className="btn waves-effect waves-light col s12  m12  l12 " type="submit" name="action">LOGAR</button>
                                    </div>
                                    <div className="progress" id="progress">
                                      <div className="indeterminate"></div>
                                  </div>
                            </form>
                </div>
                <div className="card-action">
                  <a href="/cadastro">Cadastre-se</a>
                  <a href="/esqueciasenha">Esqueci a senha</a>
                </div>
              </div>
            </div>
          </div>
        </div> 
        </div>
      );
    }

}
export default Login;




