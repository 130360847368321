import React, { Component } from 'react';
import {BrowserRouter, Switch, Route} from 'react-router-dom';


//Paginas
import Home from './component/home.component';
//Login
import Login from './component/login.component/index';
import Cadastro from './component/login.component/cadastrar';
import EsqueciSenha from './component/login.component/esqueci';
import Status from './component/status.component';

import Manutencao from './component/manutencao.component';

import Aporte from './component/aporte.component';
import Informativo from './component/informativo.component';
import Suporte from './component/suporte.component';
import Perfil from './component/perfil.component';
import Carteira from './component/carteira.component';
import Indique from './component/indique.component';
import Cursos from './component/cursos.component';
import Aula from './component/aula.component';
import Produto from './component/produto.component';
import AoVivo from './component/aovivo.component';
import NotFound from './component/notfound.component';
import Loja from './component/loja.component';
import QuemSomos from './component/quemsomos.component';
import Logout from './component/logout.component';
import Politica from './component/politica.component';
import Termo from './component/termo.component';
//import PrivateRoute from './auth';

//conteudo Aberto
import Aberto from './component/conteudoAberto.component';

//Produtos 



//template
import Menu from './template/menu';
import Footer from './template/footer';

// Emrpesa
import MenuAdm from './component/admin/menuAdm.component';
import HomeAdm from './component/admin/home.component';
import EmpAluno from './component/admin/aluno.component';
import EmpAporte from './component/admin/aporte.component';
import EmpConteudo from './component/admin/conteudo.component';
import EmpRelatorio from './component/admin/relatorio.component';
import Empresa from './component/admin/empresa.component';
//import MorningCall from './component/admin/morningcall.component';
import AulasAdm from './component/admin/aula.component';
import CursosAdm from './component/admin/curso.component';
import CarteiraQuattro from './component/admin/carteiraQuattro.component';
import ProdutoAdmin from './component/admin/produto.componente';

import Debug from './component/admin/debug.component';

class Routes extends Component{
    constructor(props){
        super(props)
        this.state = {
          carregou:'0',
          mensagem:'Seja bem vindo. Temos novas Ofertas para você!',
          status: localStorage.getItem('idStatus'),
          id: localStorage.getItem('idAluno'),
          token: localStorage.getItem('X-Cookie-Status')
        }
      } 
      
      render(){
        {
            if ( localStorage.getItem('idUsuario') !== '0' && localStorage.getItem('idUsuario') != null
                    && localStorage.getItem('idUsuario') !== "" && localStorage.getItem('idUsuario') !== "null" && this.state.token) {
                return(  
                    localStorage.getItem("idStatus") === "506"
                    ?
                    <BrowserRouter>
                        <MenuAdm/>
                        <Switch>
                            <Route path="/admin/paineldecontrole"  component={HomeAdm} />
                            <Route path="/admin/aluno"  component={EmpAluno} />
                            <Route path="/admin/aporte"  component={EmpAporte} />
                            <Route path="/admin/conteudo"  component={EmpConteudo} />
                            <Route path="/admin/relatorio"  component={EmpRelatorio} />
                            <Route path="/admin/empresa"  component={Empresa} />
                            <Route path="/admin/aulas"  component={AulasAdm} />
                            <Route path="/admin/cursos"  component={CursosAdm} />
                            <Route path="/admin/produto"  component={ProdutoAdmin} />
                            <Route path="/carteiraquattro"  component={CarteiraQuattro} />
                            <Route path="/status"  component={Status} />

                            <Route path="/admin/debug"  component={Debug} />
                            
                            <Route exact path="/logout"  component={Logout} />                    
                            <Route component={NotFound} />
                        </Switch>
                    </BrowserRouter>  
                    :          
                    <BrowserRouter>
                        <Menu/>
                        <Switch>
                            <Route exact path="/"  component={Home} />
                            <Route exact path="/manutencao"  component={Manutencao} />
                            <Route path="/aporte"  component={Aporte} />
                            <Route path="/suporte"  component={Suporte} />
                            <Route path="/perfil"  component={Perfil} />
                            <Route path="/carteira"  component={Carteira} />
                            <Route path="/informativo"  component={Informativo} />
                            <Route path="/politicadeprivacidade"  component={Politica} />
                            <Route path="/produtos"  component={Loja} />
                            <Route path="/quemsomos"  component={QuemSomos} />
                            <Route path="/termosdeuso"  component={Termo} />
                            <Route path="/indiqueamigos"  component={Indique} />
                            <Route path="/cursos/:idCurso"  component={Aula} />
                            <Route path="/produto/:idProduto"  component={Produto} />
                            <Route exact path="/cursos"  component={Cursos} />
                            <Route exact path="/aovivo"  component={AoVivo} />
                            
                            <Route path="/conteudoaberto"  component={Aberto} />
                            <Route exact path="/logout"  component={Logout} />
                            
                            <Route path="/login"  component={Login} />
                            <Route path="/cadastro"  component={Cadastro} />
                            <Route path="/esqueciasenha"  component={EsqueciSenha} />
                            <Route path="/status"  component={Status} />
                                            
                            <Route component={NotFound} />
                        </Switch>
                        <Footer/> 
                    </BrowserRouter>
                );
            } else {
                return(
                    <BrowserRouter>
                            <Switch>
                                <Route path="/login"  component={Login} />  
                                <Route path="/cadastro"  component={Cadastro} />  
                                <Route path="/esqueciasenha"  component={EsqueciSenha} /> 
                                <Route path="/status"  component={Status} /> 
                                <Route component={Login} />                      
                            </Switch>
                    </BrowserRouter>
                );
            }
    
            /*
            return( 
                <BrowserRouter>
                    <Switch>
                        <Route exact path="/"  component={Manutencao} />
                        <Route exact path="/manutencao"  component={Manutencao} />    
                        <Route component={Manutencao} />
                    </Switch>
                </BrowserRouter>
            );
            */
        }
    }
}
export default Routes;