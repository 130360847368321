import React, { Component } from 'react';

//Paginas
//import apiUrl from '../../axiosApi';
import './index.css';
/* img */



class Informativo extends Component{
    constructor(props){
      super(props)
      this.state = {        
        carregou:'0',
        mensagem:'',
        id: '',
        politica: '0',
        status: '',
      }
    }
    changeHandler = e => {
      this.setState({[e.target.name]: e.target.value})
    }

    render(){
      return( 
        <div className="politica">
          <div className="row">
            <div className="responsive-container col s12 m12 l12">
              <h2 className="flow-text center" >Política de Privacidade</h2>
            </div>  
          </div>
          <div className="row">
            <a target="_blank" rel="noopener noreferrer" href="https://testdeproj.000webhostapp.com/pdf/PoliticadePrivacidade.pdf">BAIXAR</a>
            <div className="responsive-container col s12 m12 l12">
              <iframe className="if" width="853" height="600" src="https://testdeproj.000webhostapp.com/pdf/PoliticadePrivacidade.pdf" frameBorder="0" allowFullScreen></iframe>
            </div>  
          </div>
            
        </div>
    );
}
}
export default Informativo;