import React, { Component } from 'react';

//Paginas
import apiUrl from '../../axiosApi';
import './index.css';

// img 



class Suporte extends Component{
    constructor(props){
      super(props)
      this.state = {
        carregou:'0',
        msg:'',
        id: '',
        nome: '',
        email: '',
        telefone: '',
        mensagem: '',
        lista: [],
      }
      sessionStorage.removeItem('tituloPagina');
      sessionStorage.setItem('tituloPagina', 'SUPORTE');
    }

    changeHandler = e => {
      this.setState({[e.target.name]: e.target.value})
    }

    listarTodosHttp = async e => {
      e.preventDefault();
      await apiUrl.get('/users/')
      .then(res => {
          this.setState({ lista: res.data});
        
      })
      .catch(error => {
        alert(error);
      })
    }


    cadastrarPergunta = async e => {      
      this.setState({"msg": ""});
      e.preventDefault();
      const {nome, email, telefone, mensagem} = this.state;
      await apiUrl.put('/suporte', {
        nome: nome,
        email: email,
        telefone: telefone,
        mensagem: mensagem})
      .then(res => {
        //Resposta do Servidor
        this.setState({"msg": "Pergunta Realizada. Logo entraremos em contato"});
        this.toastMensagem("Pergunta Realizada. Logo entraremos em contato")
        console.log(res.data);
        this.limpaCampos();
      })
      .catch(error => {
        this.setState({"msg": "Erro ao Realizar Pedido de Suporte"});  
        this.toastMensagem("Erro ao Realizar Pedido de Suporte" + error);        
        this.limpaCampos()
      })
    }

    limpaCampos() {
        this.setState({"nome": ""})
        this.setState({"email": ""})
        this.setState({"telefone": ""})
        this.setState({"mensagem": ""})
    }

    toastMensagem(message){
      const M = window.M;
      M.toast({html: message, classes: 'rounded'})
    }
    
    render(){
      const {nome, email, telefone, mensagem} = this.state;
      return( 
          /*<div className="suporte">
              <h2>Suporte</h2>
              <hr />
              <p>Conte com nosso suporte para tirar suas dúvidas e resolver as questões que surgirem. Preencha os campos abaixo que em até 2 dias úteis a nossa equipe irá lhe retornar o contato.</p>
              <p className="mensagem">
                {
                  this.state.msg !== '' ?  (
                    this.state.msg
                  ):''
                }
              </p>
              <form onSubmit = {this.cadastrarPergunta}>
                <table>
                  <tr>
                    <td>
                      <th>Nome</th>
                      <input type="text" placeholder="Nome" required
                         name="nome" value={nome} onChange={this.changeHandler}/>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <th>Email</th>
                      <input type="email" placeholder="seuEmail@email.com" required
                         name="email" value={email} onChange={this.changeHandler}/>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <th>Telefone</th>
                      <input type="text" placeholder="21900001111" required size="11"
                         name="telefone" value={telefone} onChange={this.changeHandler}/>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <th>Mensagem</th>
                      <textarea required id="" cols="40" rows="7"  
                        name="mensagem" value={mensagem} onChange={this.changeHandler}></textarea>                
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input  type="submit" value="Perguntar" />
                    </td>
                  </tr>
                </table>
              </form>
          </div>*/
          <div className="suporte">
            <div className="row">
              <div className="col s10 push-s1 m10 push-m1 l10 push-l1">      
                <blockquote>
                  <p >Conte com nosso suporte para tirar suas dúvidas e resolver as questões que surgirem. Preencha os campos abaixo que em até 2 dias úteis a nossa equipe irá lhe retornar o contato.</p>
                </blockquote>
              </div>
            </div>
            <div className="row">
              <form className="col s10 push-s1 m8 push-m2 l6 push-l3"
                onSubmit={this.cadastrarPergunta}>
                {/*<div className="row">
                  <div className="input-field col s10 push-s1 m8 push-m2 l6 push-l3">
                    <input id="nome" type="text" className="validate" required
                      name="nome" value= {nome} onChange={this.changeHandler}/>
                    <label htmlFor="nome">Nome Completo</label>
                  </div>
                </div>
                <div className="row">
                  <div className="input-field col s10 push-s1 m8 push-m2 l6 push-l3">
                    <input id="email" type="email" className="validate" required
                      name="email" value= {email} onChange={this.changeHandler}/>
                    <label htmlFor="email">Email</label>
                  </div>
                </div>
                <div className="row">
                  <div className="input-field col s10 push-s1 m8 push-m2 l6 push-l3">
                    <input id="telefone" type="text" className="validate" required
                      name="telefone" value= {telefone} onChange={this.changeHandler}/>
                    <label htmlFor="telefone">Telefone</label>
                  </div>
                </div>*/}
                <div className="row">
                  <div className="input-field col s10 push-s1 ">
                    <textarea id="mensagem" className="validate" required
                      name="mensagem" value= {mensagem} onChange={this.changeHandler}></textarea>
                    <label htmlFor="mensagem">Mensagem</label>
                  </div>
                </div>
                <div className="row">
                  <div className="col s12 m12 l12">
                    <button className="btn waves-effect waves-light col s10 push-s1 m10 push-m1 l6 push-l3" type="submit" name="action">PERGUNTAR</button>
                  </div>
                </div>
                </form>
              </div>
          </div>
    );
}
};
export default Suporte;