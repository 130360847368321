import React, { Component } from 'react';
import { Button, Card, Row, Col, Image, CardTitle } from 'react-materialize';
//import { Link }  from 'react-router-dom';

//Paginas

import apiUrl from '../../axiosApi';
import './index.css';

/* img */
import investimentoImg  from '../../assets/investimento.jpg';
import negocioImg  from '../../assets/negocio.jpg';
import aporteImg  from '../../assets/aportes.jpg';
import informativoImg  from '../../assets/informativo.jpg';
import indiqueImg  from '../../assets/indiqueAmigo.jpg';
import suporteImg  from '../../assets/suporte.jpg';

class Home extends Component{
      constructor(props){
        super(props)
        this.state = {
          totalRendimento: 0,          
          produtos:[],
          adquirido: [1,2],
          idCarteira: 0,
          carteira: [],
          aportes: [],
          saldo: 0,
        }        
        this.listarCarteiras();
        this.listarProdutos();
      } 

      listarProdutos = () =>{
        apiUrl.get("/produto")
        .then(res => {
            console.log(res.data);
  
            this.setState({ 
              produtos: JSON.parse(JSON.stringify(res.data)),
            });
  
        })
        .catch(error => {
          console.log(error);
        })
      }
      listarCarteiras = async () => {
        try{
          await apiUrl.get('/carteiras/')
          .then(res => {
              this.setState({ 
                idCarteira: res.data[0].id,
                aportes: res.data[0].aportes,
                carteira: res.data[0],
              });
              this.obterSaldoCarteira( res.data[0].id );
          })
        }catch(error){
            this.setState({"mensagem":error});
        } 
      }
      obterSaldoCarteira = async (idCarteira) => {
        try{
          await apiUrl.get(`/carteira/${idCarteira}/saldo`)
          .then(res => {
              this.setState({ saldo: res.data / 100 });
          })
        }catch(error){
            this.setState({"mensagem":error});
        } 
      }
      mascaraValor = (valor, zeros) =>{
        return valor.toFixed(zeros).replace(".", ",")
      }
      mascaraValor = (valor) =>{
        return valor.toFixed(2).replace(".", ",")
      }
      componentDidMount(){
        const M = window.M;
        document.addEventListener('DOMContentLoaded', function() {
          var elems = document.querySelectorAll('.slider');
          var instances = M.Slider.init(elems, {interval: 5000});
        });
        document.addEventListener('DOMContentLoaded', function() {
          var elems = document.querySelectorAll('.carousel');
          var instances = M.Carousel.init(elems, {
            fullWidth: true,
          });
        });
      }
      adquiriItem = e => {
        e.preventDefault();
        this.toastMensagem("Produto: " + e.target.name);
      }
      comprarCurso = e => {
        e.preventDefault();
        this.toastMensagem("Aguarde o lançamento do Curso");
      }
      toastMensagem(message){
        const M = window.M;
        M.toast({html: message, classes: 'rounded'})
      }
      verificaSeTemProduto = (codigo) =>{
        var verificado = false;
        var contador = 0;
        for(var i = 0; i < this.state.adquirido.length; i ++){
          if(this.state.adquirido[i] == codigo){
            contador++;
          }
        }
        if(contador == 1){
          verificado = true;
        }
        return verificado;
      }
      acessarPaginaProduto = e =>{
        e.preventDefault();
        this.toastMensagem("Redirecionando para a pagina . . . " + e.target.name);
        //window.location.href = "produto/" + e.target.name;
      }

      render(){
        const {saldo, produtos, adquirido} = this.state;
        return(
          <div className="loja">
            <div className="row">
              <div className="col s8 push-s2 m6 push-m3">
                    <div className="card-panel white z-depth-5">    
                      <h4 className="center">QTC {this.mascaraValor(saldo)}</h4>
                      <h5><a href="/carteira#extrato">Extrato</a></h5>            
                      <p>Adquira nossos produtos com QuattroCoins da sua carteira Quattro.</p> 
                      <p>Consiga mais QTCs <b><a href="/aporte">Aportando</a></b> ou <b><a href="indiqueAmigos">Indicando Amigos</a></b>.</p> 
                    </div>
                </div>
            </div>
           {/* <div className="row">
              <div className="col s8 push-s2 m8 push-m2 l4 push-l4">
                    <div className="card-panel white z-depth-5">
                      <h5 className="card-title grey-text text-darken-4">Adquira nosso curso de Investimento e Negócio.</h5>       
                        <p>Saia na frente e ainda recebe seu dinheiro de volta com 7% de lucro.</p><br />
                        <b>QTC {this.mascaraValor(1000)}</b>
                        <br />
                        <input type="submit"  value="Adquirir" onClick={this.comprarCurso} className="center" ></input>
                    </div>
                </div>
            </div>*/}
            <div className="row">
              <table className="responsive-table striped col s10 push-s1">
                <thead>
                  <tr>
                      <th>Produtos</th>
                      <th>Categoria</th>
                      <th>Preço</th>
                      <th>Comprar</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><b>Conteúdo Grátis</b></td>                    
                    <td>GRÁTIS</td>
                    <td><b>QTC {this.mascaraValor(0)}</b></td>
                    <td ><a className="waves-effect waves-light btn" href={"/"}>Acessar</a></td>
                  </tr>
                    {produtos.map(c =>                      
                      <tr key={c.id}>
                        <td><b>{c.nome}</b></td>
                        <td>{c.categoria}</td>
                        <td><b>QTC {this.mascaraValor(c.preco)}</b></td>
                        { this.verificaSeTemProduto(c.id)
                          ?
                            //<td ><input type="submit" name={c.id} value="Acessar" onClick={this.acessarPaginaProduto}></input></td>
                            <td ><a className="waves-effect waves-light btn" href={"/produto/"+ c.id}>Acessar</a></td>
                          :
                            //<td ><input type="submit" name={c.id} value="Adquirir" onClick={this.adquiriItem}></input></td>
                            <td ><a className="waves-effect waves-light btn" name={c.id} onClick={this.adquiriItem}>Adquirir</a></td>
                        }
                      </tr>
                    )} 
                </tbody>
              </table>
            </div>
          </div>
        );
    }
}
export default Home;