import React, { Component } from 'react';
import InputMask from 'react-input-mask'; 

//Paginas
import apiUrl from '../../axiosApi';
import axiosApi from '../../axiosApi';
import './index.css';

//Img
//import alunoImg from '../../assets/iconAluno.png';

function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

class Cadastrar extends Component{

    constructor(props){
      super(props);
      this.state = {
        carregou:'0',
        mensagem:'',
        id: null, 
        nome: '',
        telefone: '',
        cpf: '',
        dtNascimento: '',
        login: '',
        senha: '',
        confirmaEmail: '',
        confirmaSenha: '',
        logado: '',
        indicacao: getParameterByName('indicacao') || sessionStorage.getItem('indicacao'),
      };
    }

    changeHandler = e => {
      this.setState({[e.target.name]: e.target.value})
    }

    validadorCpf(cpf){
      var contador = 0;
      var digito1 = 0;
      var digito2 = 0;
      var resto = 0;
      var valido1 = false;
      var valido2 = false;
      for(let i = 1; i <= 9; i++ )
        digito1 = digito1 +  parseInt(cpf.substring(i-1, i)) * (11 - i); 
      resto = (digito1 * 10) % 11;
      if ((resto === 10) || (resto === 11)) 
        resto = 0;
      if (resto === parseInt(cpf.substring(9, 10)) )
        valido1 =  true;

      //Segundo Digito
      for(let i = 1; i <= 10; i++ )
        digito2 = digito2 +  parseInt(cpf.substring(i-1, i)) * (12 - i); 
      resto = (digito2 * 10) % 11;
      if ((resto === 10) || (resto === 11)) 
        resto = 0;
      if (resto === parseInt(cpf.substring(10, 11)) )
        valido2 =  true;

      for(let i = 1; i <= 10; i++){
        if(parseInt(cpf.substring(0,1)) ===  parseInt(cpf.substring(i, i+1)))
          contador++; 
      }
      console.log(contador);
      if((valido1 === true) && (valido2 === true) && (contador !== 10)){
        return true;
      }else{
        return false;
      }
    }
    cadastrarUsuario = async e => {
      e.preventDefault();
      document.getElementById('progress').style.display = 'block'
      var cpf1 = this.state.cpf.toString().replace('.','').replace('-','').replace('.','')
      var tel = this.state.telefone.replace('(', '').replace(')', '').replace(' ', '').replace('-', '');
      if(!this.validadorCpf(cpf1)){        
        this.toastMensagem("CPF Inválido" +  this.validadorCpf(this.state.login) );
      }else{
        if(this.state.login === this.state.confirmaEmail){
          if(this.state.senha === this.state.confirmaSenha){
              let _body = {
                  nome: this.state.nome,
                  cpf: cpf1,
                  dataNascimento: this.state.dtNascimento,
                  telefone: tel,
                  email: this.state.login,
                  senha: this.state.senha,
                  indicacao: this.state.indicacao,
                };

                console.log(_body);

                //alert(this.state.nome + this.state.cpf+  this.state.dtNascimento + this.state.login + this.state.senha );
                await await axiosApi.post('/cadastrar', _body)
                  .then(res => {
                    //alert( JSON.stringify(data) );
                    console.log(res.data);
                    this.toastMensagem('Usuário cadastrado com sucesso!')
                    document.getElementById('progress').style.display = 'none'
                    window.location.replace("/login");
                  })
                  .catch(error => {
                    console.log(error);
                    this.toastMensagem("Erro ao tentar cadastrar / CPF já cadastrado. Tente Novamente")
                    document.getElementById('progress').style.display = 'none'
                  }); 
          }else{
              this.toastMensagem("Senhas devem ser idênticas.");
              document.getElementById('progress').style.display = 'none'
          }

        }else{
            this.toastMensagem("Emails devem ser idênticos. Email: " + this.state.login);
            document.getElementById('progress').style.display = 'none'
        }
      }
    }
    fazerLoginAdmin = async e => {
      //e.preventDefault();
      this.setState({"mensagem": ""}) 

      await apiUrl.post(`/admin`, {
        login: this.state.login.substring(8),
        senha: this.state.senha,
      })
      .then(res => {
        //Resposta do Servidor
        console.log(res);

        this.setState({"mensagem":  "Conectando . . ."});        
        this.toastMensagem("Administrador Conectado . . ." );
        sessionStorage.removeItem('logado');
        sessionStorage.setItem('logado', true);
        sessionStorage.removeItem('idUsuario');
        sessionStorage.setItem('idUsuario', res.data.id);        
        sessionStorage.removeItem('idStatus');
        sessionStorage.setItem('idStatus', "506");

        this.setState({"logado": true});
        this.setState({"id": res.data.id});

        window.location.replace("/");

      })
      .catch(error => {
        //this.setState({"mensagem": "ERROR: Email e/ou senha Incorretos" });
        this.toastMensagem("ERROR: Email e/ou senha Incorretos" );
        sessionStorage.setItem('logado', false);
        sessionStorage.setItem('idUsuario', null);
        sessionStorage.setItem('idStatus', null);

      })
    }
    toastMensagem(message){
      const M = window.M;
      M.toast({html: message, classes: 'rounded'})
    }

    render(){
      const {nome, cpf, dtNascimento, login, confirmaEmail, senha, confirmaSenha, telefone} = this.state;

      return(  
        <body className="cad">
        <div>
          <div className="row">
            <div className="col s12 m8 push-m2 l6 push-l3">
              <div className="form card">
                <div className="card-content black-text">
                  <span className="card-title">CADASTRE-SE</span>
                  <p></p>
                  <form action="" name="login" onSubmit={this.cadastrarUsuario}>
                                <div className="row">
                                    <div className="input-field col s10 push-s1 m10 push-m1   l12 push-l1">
                                        <input id="nome" type="text" className="validate col s12  m12  l10 " required
                                            name="nome" value={nome} onChange={this.changeHandler}/>
                                        <label htmlFor="nome">Nome Completo</label>   
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="input-field col s10 push-s1 m10 push-m1  l12 push-l1">
                                        <InputMask id="cpf" type="text" className="validate col s12  m12  l10 " required mask="999.999.999-99"
                                            name="cpf" value={cpf} onChange={this.changeHandler}/>
                                        <label htmlFor="cpf">CPF</label>   
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="input-field col s10 push-s1 m10 push-m1  l12 push-l1">
                                        <input id="dtNascimento" type="date" className="validate col s12  m12  l10 " required
                                            name="dtNascimento" value={dtNascimento} onChange={this.changeHandler}/>
                                        <label htmlFor="dtNascimento">Data de Nascimento</label>   
                                    </div>
                                </div>
                                <div className="row">
                                  <div className="input-field col s10 push-s1 m10 push-m1  l12 push-l1">
                                    <InputMask id="tel" type="tel" mask="(99)9 9999-9999" required placeholder="Cel: (99)9 9999-9999 / Fixo Cel: (99)0 9999-9999"
                                      name="telefone" value={telefone} onChange={this.changeHandler}/>
                                    <label htmlFor="telefone">Celular</label>
                                  </div>
                                </div>
                                <div className="row">
                                    <div className="input-field col s10 push-s1 m10 push-m1  l12 push-l1">
                                        <input id="login" type="email" className="validate col s12  m12  l10 " required
                                            name="login" value={login} onChange={this.changeHandler}/>
                                        <label htmlFor="login">Email</label>   
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="input-field col s10 push-s1 m10 push-m1  l12 push-l1">
                                        <input id="confirmaEmail" type="email" className="validate col s12  m12  l10  " required
                                            name="confirmaEmail" value={confirmaEmail} onChange={this.changeHandler}/>
                                        <label htmlFor="confirmaEmail">Confirme o Email</label>   
                                    </div>
                                </div>
                                  <div className="row">
                                    <div className="input-field col s6 push-s1  m6 push-m1  l6 push-l1">
                                        <input id="senha" type="password" className="validate col s10  m10  l10 " required  minLength="6"
                                            name="senha" value={senha} onChange={this.changeHandler}/>
                                        <label htmlFor="senha">Senha</label>   
                                    </div>
                                    <div className="input-field col s6  m6  l6">
                                        <input id="confirmaSenha" type="password" className="validate col s10  m10  l10 " required  minLength="6"
                                            name="confirmaSenha" value={confirmaSenha} onChange={this.changeHandler}/>
                                        <label htmlFor="confirmaSenha">Confirme a senha</label>   
                                    </div>
                                  </div>
                                    <div className="row">    
                                        <button className="btn waves-effect waves-light col s12  m12  l12 " 
                                        type="submit" name="action">CADASTRAR</button>
                                    </div>
                                    <div className="progress" id="progress">
                                      <div className="indeterminate"></div>
                                  </div>
                            </form>
                </div>
                <div className="card-action">
                  <a href="/login">Login</a>
                  <a href="/esqueciasenha">Esqueci a senha</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </body>
      );
    }

}
export default Cadastrar;



