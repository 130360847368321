import React, { Component } from 'react';
import moment from 'moment';
//Paginas
import apiUrl from '../../axiosApi';
import './index.css';

import pageView from '../../utils/pageView';

class Indique extends Component {

    constructor(props){
      super(props)
      this.state = {        
        carregou:'0',
        mensagem:'Indica vai ...',
        id: localStorage.getItem('idUsuario'),
        nome: '',
        email: '',
        status: '',
        lista: [],
      }
      this.listarIndicacoesAmigo();

      pageView.log('indicacao-amigo');
    }

    changeHandler = e => {
      this.setState({[e.target.name]: e.target.value})
    }

    indicarAmigo = async e => {
      e.preventDefault();
      this.setState({"mensagem": ""}) 
      
      const {id, nome, email} = this.state;
      let body = {
        idUsuario: id,
        nome: nome,
        email: email
      };

      if ( !this.state.email )
        return;

      await apiUrl.post(`/indicacao-amigo`, body)
      .then(res => {
        this.setState({"mensagem": "Amigo Indicado"});       
        this.toastMensagem("Amigo Indicado com Sucesso");
        this.listarIndicacoesAmigo();
      })
      .catch(error => {
        this.setState({"mensagem": "Erro ao indicar amigo"});        
        this.toastMensagem("Erro ao indicar amigo" + error);
      })

    }

    listarIndicacoesAmigo = async e => {
      //const {id} = this.state;
      await apiUrl.get(`/indicacao-amigo`)
      .then(res => {
        console.log(res.data);
        this.setState({
          "lista": res.data
        });
      })
      .catch(error => {
        this.setState({"mensagem": "Erro ao listar amigos indicados"});
        this.toastMensagem("Erro ao listar amigos indicados" + error);
      })

    }

    toastMensagem(message){
      const M = window.M;
      M.toast({html: message, classes: 'rounded'})
    }

    render(){
      const {nome, email, lista} = this.state;
      return( 
          <div className="indique">
            <div className="row">
              <div className="col s10 push-s1 m10 push-m1 l10 push-l1">      
                <blockquote>
                  <p>Além de ser o maior programa de Cashback do mercado, na Quattro você recebe benefícios indicando seus amigos. Basta preencher o campo abaixo, para cada amigo indicado que contratar nosso curso você receberá 1% do aporte dele em Quattrocoins para utilizar como quiser de acordo com o programa de Cashback da Quattro.</p>
                </blockquote>
              </div>
            </div>
            <div className="row">
              <form className="col s10 push-s1 m8 push-m2 l6 push-l3"
                onSubmit={this.indicarAmigo}>
                <div className="row">
                  <div className="input-field col s10 push-s1 ">
                    <input id="nome" type="text" className="validate" required
                      name="nome" value= {nome} onChange={this.changeHandler}/>
                    <label htmlFor="nome">Nome do Indicado</label>
                  </div>
                </div>
                <div className="row">
                  <div className="input-field col s10 push-s1 ">
                    <input id="email" type="email" className="validate" required
                      name="email" value= {email} onChange={this.changeHandler}/>
                    <label htmlFor="email">Email do Indicado</label>
                  </div>
                </div>
                <div className="row">
                  <div className="col s12 m12 l12">
                  <button className="btn waves-effect waves-light col s12" type="submit" name="action">INDICAR</button>
                  </div>
                </div>
                </form>
              </div>
              <div className="row">
              <div className="col s12 m8 push-m2 l6 push-l3">      
                <table className="responsive-table striped">
                  <thead>
                    <tr>
                        <th>Data Indicação</th>
                        <th>Email</th>
                        <th>Status</th>
                        <th>Data Entrada</th>
                    </tr>
                  </thead>
                  <tbody>
                  {lista.map(lista => (
                              <tr key = {lista.guid} >
                                  <td>{moment(lista.dataCadastro, "YYYY-MM-DD").format("DD/MM/YYYY")}</td>
                                  <td>{lista.email}</td>
                                  <td>{lista.conviteAceito ? "Aceito" : "Pendente"}</td>
                                  <td>{lista.dataAceite ? moment(lista.dataAceite, "YYYY-MM-DD").format("DD/MM/YYYY") : '-'}</td>
                              </tr>
                            ))} 
                  </tbody>
                </table>
                </div>
              </div>
          </div>
    );
}
};
export default Indique;