import React, { Component } from 'react';

//Paginas
import apiUrl from '../../axiosApi';
import './index.css';

/* img */
import parceiroImg from '../../assets/parceiro1.png';
import fundo from '../../assets/fundo3.jpg';

class Cursos extends Component{
    constructor(props){
      super(props)
      this.state = {
        id:'1',
      }
    }
    changeHandler = e => {
      this.setState({[e.target.name]: e.target.value})
    } 
    componentDidMount(){
      const M = window.M;
      document.addEventListener('DOMContentLoaded', function() {
        var elems = document.querySelectorAll('.collapsible');
        var instances = M.Collapsible.init(elems, {
          indicators:true,
        });
      });
      document.addEventListener('DOMContentLoaded', function() {
        var elems = document.querySelectorAll('.tabs');        
        var instance = M.Tabs.init(elems, {});
      });
      document.addEventListener('DOMContentLoaded', function() {
        var elems = document.querySelectorAll('.carousel.carousel-slider');        
        var instance = M.Carousel.init({
          fullWidth: true,
          indicators: false,
        });
      });
      
    }
    toastMensagem(message){
      const M = window.M;
      M.toast({html: message, classes: 'rounded'})
    }
    render(){
      const {videoPrincipal, nomeVideo, pdf, texto, lista, temas} = this.state;
      
      return( 
        <div className="quemsomos">
          <div className="carousel carousel-slider center">
            <img src={fundo} alt="Mão na Massa" />
            <div className="carousel-fixed-item center">
              <div className="row">
                <div className="col s12">
                  <ul className="tabs">
                    <li className="tab col s3"><a href="#quem">Quem Somos</a></li>
                    <li className="tab col s3"><a href="#parceiros">Parceiros</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          
          <div id="quem">
            <div className="row">
              <div className="col s12 m8 push-m2 l8 push-l2">
                <h3 className="center">Quem somos</h3>
                <p className="flow-text center">Somos a plataforma de cursos de investimentos e negócios com o maior programa de Cashback do mercado.</p>
              </div>
              <div className="col s12 m8 push-m2 l8 push-l2">
                <h4 className="center">Missão</h4>
                <p className="flow-text center">Inserir nossos clientes no futuro e diminuir o abismo que os novos tempos vão criar entre quem tem educação financeira e empreendedora e quem não tem.</p> 
              </div>
              <div className="col s12 m8 push-m2 l8 push-l2">
                <h4 className="center">Visão</h4>
                <p className="flow-text center">Ser a maior plataforma de educação financeira do Brasil, trazer inclusão para o maior número de brasileiros possíveis para que tenham independência econômica.</p> 
              </div>
              <div className="col s12 m8 push-m2 l8 push-l2">
                <h4 className="center">Valores</h4>
                <p className="flow-text center">Compromisso com a educação de qualidade e trazendo o maior número de benefícios para que nossos clientes entrem no futuro, fugindo do abismo.</p> 
              </div>
              
            </div>
          </div>
          <div id="parceiros">
            <div className="row">
              <div className="col s12">
                <div className="chip">
                  <img src={parceiroImg} alt="Airbrigde Curso de Idiomas" />
                  <h5>Airbrigde</h5>
                  <p>Airbridge é uma plataforma de ensino online que mais do que apenas fornecer estudos, desenvolveu um aprendizado dinâmico e interativo que liga você ao nosso conteúdo com muito mais eficiência e fluidez.</p>
                  <h5 className="flow-text"><a href="https://www.airbridge.com.br/index.jsp" target="_blank">Saiba Mais</a></h5>
                </div>
              </div>
            </div>   
          </div>
          
        </div>
        
    );
    }
};
export default Cursos;