import React, { Component } from 'react';
import { Button, Card, Row, Col, Image, CardTitle } from 'react-materialize';
//import { Link }  from 'react-router-dom';

//Paginas

import apiUrl from '../../../axiosApi';
import './index.css';

/* img */
import investimentoImg  from '../../../assets/fundo2.jpg';

class Home extends Component{
      constructor(props){
        super(props)
        this.state = {
          carregou:'0',
          mensagem:'Seja bem vindo. Temos novas Ofertas para você!',
          qntAlunos: 0,
          qntAportes: 0,
          
        }        
        this.quantidadeAlunosCadastrados();       
        this.quantidadeAportesAtivos();       
      } 
      quantidadeAlunosCadastrados = () =>{
        apiUrl.get("/usuario")
        .then(res => {
            console.log(res.data);
            this.setState({ qntAlunos: res.data.length});
        })
        .catch(error => {
          alert(error);
        })
      }
      quantidadeAportesAtivos = () => {
        var qnt = 0;
        try{
          apiUrl.get('/admin/aporte/novos')
          .then(res => {
              console.log(res.data);
              
              for(var i=0; i < res.data.length; i++){
                if(res.data[i].status == 1){
                  qnt++;
                }
              }
              this.setState({qntAportes: qnt})
          })
        }catch(error){
            this.setState({"mensagem":error});
        } 
      }
      componentDidMount(){
        const M = window.M;
        document.addEventListener('DOMContentLoaded', function() {
          var elems = document.querySelectorAll('.slider');
          var instances = M.Slider.init(elems, {interval: 5000});
        });
      }
      
      render(){
        const {status} = this.state;
        return(
          <div className="painel"> 
            <div className="row">
              <div className="col s12 m4">
                    <ul className="menuLt">
                      <li><a href="/admin/cursos">Cursos</a></li>
                      <li><a href="/admin/aulas">Aulas</a></li>
                      <li><a href="/admin/aulas#aoVivo">Aula Ao vivo</a></li>
                      <li><a href="/admin/aporte">Aportes</a></li>
                      <li><a href="/admin/conteudo#morningcall">Morning Call</a></li>
                      <li><a href="/admin/conteudo#popups">Pop Ups</a></li>
                      <li><a href="/admin/relatorio#aporte">Relatório Aporte</a></li>
                      <li><a href="/admin/relatorio#alunos">Relatório Aluno</a></li>
                      <li><a href="/admin/relatorio#dashboard">Relatório Dashboard</a></li>
                      <li><a href="/admin/empresa#carteira">Carteira Digital</a></li>
                      <li><a href="/admin/empresa#grafico">Gráfico Empresa</a></li>                      
                      {/*<li><a href="/carteiraquattro">Carteira Quattro</a></li>*/}
                      <li><a href="/admin/debug">Debug</a></li>
                      <li><a href="/logout">logout</a></li>
                    </ul>
              </div>
              <div className="col s8">
                <div className="col s6 m4 l3">
                  <div className="card darken-1">
                    <div className="card-content black-text">
                      <span className="card-title flow-text">Alunos Cadastrados</span>
                      <h3>{this.state.qntAlunos}</h3>
                    </div>
                    <div className="card-action">
                      <a href="/admin/aluno">+</a>
                    </div>
                  </div>
                </div>
                <div className="col s6 m4 l3">
                  <div className="card darken-1">
                    <div className="card-content black-text">
                      <span className="card-title flow-text">Aportes em Aberto</span>
                      <h3>{this.state.qntAportes}</h3>
                    </div>
                    <div className="card-action">
                      <a href="/admin/aporte">+</a>
                    </div>
                  </div>
                </div>
               
               </div>
                
            </div>
          </div>
        );
    }
}
export default Home;