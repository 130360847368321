import React, { Component } from 'react';
//import { Link }  from 'react-router-dom';

//Paginas

import apiUrl from '../../axiosApi';
import './index.css';
import PolarChart from './chartPie'
import LineChart from './chartLine'

/* img */
//import negocioImg  from '../../assets/negocio.jpg';
import propagandaImg from '../../assets/predio1.jpg';

class Home extends Component{
      constructor(props){
        super(props)
        this.state = {
          carregou:'0',
          mensagem:'QUATTRO, muito além de um curso!',
          nome: '',
          nomeVideo: 'Aula1',
          videoPrincipal: 'https://www.youtube.com/embed/h_D3VFfhvs4?start=65',
          id: localStorage.getItem('idUsuario'),
          status: localStorage.getItem('idStatus'),
          lista: [],
        }
        sessionStorage.removeItem('tituloPagina');
        sessionStorage.setItem('tituloPagina', 'CONTEÚDO ABERTO');
        this.listarCursosResumo();
      }      
  
      /*(idSelecionado, nomeSlelcionado, urlVideoSelecionado, textoSelecionado, pdfSelecionado)*/
      alterarVideo =  e =>{
        e.preventDefault();
        console.log(e.target.id)
        this.getAula(e.target.id);
      };
      getAula= async (numero) =>{
        await apiUrl.get(`/curso/1/aula/${numero}`).then( res => {
          console.log(res);
          this.setState({"videoPrincipal": res.data.videoUrl});
          this.setState({"nomeVideo": res.data.nome});
          document.getElementById('video').style.display = "block";
        })
        .catch( error => {
            console.log("Buscar Aula");
            console.log(error);
            this.setState({"mensagem": "Erro ao carregar os cursos"});
        });
      }
      listarCursosResumo = async() =>{
        await apiUrl.get(`/cursos/resumo`)
        .then( res => {
          console.log(res.data.length);
          console.log(res.data[0].aulas);
          console.log(res.data[1].aulas);
          
          this.setState({lista: res.data[0].aulas });
          for (let c = 0; c < res.data.length; c++){
            console.log(c);
            //this.setState({lista: res.data[1].aulas +  res.data[2].aulas});
            //this.buscarCursoResumo(c.id);
          }
         // for (let a of res.data.aulas){
            //console.log(a.numero)
            //this.buscarAula(res.data.id, a.numero);
          //}
        })
        .catch( error => {
            console.log("Buscar Cursos Error");
            console.log(error);
            this.setState({"mensagem": "Erro ao carregar os cursos"});
        });
      }
      componentDidMount(){
        const M = window.M;
        document.addEventListener('DOMContentLoaded', function() {
          var elems = document.querySelectorAll('.collapsible');
          M.Collapsible.init(elems, {
            indicators:true,
          });
        });
      }
      toastMensagem(message){
        const M = window.M;
        M.toast({html: message, classes: 'rounded'})
      }    

      render(){
        const {lista,videoPrincipal} = this.state;
        return(
          <div className="aberto">     
            <h2>Nossa Carteira</h2>
            <hr /> 
            <div className="row">
              <div className="col s12 m6 l6">
                  <div className="card-panel white z-depth-5">
                    <span className="card-title grey-text text-darken-4"><h5>PRODUTOS</h5></span>
                    <PolarChart displayTitle="true"/>                    
                    <a href="/produtos"><h5>Saiba mais</h5></a>
                  </div>
              </div>
              <div className="col s12 m6 l6">                
                  <div className="card-panel white z-depth-5">
                    <span className="card-title grey-text text-darken-4"><h5>CRESCIMENTO</h5></span>
                      <LineChart displayTitle="true"/>
                      <a href="/produtos"><h5>Saiba mais</h5></a>
                  </div>
              </div>
            </div>
            <h2>Vídeos</h2>
            <hr />
            <div id="videos">
              <div id="video" className="row">
                <iframe title="Video" width="560" height="315" src={videoPrincipal}  className="col s12 m8 push-m2 l6-push3"
                  frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                  allowFullScreen resquestfocus="true"></iframe>
                <br/>
              </div>
            </div>
                {/*lista.map(lista => (
                  <li key = {lista.id} id={""+lista.numero} onClickCapture={this.alterarVideo}>
                    <img id={""+lista.numero} alt="thumbnail do video" src={propagandaImg} onClickCapture= {this.alterarVideo}/>
                    <p id={""+lista.numero} className="time">{lista.duracao/100}min</p> 
                    <h4 id={""+lista.numero}>{lista.nome }</h4> 
                  </li>
                ))*/}
            <div className="row">
                <div className="col s12 m6 l4 push-l2">
                  <ul className="collapsible">
                    <li>
                      <div className="collapsible-header center">INVESTIMENTO</div>
                      <div className="collapsible-body">
                        <ul className="collapsible">
                          <div className="row">
                          {lista.map(lista => (
                            <div className="col s12 m12 l12" key = {lista.id}>
                            <li key = {lista.id} id={""+lista.numero} onClickCapture={this.alterarVideo}>
                              <img id={""+lista.numero} className="responsive-img" src={propagandaImg} alt="" />
                              <p id={""+lista.numero} className="time">{lista.duracao/100}min</p> 
                              <p id={""+lista.numero}>{lista.nome }</p>
                              <div className="collapsible-header"></div>
                            </li>
                            </div>
                          ))}
                          </div>
                        </ul>
                      </div>
                    </li>
                    
                  </ul>
                </div>
                <div className="col s12 m6 l4 push-l2">
                  <ul className="collapsible">
                    <li>
                      <div className="collapsible-header center">NEGÓCIO</div>
                      <div className="collapsible-body">
                        <ul className="collapsible">
                          <div className="row">
                          {lista.map(lista => (
                            <div className="col s12 m12 l12" key = {lista.id}>
                            <li key = {lista.id} id={""+lista.numero} onClickCapture={this.alterarVideo}>
                              <img id={""+lista.numero} className="responsive-img" src={propagandaImg} alt="" />
                              <p id={""+lista.numero} className="time">{lista.duracao/100}min</p> 
                              <p id={""+lista.numero}>{lista.nome }</p>
                              <div className="collapsible-header"></div>
                            </li>
                            </div>
                          ))}
                          </div>
                        </ul>
                      </div>
                    </li>
                    
                  </ul>
                </div>
            </div>
            <hr /> 
          </div>
        );
    }
}
export default Home;