import React, { Component } from 'react';
import moment from 'moment';

//Paginas
import apiUrl from '../../axiosApi';
import './index.css';
import { Preloader } from 'react-materialize';

import pageView from '../../utils/pageView';

//IMGS
//import informativoImg  from '../../assets/fundo2.jpg';

function nl2br(texto) {
  return texto.replace(new RegExp('\r?\n','g'), '<br />');
}


class Aula extends Component{
  constructor(props){
    super(props);

    /*
      numero;      nome;         descricao;  urlVideo
      urlPdf;      duracao;      texto;      temas
      urlBanner;   urlThumbnail
    */
    this.state = {
      idCursoAluno: 0,
      idCurso: props.match.params.idCurso,
      idUsuario: localStorage.getItem('idUsuario'),
      nome: "",
      texto: "",
      temas: "",
      porcentagem: 0,
      aulas: [],
      loading: true,
      aulaAtual: 0,
      indiceAulaAtual: 0,
      progresso: [],
      listaTemas: [],
    }
    this.listarAulas();

    pageView.log('aula');
  }

  changeHandler = e => {
    this.setState({[e.target.name]: e.target.value})
  }
  listarAulas = async () =>{
    await apiUrl.get(`/usuario-curso/${this.state.idUsuario}/${this.state.idCurso}`)
    .then(res => {
      console.log(res.data);

      if (!res.data)
        window.location.href = '/';

      /*
      ativo: true
      aulas: []
      curso: {thumbnail: "curso_2_thumbnail.png", banner: "curso_2.png", dataCadastro: "2020-05-23 22:16:48", id: 2, nome: "Negócios :)", …}
      dataCadastro: "2020-07-26 17:28:30"
      dataConclusao: null
      id: 39
      idCurso: 2
      idUsuario: 13
      porcentagem: 0
      ultimaVisita: "2020-07-26 17:28:30"
      */      

      // Monta a estrutura fake de tópicos
      let listaTemas = [];
      let temaAnterior = null;
      let indice = -1;
      for (let i=0; i < res.data.aulas.length; i++) {
        let _aula = res.data.aulas[i];
        if ( _aula.temas !== temaAnterior ) {
          listaTemas.push( [ _aula.temas, [] ] );
          temaAnterior = _aula.temas;
          indice++;
        }

        if (indice >= 0)
          listaTemas[indice][1].push(_aula);
      } 

      console.log("lista:")
      console.log(listaTemas)

      // Obter a aula atual
      indice = 0;
      if ( res.data.usuarioAula.length > 0 ) {        
        let _data = res.data.usuarioAula.dataConclusao;
        let ultimaAula = res.data.usuarioAula[0];
        
        for (let i = 1; i < res.data.usuarioAula.length; i++) {
          if ( res.data.usuarioAula[i].dataConclusao > _data ) {
            _data = res.data.usuarioAula[i].dataConclusao;
            ultimaAula = res.data.usuarioAula[i];
          }
        }
        
        for (let i=0; i < res.data.aulas.length; i++) {
          if ( res.data.aulas[i].id === ultimaAula.idAula ) {
            indice = i+1;
            break;
          }
        }
      
      }


      if ( indice >= res.data.aulas.length )
        indice = 0;
      
      this.setState({
        idCursoAluno: res.data.id,
        nome: res.data.curso.nome,
        descricao: res.data.curso.descricao,
        temas: res.data.curso.temas,
        aulas: res.data.aulas,
        porcentagem: res.data.porcentagem,
        progresso: res.data.usuarioAula,
        loading: false,
        indiceAulaAtual: indice,
        aulaAtual: res.data.aulas[indice],
        listaTemas: listaTemas
      });

      console.log(this.state)

    })
    .catch(error => {
      alert(error);
      console.log(error);
      window.history.go(-1);
    });
  }
  concluirAula = async () => {
    // testa se a aula já está marcada como concluída
    if ( this.state.progresso.filter( p => p.idAula === this.state.aulaAtual.id).length > 0 )
      return;

    let body = {
      idUsuarioCurso: this.state.idCursoAluno,
      idUsuario: this.state.idUsuario,
      idAula: this.state.aulaAtual.id,
      idCurso: this.state.idCurso,
      dataConclusao: moment().format('YYYY-MM-DD HH:mm:ss'),
    };

    this.setState({
      progresso: [ ...this.state.progresso, body ],
      porcentagem: parseInt( (100 * (this.state.progresso.length + 1) ) / this.state.aulas.length),
    })


    console.log(this.state.progresso);
    await apiUrl.post(`/usuario-aula`, body)
    .then(res => {
      console.log(res.data);
    })
    .catch(error => {
      this.toastMensagem(error);
      //window.history.go(-1);
    });
  }
  chamarProximo = e => {      
    e.preventDefault();

    this.concluirAula();

    let indice = (parseInt(this.state.indiceAulaAtual) + 1);
    
    // Concluiu o curso
    if ( indice >= this.state.aulas.length ) {
      //alert("Parabéns! Você concluiu o curso!");
      return;
    }

    this.setState({
      indiceAulaAtual: indice,
      aulaAtual: this.state.aulas[indice],
    });

  }
  chamarAnterior = e => {      
    e.preventDefault();

    let indice = this.state.indiceAulaAtual - 1;
    
    if ( indice < 0 ) {
      return;
    }

    this.setState({
      indiceAulaAtual: indice,
      aulaAtual: this.state.aulas[indice],
    });
    
  }
  chamarAula = (idAula) => {      
    //e.preventDefault();
    //this.concluirAula();

    let indice = 0;

    for (let i=0; i < this.state.aulas.length; i++) {
      if ( this.state.aulas[i].id === idAula)
        indice = i;
    }

    // Concluiu o curso
    // if ( indice >= this.state.aulas.length ) {
    //   alert("Parabéns! Você concluiu o curso!");
    //   return;
    // }

    this.setState({
      indiceAulaAtual: indice,
      aulaAtual: this.state.aulas[indice],
    });

  }
  lermais = e =>{
    e.preventDefault();
    
    if(document.getElementById('texto').style.display === "none") {
      document.getElementById('textoCompleto').style.display = "none";
      document.getElementById('texto').style.display = "block";
    } else {    
      document.getElementById('texto').style.display = "none";
      document.getElementById('textoCompleto').style.display = "block";
    }
  }
  componentDidMount(){
    const M = window.M;
    document.addEventListener('DOMContentLoaded', function() {
      var elems = document.querySelectorAll('.collapsible');
      //var instances = M.Collapsible.init(elems, {
      M.Collapsible.init(elems, {});
    });
    document.addEventListener('DOMContentLoaded', function() {
      var elems = document.querySelectorAll('.scrollspy');
      //var instances = M.ScrollSpy.init(elems, {
      M.ScrollSpy.init(elems, {
        scrollOffset: 20,
      });
    });
    document.addEventListener('DOMContentLoaded', function() {
      var elems = document.querySelectorAll('.slider');
      //var instances = M.Slider.init(elems, {
      M.Slider.init(elems, {
        interval: 5000,
        fullWidth: false,
      });
    });
  }
  toastMensagem(message){
    const M = window.M;
    M.toast({html: message, classes: 'rounded'})
  }
    
  render(){
    const {aulaAtual, nome, porcentagem, progresso, listaTemas, loading} = this.state;
  
      /*
      descricao: "Descrição aqui"
      duracao: 640
      id: 6
      nome: "Aula 1"
      numero: "21"
      urlPdf: "qwewqe"
      urlBanner: "http://localhost:4500/media/imgs/aula_6.png"
      urlThumbnail: "http://localhost:4500/media/imgs/aula_6_thumbnail.png"
      urlVideo: "qwewqe"
      temas: "Bolsa de Valores"
      texto: "Texto aqui"
      */
    
     
    return( 
      <div>
        {loading || !aulaAtual
        ?
          <div className="aula">
            <div>
              <Preloader id="carregamento"></Preloader>
            </div>
          </div>
        :
          <div className="aula">
            <h3 className="center">
              {nome}          
            </h3>
              <div>
                <div id="video" className="row section scrollspy">
                  <div className="responsive-video-container col s12 m8 l9">
                    <iframe title="video-aula" width="853" height="480" src={aulaAtual.urlVideo} frameBorder="0" allowFullScreen
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" resquestfocus="true"></iframe>
                      <ul className="collection with-header">
                        <li className="collection-header">
                          <b>{aulaAtual.nome}</b>
                          <input type="button" className="right" value="PDF" onClickCapture={() => window.open(aulaAtual.urlPdf, "_blank" )}/>  
                        </li>
                        <li className="collection-item">
                          <p className="texto" id="texto" dangerouslySetInnerHTML={{ __html: nl2br(aulaAtual.texto) }}></p> 
                        </li>
                      </ul>
                      {/*<ul className="collapsible">
                        <li>
                          <div className="collapsible-header"><h4>{aulaAtual.nome}</h4> 
                            <input type="button" value="PDF" onClickCapture={() => window.open(aulaAtual.urlPdf, "_blank" )}/>  
                          </div>
                          <div className="collapsible-body">
                            <p>
                              {aulaAtual.texto}
                            </p>
                          </div>
                        </li>
                      </ul>*/}
                  </div>
                  <div className="lista row">
                    <div className="col s12 m4 l3">
                      <input className="col s6 m1 l1 " type="button" value="<" onClick={this.chamarAnterior} href="#video" />  
                      <input className="col s6 push-s1 m2 push-m1 l1 push-l1" type="button" value={porcentagem + "%"} />  
                      <input className="col s6 push-s2 m1 push-m2 l2 push-l2" type="button" value=">" onClick={this.chamarProximo}/>  
                    </div>
                    {/*lista.map.length > 0 ?
                      lista.map(l => (
                        <ul className="collection with-header" key={"xx-" + l[0]}>
                          <li className="collection-header">{l[0]}</li>
                            {l[1].map( (aula, index) => (
                              <li className="collection-item" key = {"zz-"+l[0]+"-"+index}>
                                <p className="left" id={""+aula.numero} onClickCapture={this.alterarVideo}>
                                  <label id={""+aula.numero} onClickCapture={this.alterarVideo}>
                                    <input className="with-gap left" name={aula.id} type="radio" disabled/>
                                    <span id={""+aula.numero} onClickCapture={ () => this.chamarAula( aula.id ) }>{aula.nome}</span>
                                  </label>
                                </p>
                              </li>
                            ))}
                        </ul>
                      ))
                    :
                      <h4>Ainda não há vídeos para este curso</h4>
                            */}   
                    <div className="listaTodasAulas col s12 m4 l3">
                      {listaTemas.map(l => (
                        <ul className="collection with-header" key={"xx-" + l[0] + parseInt(Math.random()*1000)}>
                          <li className="collection-header">{l[0]}</li>
                          
                          {l[1].map( (aula, index) => (
                            <li className="collection-item liListaAulas" key = {"zz-"+l[0]+"-"+index}>
                              <table className="striped">
                                <tbody>
                                  <tr>
                                    <td>
                                      <p className="left" id={""+aula.numero} onClickCapture={this.alterarVideo}>
                                        <label id={""+aula.numero} onClickCapture={this.alterarVideo}>
                                          <input className="with-gap" name={aula.id} type="radio" disabled checked={ progresso.filter(p => p.idAula === aula.id).length > 0 } />
                                          <span id={""+aula.numero} onClickCapture={ () => this.chamarAula( aula.id ) }>{aula.nome}</span>
                                        </label>
                                      </p>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </li>
                          ))}
                        </ul>
                      ))}      
                    </div>
                  </div>
                </div>
              </div> 
              
          </div>
        }
      </div>
    );
  }
};
export default Aula;