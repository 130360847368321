import React, { Component } from 'react';
import moment from 'moment';

//Paginas
import apiUrl from '../../../axiosApi';
import './index.css';


class AporteAdmin extends Component{
    constructor(props){
      super(props)
      this.state = {        
        carregou:'0',
        mensagem:'',
        id: '',
        listaAlunos:[],
        listaTodosAportes:[],
        listaAportesPendentes:[],
        listaDadosUsuariosAportesPendentes:[],
      }
      this.listarAlunos();
      this.listarAportesPendentes();
      this.listarTodosAportes();
    }
    changeHandler = e => {
      this.setState({[e.target.name]: e.target.value})
    }
    listarAlunos = () =>{
      apiUrl.get("/usuario")
      .then(res => {
          console.log(res.data);
          this.setState({ listaAlunos: JSON.parse(JSON.stringify(res.data))});
      })
      .catch(error => {
        alert(error);
      })
    }
    listarAportesPendentes = () => {
      try{
        apiUrl.get('/admin/aporte/novos')
        .then(res => {
    
            console.log("====== APORTES NOVOS ======");
            console.log(res.data);
            
            this.setState({ listaAportesPendentes: res.data});            
        })
      }catch(error){
          this.setState({"mensagem":error});
      } 
    }
    listarTodosAportes = () => {
      try{
        apiUrl.get('/admin/aporte')
        .then(res => {
    
            console.log("====== APORTES ======");
            console.log(res.data);
            
            this.setState({ listaTodosAportes: res.data});            
        })
      }catch(error){
          this.setState({"mensagem":error});
      } 
    }
    setAporteStatus = (guid, status) =>{
      this.toastMensagem("Operação realizada "  + guid);
      console.log(guid);
      
      return apiUrl.put(`/admin/aporte/status/${guid}`, {
        status: status,         
      });
    }
    setAporteRecebido = async guid =>{
      this.toastMensagem("Operação realizada "  + guid);
      console.log(guid);
      
      if (window.confirm("Tem certeza que deseja receber essa transferencia?")) {
        this.toastMensagem("> "  + guid);
        await this.setAporteStatus(guid, 2)  // to-do: Mudar para enum-constante!
        .then(res => {
          //Resposta do Servidor
          this.setState({"mensagem":"Aporte Recebido"});
          console.log(res.data);
          this.toastMensagem("Operação realizada "  + guid);
          
          //this.listarAportesPendentes();
          this.setState({ listaAportesPendentes: this.state.listaAportesPendentes.filter( a => a.guid != guid ) });
        })
        .catch(error => {
          this.setState({"mensagem": "Erro ao receber Aportes"});
          console.log("Error" + error);
        });
      }

      this.toastMensagem("! "  + guid);
      
    }
    setAporteCancelado = async guid =>{
      console.log(guid);
      
      if (window.confirm("Tem certeza que deseja cancelar essa transferencia?")) {
        this.toastMensagem("> "  + guid);
        await this.setAporteStatus(guid, 3)  // to-do: Mudar para enum-constante!
        .then(res => {
          //Resposta do Servidor
          this.setState({"mensagem":"Aporte Cancelado"});
          console.log(res.data);
          this.toastMensagem("Operação realizada "  + guid);

          //this.listarAportesPendentes();
          this.setState({ listaAportesPendentes: this.state.listaAportesPendentes.filter( a => a.guid != guid ) });
        })
        .catch(error => {
          this.setState({"mensagem": "Erro ao cancelar Aporte"});
          console.log("Error" + error);
        });
      }
      
    }
    setAporteEstornado =  async guid =>{
      console.log(guid);
      
      if (window.confirm("Tem certeza que deseja estornar essa transferencia?")) {
        this.toastMensagem("> "  + guid);
        await this.setAporteStatus(guid, 4)  // to-do: Mudar para enum-constante!
        .then(res => {
          //Resposta do Servidor
          this.setState({"mensagem":"Aporte Estornado"});
          console.log(res.data);
          this.toastMensagem("Operação realizada "  + guid);

          //this.listarAportesPendentes();
          this.setState({ listaAportesPendentes: this.state.listaAportesPendentes.filter( a => a.guid != guid ) });
        })
        .catch(error => {
          this.setState({"mensagem": "Erro ao estornar Aportes"});
          console.log("Error" + error);
        });
      }
      
    }
    componentDidMount(){
      const M = window.M;
      document.addEventListener('DOMContentLoaded', function() {
        var elems = document.querySelectorAll('.collapsible');
        //var instances = M.Collapsible.init(elems, {});
        M.Collapsible.init(elems, {});
      });
      document.addEventListener('DOMContentLoaded', function() {
        var elems = document.querySelectorAll('.modal');
        //var instances = M.Modal.init(elems, {});
        M.Modal.init(elems, {});
      })
    }
    
    
    toastMensagem(message){
      const M = window.M;
      M.toast({html: message, classes: 'rounded'})
    }
    
    render(){
      const {listaAlunos, listaAportesPendentes, listaTodosAportes, listaDadosUsuariosAportesPendentes} = this.state;
      
      return( 
        <div className="aporteAdmin"> 
            <div className="row">
            <ul className="collection with-header">
              <li className="collection-header"><h3>Aportes</h3></li>
                <ul className="collapsible ">
                    <li className="collection-item">
                      <div className="collapsible-header">
                       <h5>Pendentes</h5>
                      </div>
                      <div className="collapsible-body">
                        <ul className="collapsible">
                          {listaAportesPendentes.map(lista => (
                            <li key = {lista.guid}>
                              <div className="collapsible-header">
                                <table className="responsive-table">
                                  <thead>
                                    <tr>
                                        <th>Nome</th>
                                        <th>CPF</th>
                                        <th>Valor</th>
                                        <th>Data transferência</th>
                                        <th>Comprovante</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>{lista.carteira.usuario.nome}</td>
                                      <td>{lista.carteira.usuario.cpf}</td>
                                      <td>R${lista.valor / 100}</td>
                                      <td>{moment(lista.dataCadastro).format("DD/MM/YYYY")}</td>
                                      <td>{ lista.tipo == 1 ? <a target="blank" href={apiUrl.defaults.baseURL+lista.urlComprovante} >Comprovante </a> : ''}</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div className="collapsible-body">
                                <span>
                                  <li>Cadastrado em: {moment(lista.dataTransferencia).format("DD/MM/YYYY hh:mm")}</li> 
                                  <li>
                                    <input type="button" id={lista.guid} value="Recebido" onClick={() => this.setAporteRecebido(lista.guid)}/>
                                    <input type="button" id={lista.guid} value="Cancelado" onClick={() => this.setAporteCancelado(lista.guid)}/>
                                    <input type="button" id={lista.guid} value="Devolvido" onClick={() => this.setAporteEstornado(lista.guid)}/>
                                  </li>
                                </span>
                              </div>
                            </li>
                          ))}
                      </ul>
                      </div>
                    </li>
                </ul>
                <ul className="collapsible ">
                    <li className="collection-item">
                      <div className="collapsible-header">
                       <h5>Aportes</h5>
                      </div>
                      <div className="collapsible-body">
                        <table className="responsive-table">
                          <thead>
                            <tr>
                                <th>Status</th>
                                <th>Valor</th>
                                <th>Nome</th>
                                <th>Data</th>
                                <th>Comprovante</th>
                            </tr>
                          </thead>
                          <tbody>
                            {/*
                            guid: "5c56a161-3c97-42e1-a227-4b8f57a6cd7a"
                            aporteOrigem: null
                            dataAprovacao: "2020-07-24T20:21:46.000Z"
                            dataCadastro: "2020-07-19 21:37:45"
                            dataRetirada: null
                            dataTransferencia: "2020-07-19 00:00:00"
                            status: 2
                            taxaRendimento: 0.07
                            tipo: 1
                            urlComprovante: null
                            valor: 10000
                            idCarteira: 20
                            carteira: {
                              ativo: true
                              dataCadastro: "2020-07-19 18:25:27"
                              id: 20
                              idUsuario: 21
                              nome: "Carteira Padrão"
                              saldoTmp: 0
                              usuario: {
                                id: 21
                                nome: "Teste Indica 8"
                                ativo: true
                                cpf: "41807859002"
                                dadosCadastro: {
                                  dataCadastro: "2020-07-19 18:25:27"
                                  dataNascimento: "1990-01-01"
                                  email: "teste8@indica.zz"
                                  idUsuario: 21
                                  sexo: null
                                  telefone: null
                                }
                              }
                            }
                            */}
                           {listaTodosAportes.map(a => (
                              <tr>
                                <td>{a.status}</td>
                                <td>R$ {a.valor / 100}</td>
                                <td>{a.carteira.usuario.nome}</td>
                                <td>{moment(a.dataAprovacao).format("DD/MM/YYYY")}</td>
                                <td>{ a.tipo == 1 ? <a target="blank" href={apiUrl.defaults.baseURL+a.urlComprovante} >Comprovante </a> : ''}</td>
                              </tr>
                           ))}
                          </tbody>
                        </table>
                      </div>
                    </li>
                </ul>
            </ul>  
          </div> 
        </div>
    );
}
}
export default AporteAdmin;