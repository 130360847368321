import React, { Component } from 'react';
import moment from 'moment';

//Paginas
import apiUrl from '../../axiosApi';
import './index.css';
import { Preloader } from 'react-materialize';

import pageView from '../../utils/pageView';


//IMGS
//import informativoImg  from '../../assets/fundo2.jpg';

function nl2br(texto) {
  return texto.replace(new RegExp('\r?\n','g'), '<br />');
}


class Aula extends Component{
  constructor(props){
    super(props);

    this.state = {
      loading: true,
      titulo: "",
      descricao: "",
      urlAovivo: 'https://www.youtube.com/embed/5qap5aO4i9A',
    }

    pageView.log('ao-vivo');
  }

  changeHandler = e => {
    this.setState({[e.target.name]: e.target.value})
  }

  componentDidMount(){
    const M = window.M;
    document.addEventListener('DOMContentLoaded', function() {
      var elems = document.querySelectorAll('.collapsible');
      //var instances = M.Collapsible.init(elems, {
      M.Collapsible.init(elems, {
        accordion: true,
      });
    });
    document.addEventListener('DOMContentLoaded', function() {
      var elems = document.querySelectorAll('.scrollspy');
      //var instances = M.ScrollSpy.init(elems, {
      M.ScrollSpy.init(elems, {
        scrollOffset: 20,
      });
    });
    document.addEventListener('DOMContentLoaded', function() {
      var elems = document.querySelectorAll('.slider');
      //var instances = M.Slider.init(elems, {
      M.Slider.init(elems, {
        interval: 5000,
        fullWidth: false,
      });
    });
  }

  toastMensagem(message){
    const M = window.M;
    M.toast({html: message, classes: 'rounded'})
  }
    
  render(){
    const {titulo, descricao, urlAovivo} = this.state;
     
    return( 
      <div>
          <div className="aula">
            <h3 className="center">
              AO VIVO         
            </h3>
            <b>{titulo}</b> <br />
            <p>{descricao}</p>
            <iframe title="video-aula" width="853" height="480" src={urlAovivo} frameBorder="0" allowFullScreen
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" resquestfocus="true"></iframe>
           
          </div>
      </div>
    );
  }
};
export default Aula;