import React, { Component } from 'react';

//Paginas
import apiUrl from '../../../axiosApi';
import './index.css';

// Imagens
import logoImg from '../../../assets/miniLogo.png';



class Informativo extends Component{
    constructor(props){
      super(props)
      this.state = {        
        carregou:'0',
        mensagem:'',
        id: '',
      }
    }
    changeHandler = e => {
      this.setState({[e.target.name]: e.target.value})
    }
    
    componentDidMount(){
      const M = window.M;
      document.addEventListener('DOMContentLoaded', function() {
          var elems = document.querySelectorAll('.sidenav');
          var instances = M.Sidenav.init(elems, {});
        });
      document.addEventListener('DOMContentLoaded', function() {
        var elems = document.querySelectorAll('.dropdown-trigger');
        var instances = M.Dropdown.init(elems, 
          {
            hover: false, 
            inDuration: 10,
            coverTrigger: false,
            onCloseStart: false});
          }
      );
   }

    toastMensagem(message){
      const M = window.M;
      M.toast({html: message, classes: 'rounded'})
    }
    
    render(){
      const {listaAlunos, listaAportesPendentes, listaDadosUsuariosAportesPendentes} = this.state;
      
      return(
        <div>
            <div id="menu" className="navbar-fixed">
            <nav>
                <div className="nav-wrapper #01579b light-blue darken-4">
                    <a href="#" data-target="slide-out" className="sidenav-trigger">&#9776;</a>
                    <div className="row">
                        <div className="col s1 push-s4 m1 push-m5 l1">
                            <a href="/" className="brand-logo left">
                                <img src={logoImg} alt="Logo QUATTRO FINTECH" />
                            </a>
                        </div>
                        <div className="col m8 pull-m1 l10">
                            <ul className="right hide-on-med-and-down">
                                <li><a className="menuSidenav" href="/admin/paineldecontrole">Painel de Controle</a></li>
                                <li><a className="menuSidenav" href="/admin/aluno">Alunos</a></li>
                                <li><a className="menuSidenav" href="/admin/aporte">Aportes</a></li>
                                <li><a className="menuSidenav" href="/admin/aulas">Aulas</a></li>
                                <li><a className="menuSidenav" href="/admin/relatorio">Relatórios</a></li>
                                <li><a className="menuSidenav" href="/admin/empresa">Empresa</a></li>
                                <li><a className="menuSidenav" href="/logout">Logout</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
            <ul id="slide-out" className="sidenav">
              <li><a className="menuSidenav" href="/admin/paineldecontrole">Painel de Controle</a></li>
              <li><a className="menuSidenav" href="/admin/aluno">Alunos</a></li>
              <li><a className="menuSidenav" href="/admin/aporte">Aportes</a></li>
              <li><a className="menuSidenav" href="/admin/aulas">Aulas</a></li>
              <li><a className="menuSidenav" href="/admin/relatorio">Relatórios</a></li>
              <li><a className="menuSidenav" href="/admin/empresa">Empresa</a></li>
              <li><a className="menuSidenav" href="/logout">Logout</a></li>
            </ul>           
    </div>
    );
  };
}
export default Informativo;