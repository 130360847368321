import React, { Component } from 'react';
import moment from 'moment';
//Paginas
import apiUrl from '../../../axiosApi';
import './index.css';

import pageView from '../../../utils/pageView';

class Indique extends Component {

    constructor(props){
      super(props)
      this.state = {        
        carregou:'0',
        mensagem:'Indica vai ...',
        id: localStorage.getItem('idUsuario'),
        nome: '',
        email: '',
        status: '',
        lista: [],
        listaCarteira:[],
        listaCarteiraResumida:[],
        saldoTotalCarteira: 0, 
      }

      pageView.log('carteira-completa-quattro');
      this.listarCarteira();
    }

    listarCarteira = (cod) =>{
      var saldo = 0; 
      apiUrl.get("/carteira-quattro/carteira")
      .then(res => {
          this.setState({ listaCarteira: res.data });
          for(var i = 0; i < res.data.length; i++){
            saldo+= (res.data[i].quantidade * res.data[i].valorCompra);
          }
          this.setState({saldoTotalCarteira: saldo})
      })
      .catch(error => {
        this.toastMensagem(error);
      })
      
      apiUrl.get("/carteira-quattro/carteira/resumido")
      .then(res => {
          this.setState({ listaCarteiraResumida: res.data });
      })
      .catch(error => {
        this.toastMensagem(error);
      })
    }
    mascaraValor = (valor, qnt) =>{
      return valor.toFixed(qnt).replace(".", ",")
    }
    changeHandler = e => {
      this.setState({[e.target.name]: e.target.value})
    }
    toastMensagem(message){
      const M = window.M;
      M.toast({html: message, classes: 'rounded'})
    }

    render(){
      const {nome, email, listaCarteira, listaCarteiraResumida,saldoTotalCarteira} = this.state;
      return( 
          <div className="indique">
            <div className="row">
              <div className="col s10 push-s1 m10 push-m1 l10 push-l1">      
                <blockquote>
                  <h3>Carteira Quattro</h3>
                </blockquote>
              </div>
            </div>
            <div className="row">
              {listaCarteiraResumida.map(l =>(
                <div className="col s10 push-s1 m2 push-m3 z-depth-2">    
                    <h5>{l.tipo}</h5>
                    <h5>{this.mascaraValor(l.porcentagem, 2)}%</h5>
                </div>
              ))}
            </div>
            
            <div className="row">
              {listaCarteiraResumida.map(l =>(
                listaCarteira.map(c => (
                  l.tipo == c.ativo.tipo
                    ?
                      <div className="ativos col s10 push-s1 m4 push-m1 z-depth-2">    
                        <h5>{c.ativo.codigo}</h5>
                        <h5>{c.ativo.nome}</h5>
                        <h5>{c.ativo.tipo}</h5>
                        <h5>{this.mascaraValor((c.quantidade * c.valorCompra)/saldoTotalCarteira, 4)}%</h5>
                      </div>
                    :
                      ""
                ))
              ))}
            </div>
          </div>
    );
}
};
export default Indique;