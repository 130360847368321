import React, { Component } from 'react';
import {Bar, Line, Pie, Polar} from 'react-chartjs-2';
import moment from 'moment';
import CurrencyInput from 'react-currency-masked-input'

//Pagina
import apiUrl from '../../../axiosApi';
import './index.css';
/* img */



class EmpresaAdmin extends Component{
    constructor(props){
      super(props)
      this.state = {        
        carregou:'0',
        mensagem:'',
        id: '',
        lista:[],
        graficosAluno: [],
        graficosAporte: [],
        investimentoHoje: '',
        investimentoSemana: '',
        investimentoMes: '',
        investimentoTotal: '',
        totalHoje: '',
        totalSemana: '',
        totalMes: '',
        totalTotal: '',

        idAtivo: '',
        codigoAtivo: '',
        nomeAtivo: '',
        tipoAtivo: '',
        listaTipoAtivos: ['Ação', 'FII', 'Renda Fixa', 'ETFs'],
        listaAtivos:[],
        idAtivoPesquisa: 0,

        listaCarteira:[],
        listaCarteiraResumida:[],


        movimentacaoId: '',
        movimentacaoIdAtivo: '',
        movimentacaoTipoMovimentacao: '',
        listaTipoMovimentacao: [ 'C', 'V', 'D', 'T' ], // (compra, venda, dividendos, taxas)
        movimentacaoValor: 0,
        movimentacaoQuantidade: '',
        movimentacaoData: '',
        listaMovimentacoes: [],
        
      }
      this.listarAportes();            
      this.grafico("1");
      this.grafico("3");

      this.listarAtivos();
      this.listarCarteira();
      this.listarMovimentacoes();
    }
    listarAportes = () => {
      try{
        apiUrl.get('/admin/aporte/novos')
        .then(res => {
            this.totalAportes(res.data);
            //this.setState({ lista: res.data});
        })
      }catch(error){
          this.setState({"mensagem":error});
      } 
    }
    totalAportes = (data) => {
      var date = new Date();
      var hoje = 0;
      var semana = 0;
      var mes = 0;
      var total = 0;
      console.log(data);
      let today = new Date().getDate();
      today +="/"+ new Date().getMonth();
      today +="/"+ new Date().getFullYear();
      let diaSemana = new Date().getDay();
      //console.log(today);
      for(var i = 0; i < data.length; i++){
        var cadastro = new Date(data[i].dataCadastro);
        var dataCadastro = new Date(data[i].dataCadastro).getDate();
        dataCadastro +="/"+ new Date(data[i].dataCadastro).getUTCMonth();
        dataCadastro +="/"+ new Date(data[i].dataCadastro).getFullYear();
        total += (data[i].valor / 100);
        if(dataCadastro === today){
          //console.log("Hoje" + i);
          hoje += (data[i].valor / 100);
        }
        if(dataCadastro.substring(3) === today.substring(3)){
          //console.log("Mes Cadastro: " + dataCadastro.substring(2,5));
          mes += (data[i].valor / 100);
        }
        
        var difrencaDatas = Math.ceil(Math.abs(new Date().getTime() - cadastro.getTime())/ (1000 * 60 * 60 * 24));
        //console.log(data[i].dadosCadastro.dataCadastro);
        switch(diaSemana){
          case 0:
            if(difrencaDatas == 1){
              //console.log("Hoje: " + cadastro);
              semana += (data[i].valor / 100);
            }
            break;
          case 1:
            if(difrencaDatas == 1 || difrencaDatas == 2){
              //console.log("Hoje" + i);
              semana += (data[i].valor / 100);
            }
            break;
          case 2:
            if(difrencaDatas == 1 || difrencaDatas == 2 || difrencaDatas == 3){
              //console.log("Hoje" + i);
              semana += (data[i].valor / 100);
            }
              break;
        case 3:
          if(difrencaDatas == 1 || difrencaDatas == 2 || difrencaDatas == 3 || difrencaDatas == 4){
            //console.log("Hoje" + i);
            semana += (data[i].valor / 100);
          }
          break;
        case 4:
          if(difrencaDatas == 1 || difrencaDatas == 2 || difrencaDatas == 3 || difrencaDatas == 4 
            ||difrencaDatas == 5){
            //console.log("Hoje" + i);
            semana += (data[i].valor / 100);
          }
          break;
        case 5:
          if(difrencaDatas == 1 || difrencaDatas == 2 || difrencaDatas == 3 || difrencaDatas == 4 
            ||difrencaDatas == 5 || difrencaDatas == 6){
            //console.log("Hoje" + i);
            semana += (data[i].valor / 100);
          }
          break
        case 6:
          if(difrencaDatas == 1 || difrencaDatas == 2 || difrencaDatas == 3 || difrencaDatas == 4 
            ||difrencaDatas == 5 || difrencaDatas == 6 || difrencaDatas == 7){
            //console.log("Hoje" + i);
            semana += (data[i].valor / 100);
          }
          break;
        }
      }
        this.setState({investimentoHoje: hoje});
        this.setState({investimentoMes: mes});
        this.setState({investimentoSemana: semana});      
        this.setState({investimentoTotal: total})
    }
    grafico = async (tipo) => {
      var grafAluno = new Array();
      var grafAlunoQuantidade = new Array();
      var achou = false;
      var data;
      if(tipo == "1"){
        await apiUrl.get("/usuario")
        .then(res => {
            data = res.data;
            //this.setState({ listaAlunos: JSON.parse(JSON.stringify(res.data))});
        })
        .catch(error => {
          alert(error);
        })
          var dataCadastro= '';
          var cont = 0;
          //console.log(data.length);
          for(var i = 0; i <data.length; i++){
            dataCadastro = new Date(data[i].dadosCadastro.dataCadastro).getDate();
            dataCadastro +="/"+ new Date(data[i].dadosCadastro.dataCadastro).getUTCMonth();
            dataCadastro +="/"+ new Date(data[i].dadosCadastro.dataCadastro).getFullYear();  
            
            for(var y = 0; y < grafAluno.length; y++){
              if(grafAluno[y] == dataCadastro){
                grafAlunoQuantidade[y] ++;
                achou = true;
              }
            }
  
            if(achou == false){
              grafAluno[cont] = dataCadastro;
              grafAlunoQuantidade[cont] = 1;
              cont++;
            }else{
              cont = cont;
              achou = false;
            }
              
          
        }
        //console.log(grafAluno);
        //console.log(grafAlunoQuantidade);
        this.graficoLinhaAlunos(grafAluno, grafAlunoQuantidade, data.length);
      }
      if(tipo == "3"){
        await apiUrl.get("/admin/aporte/novos")
        .then(res => {
            data = res.data;
            //this.setState({ listaAlunos: JSON.parse(JSON.stringify(res.data))});
        })
        .catch(error => {
          alert(error);
        })
          var dataCadastro= '';
          var cont = 0;
          //console.log(data.length);
          for(var i = 0; i <data.length; i++){
            dataCadastro = new Date(data[i].dataCadastro).getDate();
            dataCadastro +="/"+ new Date(data[i].dataCadastro).getUTCMonth();
            dataCadastro +="/"+ new Date(data[i].dataCadastro).getFullYear();  
            
            for(var y = 0; y < grafAluno.length; y++){
              if(grafAluno[y] == dataCadastro){
                grafAlunoQuantidade[y] ++;
                achou = true;
              }
            }
  
            if(achou == false){
              grafAluno[cont] = dataCadastro;
              grafAlunoQuantidade[cont] = 1;
              cont++;
            }else{
              cont = cont;
              achou = false;
            }
              
          
        }
        //console.log(grafAluno);
        //console.log(grafAlunoQuantidade);
        this.graficoLinhaAportes(grafAluno, grafAlunoQuantidade, data.length);
      }
      
    }
    aletarGrafico = e =>{
      var select = document.getElementById('tipo').value; 
      switch(select){
        case "Alunos":
          this.grafico("1");
          break;
        case "Indique Amigos":
            this.grafico("2");
            break;
        case "Aportes":
            this.grafico("3");
            break;
        case "Investimento":
            this.grafico("4");
            break;
        case "Pacote":
            this.grafico("5");
            break;
      }
      
    }
    graficoLinhaAlunos = (label, dados, total) => {
      var chart = {
        labels: label,
        datasets: [
            {
              label:'Alunos Cadastrados: ' + total,
              data: dados, 
              fill: false,
              fillColor : "rgba(220,220,220,0.5)",
              borderColor: "#062f4f",
              strokeColor : "rgba(220,220,220,1)",
              pointColor : "rgba(255,255,255,1)",
              pointStrokeColor : "#000",
              animation: true,
            },
        ]
      }
      this.setState({graficosAluno: chart});
    }
    graficoLinhaAportes = (label, dados, total) => {
      var chart = {
        labels: label,
        datasets: [
            {
              label:'Aportes Realizados: ' + total,
              data: dados, 
              fill: false,
              fillColor : "rgba(220,220,220,0.5)",
              borderColor: "#062f4f",
              strokeColor : "rgba(220,220,220,1)",
              pointColor : "rgba(255,255,255,1)",
              pointStrokeColor : "#000",
              animation: true,
            },
        ]
      }
      this.setState({graficosAporte: chart});
    }
    changeHandler = e => {
       this.setState({[e.target.name]: e.target.value})
      this.grafico(this.state.tipo);
    }
    idAtivoAlterar = e => {
      //this.setState({idAtivoPesquisa: 18});
      //this.setState({idAtivoPesquisa: e.target.value});
      this.pesquisarAtivo( e.target.value);
      //this.toastMensagem(e.target.value);
    }
    idMovimentacaoAlterar = e => {
      this.pesquisarMovimentacaoAtivos( e.target.value);
      //this.toastMensagem(e.target.value);
    }
    pesquisarMovimentacaoAtivos = (id) => {
      var i = 0;
      apiUrl.get("/carteira-quattro/movimentacao")
      .then(res => {
          console.log("Pesquisa Movimentação");
          while(true){
            if(res.data[i].id == id){
              console.log(res.data[i]);
              this.setState({movimentacaoId: res.data[i].id});
              this.setState({movimentacaoIdAtivo: res.data[i].idAtivo});
              this.setState({movimentacaoTipoMovimentacao: res.data[i].tipoMovimentacao});
              this.setState({movimentacaoValor: parseFloat(res.data[i].valor) / 100});
              this.setState({movimentacaoQuantidade: res.data[i].quantidade});
              this.setState({movimentacaoData: res.data[i].dataCadastro});
              break;
            }
            i++;
          }
      })
      .catch(error => {
        this.toastMensagem(error);
      })
    }
    pesquisarAtivo = (id) =>{   
      var i = 0;   
      apiUrl.get("/carteira-quattro/ativo")
      .then(res => {
          console.log("Pesquisa Ativo");
          while(true){
            if(res.data[i].id == id){
              this.toastMensagem("Achei :" + id);
              this.setState({idAtivo: res.data[i].id});
              this.setState({nomeAtivo: res.data[i].nome});
              this.setState({codigoAtivo: res.data[i].codigo});
              this.setState({tipoAtivo: res.data[i].tipo});
              break;
            }
            i++;
          }
                 
                
      })
      .catch(error => {
        this.toastMensagem(error);
      })
    }
    listarAtivos = () =>{
      apiUrl.get("/carteira-quattro/ativo")
      .then(res => {
          console.log("Ativos");
          console.log(res.data);

          this.setState({ listaAtivos: res.data });
      })
      .catch(error => {
        this.toastMensagem(error);
      })
    }
    listarCarteira = (cod) =>{
      apiUrl.get("/carteira-quattro/carteira")
      .then(res => {
          console.log("Carteira");
          console.log(res.data);

          this.setState({ listaCarteira: res.data });
      })
      .catch(error => {
        this.toastMensagem(error);
      })

      apiUrl.get("/carteira-quattro/carteira/resumido")
      .then(res => {
          console.log("Carteira Resumida");
          console.log(res.data);

          this.setState({ listaCarteiraResumida: res.data });
      })
      .catch(error => {
        this.toastMensagem(error);
      })
    }
    listarMovimentacoes = (cod) =>{
      apiUrl.get("/carteira-quattro/movimentacao")
      .then(res => {
          console.log("Movimentações");
          console.log(res.data);

          this.setState({ listaMovimentacoes: res.data });
      })
      .catch(error => {
        this.toastMensagem(error);
      })
    }
    cadastrarAlterarAtivo = async e => {
      e.preventDefault();      

      this.toastMensagem("Id Ativo: " + this.state.idAtivo);
        let ativo = {
          codigo: this.state.codigoAtivo,
          nome: this.state.nomeAtivo,
          tipo: this.state.tipoAtivo,
        };
      if (this.state.idAtivo)
        ativo.id = this.state.idAtivo;

      console.log("Ativo: ");
      console.log(ativo);

      //await apiUrl.put(`/carteira-quattro/ativo`, ativo)
      apiUrl.put(`/carteira-quattro/ativo`, ativo)
      .then(res => {
        this.setState({"mensagem":  "Ativo Atualizado"});       
        this.toastMensagem("Ativo Atualizado");

        console.log("Ativo Cadastrado/Atualizado");
        console.log(res.data);

        this.limparCamposAtivos();
        this.listarAtivos();
      })
      .catch(error => {        
        this.toastMensagem("Error ao cadastrar Ativo");
        this.setState({"mensagem": "Error ao atualizar Ativo"});
        console.log(error);
      });
    }
    cadastrarMovimentacao = async e => {
      e.preventDefault();      

      /*
        movimentacaoIdAtivo: '',
        movimentacaoTipoMovimentacao: '',
        listaTipoMovimentacao: [ 'C', 'V', 'D', 'T' ], // (compra, venda, dividendos, taxas)
        movimentacaoValor: 0,
        movimentacaoQuantidade: 0,
        movimentacaoData: '',
        listaMovimentacoes: [],
      */

      let movimentacao = {
        idAtivo: this.state.movimentacaoIdAtivo,
        tipoMovimentacao: this.state.movimentacaoTipoMovimentacao,
        valor: parseInt(this.state.movimentacaoValor*1000),
        data: moment(this.state.movimentacaoData).format(),
        
      };
      if ( this.state.movimentacaoQuantidade )
        movimentacao.quantidade = parseInt(this.state.movimentacaoQuantidade);

      if(this.state.movimentacaoId){
        movimentacao.id = this.state.movimentacaoId;
      }

      console.log(" >> Movimentacao: ");
      console.log(movimentacao);
      /*
      //await apiUrl.post(`/carteira-quattro/movimentacao`, movimentacao)
      apiUrl.post(`/carteira-quattro/movimentacao`, movimentacao)
      .then(res => {
        this.setState({"mensagem":  "Movimentação Cadastrada"});       
        this.toastMensagem("Movimentação Cadastrada / Alterada");

        console.log("Movimentação Cadastrado / Alterada");
        console.log(res.data);

        this.limparCamposMovimentacao();
        this.listarCarteira();
        this.listarMovimentacoes();
      })
      .catch(error => {        
        this.toastMensagem("Error ao cadastrar/Alterar Movimentacão");
        this.setState({"mensagem": "Error ao cadastrar movimentação"});
        console.log(error);
      });*/
    }
    limparCamposAtivos = () => {
      this.setState({
        idAtivo: '',
        codigoAtivo: '',
        nomeAtivo: '',
        tipoAtivo: '',
      });
    }
    limparCamposMovimentacao = () => {
      this.setState({
        movimentacaoIdAtivo: '',
        movimentacaoTipoMovimentacao: '',
        movimentacaoValor: '',
        movimentacaoQuantidade: 0,
        movimentacaoData: '',
      });
    }
    mascaraValor = (valor) =>{
      return valor.toFixed(2).replace(".", ",")
    }
    componentDidMount(){
      const M = window.M;
      document.addEventListener('DOMContentLoaded', function() {
        var elems = document.querySelectorAll('.collapsible');
        var instances = M.Collapsible.init(elems, {});
      });
      document.addEventListener('DOMContentLoaded', function() {
        var elems = document.querySelectorAll('.modal');
        var instances = M.Modal.init(elems, {});
      })
      
      document.addEventListener('DOMContentLoaded', function() {
        var elems = document.querySelectorAll('.tabs');
        var instance = M.Tabs.init(elems, {});
      });

      document.addEventListener('DOMContentLoaded', function() {
        var elems = document.querySelectorAll('select');
        var instances = M.FormSelect.init(elems, {});
      });
      document.addEventListener('DOMContentLoaded', function() {
        var elems = document.querySelectorAll('.materialboxed');
        var instances = M.Materialbox.init(elems, {});
      });
      document.addEventListener('DOMContentLoaded', function() {
        var elems = document.querySelectorAll('.scrollspy');
        var instances = M.ScrollSpy.init(elems, {
          scrollOffset: 50,
        });
      });
    }
    toastMensagem(message){
      const M = window.M;
      M.toast({html: message, classes: 'rounded'})
    }
    
    render(){
      const {lista, idAtivo, codigoAtivo, nomeAtivo, tipoAtivo,
        listaTipoAtivos, listaAtivos, listaCarteira, listaCarteiraResumida,
        movimentacaoIdAtivo, movimentacaoTipoMovimentacao, listaTipoMovimentacao,  movimentacaoValor,
        movimentacaoQuantidade, movimentacaoData, listaMovimentacoes, idAtivoPesquisa} = this.state;
      if(lista === ""){
        this.listarTodosHttp()
      }else{
        
      }
      return( 
        <div className="empresaAdmin">
          
          <div className="row">
            <ul className="collection with-header">
              <li className="collection-header section scrollspy" id="aporte"><h3>Aporte</h3></li>
                <ul className="collapsible ">
                    <li className="collection-item">
                      <div className="collapsible-header">
                       <h5>QuattroCoins</h5>
                      </div>
                      <div className="collapsible-body">
                        <table className="responsive-table centered">
                            <thead>
                              <tr>
                                  <th>Hoje</th>
                                  <th>Semana</th>
                                  <th>Mês</th>
                                  <th>Total</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>R$ {this.state.investimentoHoje}</td>
                                <td>R$ {this.state.investimentoSemana} </td>
                                <td>R$ {this.state.investimentoMes}</td>
                                <td>R$ {this.state.investimentoTotal} </td>
                              </tr>                          
                            </tbody>
                          </table>
                      </div>
                    </li>
                </ul>
                <ul className="collapsible ">
                    <li className="collection-item">
                      <div className="collapsible-header">
                       <h5>Produtos</h5>
                      </div>
                      <div className="collapsible-body">
                        <table className="responsive-table centered">
                            <thead>
                              <tr>
                                  <th>Hoje</th>
                                  <th>Semana</th>
                                  <th>Mês</th>
                                  <th>Total</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>R$ 0</td>
                                <td>R$ 0</td>
                                <td>R$ 0</td>
                                <td>R$ 0</td>
                              </tr>                          
                            </tbody>
                          </table>
                      </div>
                    </li>
                </ul>
                <ul className="collapsible ">
                    <li className="collection-item">
                      <div className="collapsible-header">
                       <h5>Total</h5>
                      </div>
                      <div className="collapsible-body">
                        <table className="responsive-table centered">
                          <thead>
                            <tr>
                                <th>Hoje</th>
                                <th>Semana</th>
                                <th>Mês</th>
                                <th>Total</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>R$ {this.state.investimentoHoje}</td>
                              <td>R$ {this.state.investimentoSemana} </td>
                              <td>R$ {this.state.investimentoMes}</td>
                              <td>R$ {this.state.investimentoTotal} </td>
                            </tr>                          
                          </tbody>
                        </table>
                      </div>
                    </li>
                </ul>
            </ul>  
          </div> 

          <div className="row">
            <ul className="collection with-header">
              <li className="collection-header section scrollspy" id="carteira"><h3>Carteira Digital</h3></li>
                <ul className="collapsible ">
                    <li className="collection-item">
                      <div className="collapsible-header">
                       <h5>Cadastrar Ativos</h5>
                      </div>
                      <div className="collapsible-body">
                      <form name="cadastrarAlterarAulas" onSubmit={this.cadastrarAlterarAtivo}>
                  <ul className="collection cadastro">
                    <div className="row">
                      <div className="input-field col s10 m8 push-m2">
                        <input id="idAtivo" type="number" className="validate" placeholder="Id"
                          name="idAtivo" value={idAtivo} onChange={this.changeHandler} disabled />
                        <label className="active" htmlFor="idAtivo" >ID</label>   
                      </div>
                    </div>
                    <div className="row">
                      <div className="input-field col s10 m8 push-m2">
                        <input id="nomeAtivo" type="text" className="validate" required placeholder="Nome"
                          name="nomeAtivo" value={nomeAtivo} onChange={this.changeHandler}  />
                        <label className="active" htmlFor="nomeAtivo" >Nome</label>   
                      </div>
                    </div>
                    <div className="row">
                      <div className="input-field col s10 m8 push-m2">
                        <input id="codigoAtivo" type="text" className="validate" required placeholder="TICKET"
                          name="codigoAtivo" value={codigoAtivo} onChange={this.changeHandler}  />
                        <label className="active" htmlFor="codigoAtivo" >Codigo</label>   
                      </div>
                    </div>
                    <div className="row">
                      <div className="input-field col s10 m8 push-m2">
                      <select name="tipoAtivo" className="browser-default" value={tipoAtivo} onChange={this.changeHandler} required>
                          <option value="" >Modalidade do Ativo</option>
                         {listaTipoAtivos.map( t => <option key={t} value={t}>{t}</option>)}
                      </select>
                        <label className="active" htmlFor="tipoAtivo" >Selecione o tipo do ativo</label>   
                      </div>
                    </div>
                    <button className="btn waves-effect waves-light col s10 m10 push-m1" type="submit" name="action">CADASTRAR</button>
                  </ul>
                </form>
                      </div>
                    </li>
                </ul>
                <ul className="collapsible ">
                    <li className="collection-item">
                      <div className="collapsible-header">
                       <h5>Ativos</h5>
                      </div>
                      <div className="collapsible-body">
                        <a className="waves-effect waves-light btn modal-trigger" href="#movimentarAtivo">Movimentar</a>
                        <table className="ativos responsive-table striped centered">
                          <thead>
                            <tr>
                                <th>ID</th>
                                <th>Nome</th>
                                <th>Ticket</th>
                                <th>Atualizar</th>
                            </tr>
                          </thead>
                          <tbody>
                            {listaAtivos.map(ativo => (         
                              <tr key = {ativo.id}>
                                <td>{ativo.id}</td>
                                <td>{ativo.nome}</td>
                                <td>{ativo.codigo}</td>
                                <td><button name={ativo.id} value={ativo.id} id={ativo.id} className="btn modal-trigger waves-effect waves-light col s10 m10 push-m1" onClickCapture={this.idAtivoAlterar} href="#alterarAtivo">Alterar</button></td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </li>
                </ul>
                <ul className="collapsible ">
                    <li className="collection-item">
                      <div className="collapsible-header">
                       <h5>Movimentações</h5>
                      </div>
                      <div className="collapsible-body">
                        <table className="ativos responsive-table striped centered">
                          <thead>
                            <tr>
                                <th>ID</th>
                                <th>Ticket</th>
                                <th>Tipo</th>
                                <th>VL UNT</th>
                                <th>QNT</th>
                                <th>Total</th>
                                <th>Data</th>
                            </tr>
                          </thead>
                          <tbody>
                            {listaMovimentacoes.map(movimentacao => (         
                              <tr key={movimentacao.id}>
                                <td>{movimentacao.id}</td>
                                <td>{movimentacao.ativo.codigo}</td>
                                <td>{movimentacao.tipoMovimentacao == 'C' ? "COMPRA" : movimentacao.tipoMovimentacao == 'V' ? "VENDA" : movimentacao.tipoMovimentacao == 'D' ? "DIVIDENDOS" : movimentacao.tipoMovimentacao == 'T' ? "TAXA" : ""}</td>
                                <td>R$ {this.mascaraValor(movimentacao.valor/100)}</td>
                                <td>{movimentacao.quantidade}</td>
                                <td>R$ {this.mascaraValor(movimentacao.quantidade * (movimentacao.valor/100))}</td>
                                <td>{moment(movimentacao.dataCadastro).format("DD/MM/YYYY")}</td>
                                <td><button id={movimentacao.id} value={movimentacao.id} className="btn modal-trigger waves-effect waves-light col s10 m10 push-m1" type="submit" name="action" onClickCapture={this.idMovimentacaoAlterar} href="#alterarMovimentacao">Alterar</button></td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </li>
                </ul>
                <ul className="collapsible ">
                    <li className="collection-item">
                      <div className="collapsible-header">
                       <h5>Carteira</h5>
                      </div>
                      <div className="collapsible-body">
                        <table className="ativos responsive-table striped centered">
                          <thead>
                            <tr>
                                <th>ID</th>
                                <th>Ticket</th>
                                <th>QNT</th>
                                <th>VL UNT</th>
                                <th>Total</th>
                                <th>Dividendos</th>
                                <th>Taxas</th>
                            </tr>
                          </thead>
                          <tbody>
                            {listaCarteira.map(carteira => (         
                              <tr key = {carteira.id}>
                                <td>{carteira.id}</td>
                                <td>{carteira.ativo.codigo}</td>
                                <td>{carteira.quantidade}</td>
                                <td>R$ {this.mascaraValor(carteira.valorCompra/100)}</td>
                                <td>R$ {this.mascaraValor(carteira.valorCompra/100 * carteira.quantidade)}</td>
                                <td>R$ {this.mascaraValor(carteira.dividendos/100)}</td>
                                <td>R$ {this.mascaraValor(carteira.taxas/100)}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </li>
                </ul>
                <ul className="collapsible ">
                    <li className="collection-item">
                      <div className="collapsible-header">
                       <h5>Carteira Resumida</h5>
                      </div>
                      <div className="collapsible-body">
                        <table className="ativos responsive-table striped centered">
                          <thead>
                            <tr>
                                <th>Investimetno</th>
                                <th>Porcentagem</th>
                            </tr>
                          </thead>
                          <tbody>
                            {listaCarteiraResumida.map(carteira => (         
                              <tr key = {carteira.tipo}>
                                <td>{carteira.tipo}</td>
                                <td>{this.mascaraValor( carteira.porcentagem)}%</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </li>
                </ul>
            </ul>  
          </div>  

          {/*MODAL MOVIMENTAÇÃO DE ATIVOS*/}               
          <div id="movimentarAtivo" className="modal modal-fixed-footer">
            <form className="section scrollspy col s10 push-s1 m8 push-m2 l6 push-l3"
            name="cadastrarAlterarAulas" id="cadastrarAlterarAulas" onSubmit={this.cadastrarMovimentacao}>
              <div className="modal-content">
                <h4>Movimentar Ativo</h4>
                <div className="row">
                    <div className="row">
                      <div className="input-field col s10 m8 push-m2">
                      <select name="movimentacaoIdAtivo" className="browser-default" value={movimentacaoIdAtivo} onChange={this.changeHandler} required>
                          <option value="" >Escolha um ativo</option>
                         {listaAtivos.map( l => <option key={l.id} value={l.id}>{l.codigo} - {l.nome}</option>)}
                      </select>
                        <label className="active" htmlFor="movimentacaoIdAtivo">Ativo</label>   
                      </div>
                    </div>
                    <div className="row">
                      <div className="input-field col s10 m8 push-m2">
                      <select name="movimentacaoTipoMovimentacao" className="browser-default" value={movimentacaoTipoMovimentacao} onChange={this.changeHandler} required>
                          <option value="" >Selecione o tipo de movimentação</option>
                         {listaTipoMovimentacao.map( t => <option key={t} value={t}>{t}</option>)}
                      </select>
                        <label className="active" htmlFor="alterarTituloAovivo" >Movimentação</label>   
                      </div>
                    </div>
                    <div className="row">
                      <div className="input-field col s10 m8 push-m2">
                        <CurrencyInput id="movimentacaoValor" type="number" className="validate"  required
                          name="movimentacaoValor"  onChange={this.changeHandler}/>
                        <label htmlFor="movimentacaoValor">Valor Unitário</label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="input-field col s10 m8 push-m2">
                      <input type="number" placeholder=""
                              name="movimentacaoQuantidade" value={movimentacaoQuantidade} onChange={this.changeHandler}/>
                        <label className="active" htmlFor="movimentacaoQuantidade" >Quantidade</label>   
                      </div>
                    </div>
                    <div className="row">
                      <div className="input-field col s10 m8 push-m2">
                      <input type="date" placeholder="" required
                              name="movimentacaoData" value={movimentacaoData} onChange={this.changeHandler}/>
                        <label htmlFor="movimentacaoData">Data</label>
                      </div>
                    </div>
                </div>
              </div>
              <div className="modal-footer">
                <button className="btn waves-effect waves-light col s10 m10 push-m1" type="submit" name="action">Cadastrar</button>
                <a className="modal-close btn waves-effect waves-light col s10 m10 push-m1">Cancelar</a>
              </div>            
            </form> 
          </div>         

          {/*MODAL ALTERAR ATIVO*/}               
          <div id="alterarAtivo" className="modal modal-fixed-footer">
            <form className="section scrollspy col s10 push-s1 m8 push-m2 l6 push-l3"
            name="cadastrarAlterarAtivo" id="cadastrarAlterarAtivo" onSubmit={this.cadastrarAlterarAtivo}>
              <div className="modal-content">
                <h4>Alterar Ativo</h4>
                  
                      <ul className="collection cadastro">
                        <div className="row">
                          <div className="input-field col s10 m8 push-m2">
                            <input id="idAtivo" type="number" className="validate" placeholder="Id"
                              name="idAtivo" value={idAtivo} onChange={this.changeHandler} disabled />
                            <label className="active" htmlFor="idAtivo" >ID</label>   
                          </div>
                        </div>
                        <div className="row">
                          <div className="input-field col s10 m8 push-m2">
                            <input id="nomeAtivo" type="text" className="validate" required placeholder="Nome"
                              name="nomeAtivo" value={nomeAtivo} onChange={this.changeHandler}  />
                            <label className="active" htmlFor="nomeAtivo" >Nome</label>   
                          </div>
                        </div>
                        <div className="row">
                          <div className="input-field col s10 m8 push-m2">
                            <input id="codigoAtivo" type="text" className="validate" required placeholder="TICKET"
                              name="codigoAtivo" value={codigoAtivo} onChange={this.changeHandler}  />
                            <label className="active" htmlFor="codigoAtivo" >Codigo</label>   
                          </div>
                        </div>
                        <div className="row">
                          <div className="input-field col s10 m8 push-m2">
                          <select name="tipoAtivo" className="browser-default" value={tipoAtivo} onChange={this.changeHandler} required>
                              <option value="" >Modalidade do Ativo</option>
                            {listaTipoAtivos.map( t => <option key={t} value={t}>{t}</option>)}
                          </select>
                            <label className="active" htmlFor="tipoAtivo" >Selecione o tipo do ativo</label>   
                          </div>
                        </div>
                      </ul>
              </div>
              <div className="modal-footer">
                <button className="btn waves-effect waves-light col s10 m10 push-m1" type="submit" name="action">Alterar</button>
                <a className="modal-close btn waves-effect waves-light col s10 m10 push-m1">Cancelar</a>
              </div>            
            </form> 
          </div>         
          
          {/*MODAL ALTERAR MOVIMENTAçÇÃO*/}               
          <div id="alterarMovimentacao" className="modal modal-fixed-footer">
            <form className="section scrollspy col s10 push-s1 m8 push-m2 l6 push-l3"
            name="cadastrarAlterarMovimentacao" id="cadastrarAlterarMovimentacao" onSubmit={this.cadastrarMovimentacao}>
              <div className="modal-content">
                <h4>Alterar Movimentação</h4>
                <div className="row">
                    <div className="row">
                      <div className="input-field col s10 m8 push-m2">
                      <select name="movimentacaoIdAtivo" className="browser-default" value={movimentacaoIdAtivo} onChange={this.changeHandler} required>
                          <option value="" >Escolha um ativo</option>
                         {listaAtivos.map( l => <option key={l.id} value={l.id}>{l.codigo} - {l.nome}</option>)}
                      </select>
                        <label className="active" htmlFor="movimentacaoIdAtivo">Ativo</label>   
                      </div>
                    </div>
                    <div className="row">
                      <div className="input-field col s10 m8 push-m2">
                      <select name="movimentacaoTipoMovimentacao" className="browser-default" value={movimentacaoTipoMovimentacao} onChange={this.changeHandler} required>
                          <option value="" >Selecione o tipo de movimentação</option>
                         {listaTipoMovimentacao.map( t => <option key={t} value={t}>{t}</option>)}
                      </select>
                        <label className="active" htmlFor="alterarTituloAovivo" >Movimentação</label>   
                      </div>
                    </div>
                    <div className="row">
                      <div className="input-field col s10 m8 push-m2">
                        <CurrencyInput id="movimentacaoValor" type="number" className="validate"  required
                          name="movimentacaoValor"  value={movimentacaoValor} onChange={this.changeHandler}/>
                        <label htmlFor="movimentacaoValor">Valor Unitário</label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="input-field col s10 m8 push-m2">
                      <input type="number" placeholder=""
                              name="movimentacaoQuantidade" value={movimentacaoQuantidade} onChange={this.changeHandler}/>
                        <label className="active" htmlFor="movimentacaoQuantidade" >Quantidade</label>   
                      </div>
                    </div>
                    <div className="row">
                      <div className="input-field col s10 m8 push-m2">
                      <input type="date" placeholder="" required
                              name="movimentacaoData" value={moment(movimentacaoData).format('YYYY-MM-DD')} onChange={this.changeHandler}/>
                        <label htmlFor="movimentacaoData">Data</label>
                      </div>
                    </div>
                </div>
              </div>
              <div className="modal-footer">
                <button className="btn waves-effect waves-light col s10 m10 push-m1" type="submit" name="action">Alterar</button>
                <a className="modal-close btn waves-effect waves-light col s10 m10 push-m1">Cancelar</a>
              </div>            
            </form> 
          </div>         
         
          <div className="row">
            <ul className="collection with-header">
              <li className="collection-header section scrollspy" id="grafico"><h3>Grafico Quattro Fintech</h3></li>
                <ul className="collapsible ">
                    <li className="collection-item">
                      <div className="collapsible-header">
                       <h5>Alunos</h5>
                      </div>
                      <div className="collapsible-body">
                        <Line
                          data={this.state.graficosAluno}
                          option={{
                              title:{
                                  display:this.props.displayTitle,
                                  text: 'Carteira QUATTRO',
                                  fontSize:25
                              },
                              legend:{
                                  display:this.props.displayLegend,
                                  position: this.props.legendPosiotion,
                              },
                              scales: {
                                yAxes: [{
                                  barThickness: 100,
                                  gridLines:{
                                    display: false,
                                    offsetGridLines: false,
                                  },
                                  ticks: {
                                    beginAtZero:true
                                  }
                                }],
                                xAxes: [{
                                  gridLines:"rgba(255, 255, 255, 1)"
                                }]
                              },
                              maintainAspectRatio: false
                          }}
                        />
                      </div>
                    </li>
                </ul>
                <ul className="collapsible ">
                    <li className="collection-item">
                      <div className="collapsible-header">
                       <h5>Aportes</h5>
                      </div>
                      <div className="collapsible-body">
                        <Line
                          data={this.state.graficosAporte}
                          option={{
                              title:{
                                  display:this.props.displayTitle,
                                  text: 'Carteira QUATTRO',
                                  fontSize:25
                              },
                              legend:{
                                  display:this.props.displayLegend,
                                  position: this.props.legendPosiotion,
                              },
                              scales: {
                                yAxes: [{
                                  barThickness: 100,
                                  gridLines:{
                                    display: false,
                                    offsetGridLines: false,
                                  },
                                  ticks: {
                                    beginAtZero:true
                                  }
                                }],
                                xAxes: [{
                                  gridLines:"rgba(255, 255, 255, 1)"
                                }]
                              },
                              maintainAspectRatio: false
                          }}
                        />
                      </div>
                    </li>
                </ul>
                <ul className="collapsible ">
                    <li className="collection-item">
                      <div className="collapsible-header">
                       <h5>Produtos</h5>
                      </div>
                      <div className="collapsible-body">
                        Nothing
                      </div>
                    </li>
                </ul>
            </ul>  
          </div>                  
        </div>
    );
}
}
export default EmpresaAdmin;