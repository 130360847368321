import React, { Component } from 'react';

//Paginas
import apiUrl from '../../axiosApi';
import './index.css';

/* img */
import investimentoImg  from '../../assets/investimento.jpg';
import cursoInvestimento  from '../../assets/cursoInvestimento.png';
import cursoNegocios  from '../../assets/cursoNegocios.png';
import fundo from '../../assets/fundo3.jpg';
import negocioImg  from '../../assets/negocio.jpg';

import pageView from '../../utils/pageView';

class Cursos extends Component{
    constructor(props){
      super(props)

      this.state = {
        idUsuario: localStorage.getItem('idUsuario'),
        cursos: [],
        meusCursos: [],
        temas: [],
      }

      this.listarCursos();
      this.listarMeusCursos();

      pageView.log('cursos');
    }

    changeHandler = e => {
      this.setState({[e.target.name]: e.target.value})
    }
    
    listarCursos = () =>{
      apiUrl.get("/cursos/resumo")
      .then(res => {
          let temas = {};
          for ( let c of res.data ) {
            if ( temas[ c.temas ] )
              temas[ c.temas ].push( c );
            else
              temas[ c.temas ] = [ c ]; 
          }

          this.setState({ 
            cursos: JSON.parse(JSON.stringify(res.data)),
            temas: temas,
          });

      })
      .catch(error => {
        console.log(error);
      })
    }

    listarMeusCursos = () =>{
      apiUrl.get("/usuario-curso/" + this.state.idUsuario)
      .then(res => {          
          this.setState({ 
            meusCursos: JSON.parse(JSON.stringify(res.data)),
          });
      })
      .catch(error => {
        console.log(error);
      })
    }

    componentDidMount(){
      const M = window.M;
      document.addEventListener('DOMContentLoaded', function() {
        var elems = document.querySelectorAll('.collapsible');
        M.Collapsible.init(elems, {
          indicators:true,
        });
      });
      document.addEventListener('DOMContentLoaded', function() {
        var elems = document.querySelectorAll('.tabs');        
        M.Tabs.init(elems, {});
      });
      document.addEventListener('DOMContentLoaded', function() {
        //var elems = document.querySelectorAll('.carousel.carousel-slider');        
        M.Carousel.init({
          fullWidth: true,
          indicators: false,
        });
      });
      
    }

    toastMensagem(message){
      const M = window.M;
      M.toast({html: message, classes: 'rounded'})
    }

    render(){
      const {cursos, meusCursos, temas} = this.state;
      
      return( 
        <div className="cursos">
          <div className="carousel carousel-slider center">
            <img src={fundo} alt="Mão na Massa" />
            <div className="carousel-fixed-item center">
              <div className="row">
                <div className="col s12">
                  <ul className="tabs">
                    <li className="tab col s3"><a href="#cursos">Cursos</a></li>
                    <li className="tab col s3"><a href="#meus">Meus Cursos</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          
          <div id="cursos">
            <div className="row">
              <div className="col s6 m4 l3">
                 <div className="card">
                    <h5 alt="Curso de Investimentos">INVESTIMENTOS</h5>
                    <ul>
                      <li>
                        <b>Módulo 1 - Introdução aos Investimentos. </b> 
                          Iremos despertar o mindset dos alunos, sobre o mundo dos investimentos, como entender sobre as taxas de juros, os tipos de investimentos disponíveis no mercado e o  que significa cada um deles.
                      </li>
                      <li>
                        <b>Módulo 2 - Renda Variável: Construindo conceitos. </b>
                          Iremos contruir o conceito básico sobre a renda variável na rotina do investidor iniciante.
                      </li>
                      <li>
                        <b>Módulo 3 - Olhando pra Dentro de Casa. </b>
                           Iremos explicar ao investidor, que agora já conhece as mais variadas formas de investir, a olhar para dentro de casa. Rever custos pessoais, identificar seu perfil adequado como investidor, como poupar e como investir.
                      </li>
                      <li>
                        <b>Módulo 4 - Como Investir na Bolsa. </b>
                          Iremos aprofundar o investimento na bolsa, utilizando técnicas e análises de mercado para aprender a escolher quais ativos iremos ter em nossa carteira.
                      </li>
                      <li>
                        <b>Módulo 5 - Administrar seu Patrimônio de Maneira consistente e vitalícia. </b>
                          Iremos trabalhar alguns conceitos técnicas para administrar sua carteira, de forma que  você  tenha segurança ao longo do tempo exposto na renda variável.  
                      </li>
                      <li>
                        <b>Módulo 6 - O Guia Final para a Vitória! </b>
                          Neste guia final iremos dar o mindset para o investidor se certificar que sua vida agora faz parte de uma minoria vencedora que irá multiplicar conceitos e influenciar todos ao seu redor. 
                      </li>
                    </ul>
                  </div>
              </div>
              <div className="col s6 m4 l3">
                 <div className="card">
                  <h5 alt="Curso de Investimentos">NEGÓCIOS</h5>
                    <ul>
                      <li>
                        <b>Módulo 1 - Introdução. </b>
                          Vamos abrodar desde a concepção de uma ideia até a definição de culturas fortes que vão nortear todo o processo empreendedor.
                      </li>
                      <li>
                        <b>Módulo 2 - Planejamento. </b>
                          Vamos traçar um modelo mais moderno para estruturar um negócio sem perder a capacidade de se reinventar rápidamente.
                      </li>
                      <li>
                        <b>Módulo 3 - Técnicas e Burocracias. </b> 
                          Um entendimento de um ponto fundamental para a sua empresa não ser prejudicada na escolha de sociedade empresária, parcerias e contratações.
                      </li>
                      <li>
                        <b>Módulo 4 - Comercial. </b> 
                          Uma visão completa desde a captação até o sucesso do cliente.
                      </li>
                      <li>
                        <b>Módulo 5 - Ferramentas. </b>
                      </li>
                      <li>
                        <b>Módulo 6 - Escala. </b>
                          Crescer gera risco, aqui vamos mapear esses aspectos para um crescimento estruturado. 
                      </li>
                    </ul>
                  </div>
              </div>
            </div>
          </div>

          <div id="meus">
            <div className="row">
            {cursos.map(c => (
              meusCursos.some(mc => mc.idCurso == c.id) 
              ? 
              c.id == 1 
                ?
                  <div className="col s10 push-s1 m4">
                    <a href={"/cursos/" + c.id}>   
                      <img src={investimentoImg} alt="Curso de investimentos" className="responsive-img" />
                      <h4 className="center">Investimentos</h4>
                      <h5 className="center">Continuar de onde parei</h5>
                    </a>
                  </div>
                :
                  <div className="col s10 push-s1 m4">
                    <a href={"/cursos/" + c.id}>  
                      <img src={negocioImg} alt="Curso de negócios" className="responsive-img" />
                      <h4 className="center">Negócios</h4>                      
                      <h5 className="center">Continuar de onde parei</h5>
                    </a>
                  </div>
              :
                ""
            ))}
              <h5>
                Adquira nossos cursos <a href="/aporte">no seu primeiro aporte</a> ou com <a href="/aporte">QuattroCoins.</a>
              </h5>
            </div>            
          </div>
          
        </div>
        
    );
    }
};
export default Cursos;