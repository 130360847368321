import React, { Component } from 'react';
import moment from 'moment';
//Paginas
import apiUrl from '../../../axiosApi';
import './index.css';
/* img */

function nl2br(texto) {
  return texto.replace(new RegExp('\r?\n','g'), '<br />');
}

class ConteudoAdmin extends Component{
    constructor(props){
      super(props)
      this.state = {        
        carregou:'0',
        mensagem:'',
        id: '',
        idMorningCall: 0,
        textoMorningCall: '',
        dataMorningCall: '',      
        morningCall: [],
        morningCallPage: 0,

        alterarIdMorningCall: 0,
        alterarTextoMorningCall: '',
        alterarDataMorningCall: '',  
      }
      this.listarMorningCalls();
    }
    changeHandler = e => {
      this.setState({[e.target.name]: e.target.value})
    }
    cadastrarMorningCall = async e =>{
      e.preventDefault();
      if(this.state.alterarIdMorningCall == 0){
        await apiUrl.post(`/morning-call`, {          
          texto: this.state.textoMorningCall,          
          data: moment(this.state.dataMorningCall),
        })
        .then(res => {
          //Resposta do Servidor
          this.toastMensagem("Morning Call CADASTRADO com sucesso para: " + this.state.dataMorningCall)
        })
        .catch(error => {      
          this.toastMensagem("Erro ao CADASTRAR o Morning Call. Error: " + error);
        })
      }else{
        await apiUrl.post(`/morning-call`, {
          id: this.state.alterarIdMorningCall,
          texto: this.state.alterarTextoMorningCall,
          data: moment(this.state.alterarDataMorningCall),
        })
        .then(res => {
          //Resposta do Servidor
          this.toastMensagem("Morning Call ALTERADO com sucesso para: " + this.state.dataMorningCall)
        })
        .catch(error => {      
          this.toastMensagem("Erro ao ALTERAR o Morning Call. Error: " + error);
        })
      }
      this.limparCampos();
      this.listarMorningCalls();
    }
    listarMorningCalls = () => {
      this.setState({ 
        morningCall: [],
      });
      apiUrl.get("/morning-call/" + this.state.morningCallPage)
      .then(res => {
          console.log("morning calls");
          console.log(res.data);
          
          this.setState({ 
            morningCall: [].concat( this.state.morningCall, res.data ),
          });
          
      })
      .catch(error => {
        console.log(error);
      })
    }
    btnAtualizar = async e =>{
      e.preventDefault();
      var id = e.target.id;
      apiUrl.get("/morning-call/" + this.state.morningCallPage)
      .then(res => {
          for(var i = 0; i < res.data.length; i++){
            if(res.data[i].id == id){   
              this.setState({ 
                alterarIdMorningCall: res.data[i].id,
                alterarTextoMorningCall: res.data[i].texto,
                alterarDataMorningCall: res.data[i].data, 
              });
            }
          }
          
          
      })
      .catch(error => {
        console.log(error);
      })
    }
    carregarMaisMorningCalls = () => {
      this.setState({ morningCallPage: this.state.morningCallPage + 1});
      this.listarMorningCalls();
    }
    limparCampos(){
      this.setState({ 
        idMorningCall: 0,
        textoMorningCall: "",
        dataMorningCall: "", 
        alterarIdMorningCall: 0,
        alterarTextoMorningCall: '',
        alterarDataMorningCall: '',  
      });
    }
    componentDidMount(){
      const M = window.M;
      document.addEventListener('DOMContentLoaded', function() {
        var elems = document.querySelectorAll('.collapsible');
        var instances = M.Collapsible.init(elems, {});
      });
      document.addEventListener('DOMContentLoaded', function() {
        var elems = document.querySelectorAll('.modal');
        var instances = M.Modal.init(elems, {});
      })
      document.addEventListener('DOMContentLoaded', function() {
        var elems = document.querySelectorAll('.scrollspy');
        var instances = M.ScrollSpy.init(elems, {
          scrollOffset: 50,
        });
      });
    }
    toastMensagem(message){
      const M = window.M;
      M.toast({html: message, classes: 'rounded'})
    }

    render(){
      const {idMorningCall, textoMorningCall, dataMorningCall, morningCall,
        alterarIdMorningCall, alterarTextoMorningCall, alterarDataMorningCall} = this.state;

      return( 
        <div className="conteudoAdmin">
          <div className="row">
            <ul className="collection with-header">
              <li className="collection-header section scrollspy" id="morningcall"><h3>Morning Call</h3></li>
                <ul className="collapsible ">
                    <li className="collection-item">
                      <div className="collapsible-header">
                       <h5>Cadastrar</h5>
                      </div>
                      <div className="collapsible-body">
                        <div className="row">
                          <form className="section scrollspy col s10 push-s1 m8 push-m2 l6 push-l3"
                            name="cdtMorningCall" id="cdtMorningCall" onSubmit={this.cadastrarMorningCall}>
                            <div className="row">
                              <div className="input-field col s10 m8 push-m2">
                                <input id="idMorningCall" type="number" className="validate" required placeholder="Id"
                                  name="idMorningCall" value={idMorningCall} onChange={this.changeHandler} disabled />
                                <label className="active" htmlFor="idMorningCall" >ID</label>   
                              </div>
                            </div>
                            <div className="row">
                              <div className="input-field col s10 m8 push-m2">
                                <textarea id="textoMorningCall" className="validate" required placeholder="Texto . . ."
                                  name="textoMorningCall" value={textoMorningCall} onChange={this.changeHandler} required   maxLength="5000">
                                </textarea >
                                <label htmlFor="textoMorningCall">Texto</label>
                              </div>
                            </div>
                            <div className="row">
                              <div className="input-field col s10 m8 push-m2">
                                <input id="dataMorningCall" type="date" className="validate"
                                  name="dataMorningCall" value={dataMorningCall} onChange={this.changeHandler}/>
                                <label htmlFor="dataMorningCall">Data</label>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col s12 m12 l12">
                                { idMorningCall == 0 ?
                                  <button className="btn waves-effect waves-light col s10 m10 push-m1" type="submit" name="action">CADASTRAR</button>
                                :
                                  <button className="btn waves-effect waves-light col s10 m10 push-m1" type="submit" name="action">ATUALIZAR</button>
                                }
                              </div>
                            </div>     
                          </form>
                        </div>
                      </div>
                    </li>
                </ul>
                <ul className="collapsible ">
                    <li className="collection-item">
                      <div className="collapsible-header">
                       <h5>Cadastrado</h5>
                      </div>
                      <div className="collapsible-body">
                        <table className="responsive-table">
                          <thead>
                            <tr>
                                <th>ID</th>
                                <th>DATA</th>
                                <th>TEXTO</th>
                                <th>ALTERAR</th>
                            </tr>
                          </thead>
                          <tbody>
                            {morningCall.map(c => 
                                          // <p key={c.id} className="white-text" dangerouslySetInnerHTML={{ __html: `${moment(c.data).format('DD/MM/YYYY')} - ${nl2br(c.texto)}`}}></p>            
                            <tr key={c.id}>
                              <td>{c.id}</td>
                              <td>{moment(c.data).format('DD/MM/YYYY')}</td>
                              <td><p className="white-black" dangerouslySetInnerHTML={{ __html: `${nl2br(c.texto)}`}}></p></td>
                              <td><a className="waves-effect waves-light btn modal-trigger" href="#movimentarAtivo" name={c.id} id={c.id} onClick={this.btnAtualizar}>Alterar</a></td>
                            </tr> )}
                          </tbody>
                        </table>
                      </div>
                    </li>
                </ul>
            </ul>  
          </div>  

          {/*MODAL MOVIMENTAÇÃO*/}               
          <div id="movimentarAtivo" className="modal modal-fixed-footer">
            <form className="section scrollspy col s10 push-s1 m8 push-m2 l6 push-l3"
              name="cdtMorningCall" id="cdtMorningCall" onSubmit={this.cadastrarMorningCall}>
              <div className="modal-content">
                <h4>Alterar Morning Call</h4>
                <div className="row">
                    <div className="row">
                      <div className="input-field col s10 m8 push-m2">
                        <input id="alterarIdMorningCall" type="number" className="validate" required placeholder="Id"
                          name="alterarIdMorningCall" value={alterarIdMorningCall} onChange={this.changeHandler} disabled />
                        <label className="active" htmlFor="alterarIdMorningCall" >ID</label>   
                    </div>
                    </div>
                    <div className="row">
                      <div className="input-field col s10 m8 push-m2">
                        <textarea id="alterarTextoMorningCall" className="validate" required placeholder="Texto . . ."
                          name="alterarTextoMorningCall" value={alterarTextoMorningCall} onChange={this.changeHandler} required   maxLength="5000">
                        </textarea >
                        <label htmlFor="alterarTextoMorningCall">Texto</label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="input-field col s10 m8 push-m2">
                        <input id="alterarDataMorningCall" type="date" className="validate"
                          name="alterarDataMorningCall" value={alterarDataMorningCall} onChange={this.changeHandler}/>
                        <label htmlFor="alterarDataMorningCall">Data</label>
                      </div>
                    </div>
                </div>
              </div>
              <div className="modal-footer">
                <button className="btn waves-effect waves-light col s10 m10 push-m1" type="submit" name="action">Cadastrar</button>
                <a className="modal-close btn waves-effect waves-light col s10 m10 push-m1">Cancelar</a>
              </div>            
            </form> 
          </div>         

          <div className="row">
            <ul className="collection with-header">
              <li className="collection-header section scrollspy" id="popups"><h3>Pop Ups</h3></li>
                <ul className="collapsible ">
                    <li className="collection-item">
                      <div className="collapsible-header">
                       <h5>Cadastrar/Atualizar</h5>
                      </div>
                      <div className="collapsible-body">                        
                        Nothing
                      </div>
                    </li>
                </ul>
                <ul className="collapsible ">
                    <li className="collection-item">
                      <div className="collapsible-header">
                       <h5>Pop Ups</h5>
                      </div>
                      <div className="collapsible-body">
                        <table className="responsive-table">
                          <thead>
                            <tr>
                                <th>ID</th>
                                <th>DATA</th>
                                <th>TEXTO</th>
                                <th>ALTERAR</th>
                            </tr>
                          </thead>
                          <tbody>
                            Nothing
                          </tbody>
                        </table>
                      </div>
                    </li>
                </ul>
            </ul>  
          </div>
          
                 
        </div>
    );
}
}
export default ConteudoAdmin;