import React from 'react';
import ReactDOM from 'react-dom';
import 'materialize-css/dist/css/materialize.min.css';
//Paginas
import * as ServiceWorker from './serviceWorker';
import App from './App'

import './index.css';

ServiceWorker.unregister();

ReactDOM.render(
    <App/>, document.getElementById('root')
);

ServiceWorker.register();
