import React, { Component } from 'react';
import moment from 'moment';

//Paginas
import apiUrl from '../../axiosApi';
import './index.css';
import { Preloader } from 'react-materialize';

import pageView from '../../utils/pageView';

//PRODUTOS
import CarteiraQuattro from '../../component/produtos/carteira.component'
import RelatorioAcoes from '../../component/produtos/relatorioAcoes.component'

//IMGS
//import informativoImg  from '../../assets/fundo2.jpg';

function nl2br(texto) {
  return texto.replace(new RegExp('\r?\n','g'), '<br />');
}


class Aula extends Component{
  constructor(props){
    super(props);

    /*
      numero;      nome;         descricao;  urlVideo
      urlPdf;      duracao;      texto;      temas
      urlBanner;   urlThumbnail
    */
    this.state = {
      idCursoAluno: 0,
      idProduto: props.match.params.idProduto,
      idUsuario: localStorage.getItem('idUsuario'),
      tenhoProdutos: [1,2],
      continuar: false,
    }
    pageView.log('Produto id: '+ this.state.idProduto);
    this.verificaSeTemProduto(this.state.idProduto);
  }

  changeHandler = e => {
    this.setState({[e.target.name]: e.target.value})
  }
  verificaSeTemProduto = (codigo) =>{
    var verificado = false;
    var contador = 0;
    for(var i = 0; i < this.state.tenhoProdutos.length; i ++){
      if(this.state.tenhoProdutos[i] == codigo){
        contador++;
      }
    }
    if(contador == 1){
      verificado = true;
    }
    //this.setState({continuar: verificado});
    //return verificado;
    if(verificado == false){
      window.history.go(-1);
    }else{
      this.setState({continuar: verificado});
    }
  }
  retornaPagina = (id) =>{
    switch(id){
      case 1:
        return <CarteiraQuattro/>
        break;
      default:
          return "Erro de autenticação"
        break;
    }
  }
  componentDidMount(){
    const M = window.M;
    document.addEventListener('DOMContentLoaded', function() {
      var elems = document.querySelectorAll('.collapsible');
      //var instances = M.Collapsible.init(elems, {
      M.Collapsible.init(elems, {});
    });
    document.addEventListener('DOMContentLoaded', function() {
      var elems = document.querySelectorAll('.scrollspy');
      //var instances = M.ScrollSpy.init(elems, {
      M.ScrollSpy.init(elems, {
        scrollOffset: 20,
      });
    });
    document.addEventListener('DOMContentLoaded', function() {
      var elems = document.querySelectorAll('.slider');
      //var instances = M.Slider.init(elems, {
      M.Slider.init(elems, {
        interval: 5000,
        fullWidth: false,
      });
    });
  }
  toastMensagem(message){
    const M = window.M;
    M.toast({html: message, classes: 'rounded'})
  }
    
  render(){
    const {idProduto, continuar} = this.state;    
     
    return( 
      <div className="produto">
        {idProduto == 1
          ?
            <CarteiraQuattro/>
          :
            ""
        }
        {idProduto == 2
          ?
            <RelatorioAcoes/>
          :
            ""
        }
        
      </div>
    );
  }
};
export default Aula;