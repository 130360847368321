import React, { Component } from 'react';
import moment from 'moment';

//Paginas
import apiUrl from '../../axiosApi';
import './index.css';
import { Line } from 'react-chartjs-2';

import pageView from '../../utils/pageView';

class Aporte extends Component {

    constructor(props) {
      super(props)
      this.state = {
        carregou:'0',
        mensagem:'',
        id: localStorage.getItem('idUsuario'),
        idCarteira: 0,
        saldo: 0,
        valor: '',
        data: '',
        comprovante: '',
        tipo: '',
        status:'1',
        aportes: [],
        listaRendimento: [],
        listaExtrato: [],
        i: 1,
        total: 0,
        rendimento: 0,
        totalRendimento: 0,
        graficos: {},
        selicAtual: 2,
        carteira: {},
        selic: 0.02,
        inflacao: 0.0221,
      }
      
      this.listarCarteiras();
      this.dadosDoGrafico();
      pageView.log('carteira');
    }
    changeHandler = e => {
      this.setState({[e.target.name]: e.target.value});
    }
    listarCarteiras = async () => {
      try{
        await apiUrl.get('/carteiras/')
        .then(res => {
            this.setState({ 
              idCarteira: res.data[0].id,
              aportes: res.data[0].aportes,
              carteira: res.data[0],
            });
            
            //this.carteiraConsolidada(res.data);
            this.dadosGrafico(res.data);
            //this.aportes(res.data);
            this.obterSaldoCarteira( res.data[0].id );
            this.totalInvestido();
        })
      }catch(error){
          this.setState({"mensagem":error});
      } 
    }
    obterSaldoCarteira = async (idCarteira) => {
      try{
        await apiUrl.get(`/carteira/${idCarteira}/saldo`)
        .then(res => {
            this.setState({ saldo: res.data / 100 });
        })
      }catch(error){
          this.setState({"mensagem":error});
      } 
    }
    carteiraConsolidada(dados){
      var qntAportes = dados[0].aportes.length;
      var total = 0;
      var rendimento = 0;
      var totalRendimento = 0;
      var dia = 0;
      var valorHj = 0;

      for(var i = 0; i < qntAportes; i++){
        if(dados[0].aportes[i].status == 2){
          total += dados[0].aportes[i].valor/100
          dia = ((dados[0].aportes[i].valor/100)*(dados[0].aportes[i].taxaRendimento - 1))/3650
          
          const now = moment(new Date()); // Data de hoje
          const past = moment(dados[0].aportes[i].dataCadastro); // Outra data no passado
          const duration = moment.duration(now.diff(past));
          
          // Mostra a diferença em dias
          const days = duration.asDays();
          valorHj = days*dia;
          rendimento += valorHj;
          var totlaHj = valorHj + dados[0].aportes[i].valor/100
          totalRendimento +=totlaHj;
        }
      }
      this.setState({"total": total.toFixed(2)});
      this.setState({"rendimento": rendimento.toFixed(4)});
      this.setState({"totalRendimento": totalRendimento.toFixed(2)});
    }
    obterStatus = (id) => {
      switch(id) {
        case 1: return "PENDENTE";
        case 2: return "OK";
        case 3: return "CANCELADO";
        case 4: return "DEVOLVIDO";
        case 5: return "AGUARDANDO";
        case 6: return "RETIRADO";
      }
    }
    retornaValorDia(passado, dia){
      const now = moment(new Date()); // Data de hoje
      const past = moment(passado); // Outra data no passado
      const duration = moment.duration(now.diff(past));
      var valorHj = 0;
      // Mostra a diferença em dias
      const days = duration.asDays();

      valorHj = parseFloat((dia/365)/100) * parseInt(days);

      return valorHj.toFixed(2); 
    }
    componentDidMount(){
      const M = window.M;
      document.addEventListener('DOMContentLoaded', function() {
        var elems = document.querySelectorAll('.scrollspy');
        var instances = M.ScrollSpy.init(elems, 
          {
            scrollOffset: 200,	
          });
      });
    }
    dadosGrafico(dados){
      var label = new Array();
      var quattrocoin = new Array();
      var poupanca = new Array();
      var selic = new Array();
      var inflacao = new Array();
      var mes = moment(new Date, "YYYY-MM-DD").format("MM");
      var ano = moment(new Date, "YYYY-MM-DD").format("YY");
      for(var i = 0; i < 13; i++){
        if(i <= 0 ){
          quattrocoin[i] = 0.00;
          poupanca[i] = 0.0;
          selic[i] = 0.0;          
          inflacao[i] = ((this.state.inflacao * (1/12))* (i+1)) * 100;          
          label[i] = mes + "/" + ano;
        }else{
          quattrocoin[i] = 7.00;
          poupanca[i] = (((this.state.selic* 0.7) * (1/12))* i)*100;
          selic[i] = ((this.state.selic * (1/12))* i) * 100; 
          inflacao[i] = ((this.state.inflacao * (1/12))* (i+1)) * 100;
          if(parseInt(mes)+i > 12 ){
            label[i] = (parseInt(mes)+i-12) + "/" + (parseInt(ano) + 1);
          }else{
            label[i] = (parseInt(mes)+i) + "/" + ano;
          }    
          if(parseInt(mes)+i<10 || parseInt(mes)+i>12 && parseInt(mes)+i<22){
            label[i] = "0"+label[i];
          }
        }
      }
      //grafAluno[y] = "Hoje";
      //grafAlunoQuantidade[y] = this.state.totalRendimento;
      this.graficoLinhaAportes(label, quattrocoin, poupanca, selic, inflacao)  
    }
    dadosDoGrafico(){
      var label = new Array();
      var quattrocoin = new Array();
      var poupanca = new Array();
      var selic = new Array();
      var mes = "12";//moment(new Date, "YYYY-MM-DD").format("MM");
      var ano = moment(new Date, "YYYY-MM-DD").format("YY");
      for(var i = 0; i < 12; i++){
        if(i <= 0 ){
          quattrocoin[i] = 0.00;
          poupanca[i] = 0;
          selic[i] = 0;          
          label[i] = mes + "/" + ano;
        }else{
          quattrocoin[i] = 7.00;
          poupanca[i] = this.mascaraValor((((this.state.selic* 0.7) * (1/12))* i)*100);
          selic[i] = this.mascaraValor(((this.state.selic * (1/12))* i) * 100); 
          if(parseInt(mes)+i > 12 ){
            label[i] = (parseInt(mes)+i-12) + "/" + (parseInt(ano) + 1);
          }else{
            label[i] = (parseInt(mes)+i) + "/" + ano;
          }    
          if(parseInt(mes)+i<10 || parseInt(mes)+i>12 && parseInt(mes)+i<22){
            label[i] = "0"+label[i];
          }
        }
      }
      
     // this.graficoLinhaAportes(label, quattrocoin, poupanca, selic);
      //this.graficoLinhaAportes(label, 0, 0, 0);
    }
    retornaMes = (mes) =>{
      let mesDict = {
        "01": "Jan",
        "02": "Fev",
        "03": "Mar",
        "04": "Abr",
        "05": "Mai",
        "06": "Jun",
        "07": "Jul",
        "08": "Ago",
        "09": "Set",
        "10": "Out",
        "11": "Nov",  
        "12": "Dez",  
        "Jan": "01",
        "Fev": "01",
        "Mar": "03",
        "Abr": "04",
        "Mai": "05",
        "Jun": "06",
        "Jul": "07",
        "Ago": "08",
        "Set": "09",
        "Out": "10",
        "Nov": "11",  
        "Dez": "12",
      };

      return mesDict[mes];  
      
    }
    graficoLinhaAportes = (label, quattroCoins, poupanca, selic, inflacao) => {
      var chart = {
        labels: label,
        datasets: [
            {
              label:'QuattroCoins',
              data:quattroCoins,
              fill: false,
              fillColor : "rgba(220,220,220,0.5)",
              borderColor: "#062f4f",
              strokeColor : "rgba(220,220,220,1)",
              pointColor : "rgba(255,255,255,1)",
              pointStrokeColor : "#000",
              animation: true,
            },
            {
              label:'Poupança',
              data: poupanca, 
              fill: false,
              fillColor : "rgba(120,020,110,0.5)",
              borderColor: "#b87201",
              strokeColor : "rgba(220,220,220,1)",
              pointColor : "rgba(255,255,255,1)",
              pointStrokeColor : "#000",
              animation: true,
            },
            {
              label:'Selic',
              data: selic, 
              fill: false,
              fillColor : "rgba(120,020,110,0.5)",
              borderColor: "#b82601",
              strokeColor : "rgba(220,220,220,1)",
              pointColor : "rgba(255,255,255,1)",
              pointStrokeColor : "#000",
              animation: true,
            },
            {
              label:'Inflação',
              data: inflacao, 
              fill: false,
              fillColor : "rgb(6, 105, 28)",
              borderColor: "rgb(6, 105, 28)",
              strokeColor : "rgba(220,220,220,1)",
              pointColor : "rgba(255,255,255,1)",
              pointStrokeColor : "#000",
              animation: true,
            },
        ]
        
      }
      this.setState({graficos: chart});
    }
    retornaSelic(){
      var selic = new Array();
      selic[0] = 0;
      selic[1] = 20;
      return selic;
    }
    totalInvestido = async () =>{
      var total = 0;
      try{
        let carteira = this.state.carteira;
        /*this.setState({ idCarteira: carteira.id });
        this.setState({ aportes: carteira.aportes });
        this.carteiraConsolidada(carteira);
        this.dadosGrafico(carteira);*/
        for(var i = 0; i < carteira.aportes.length; i++ ){
          if(carteira.aportes[i].tipo == 1 && carteira.aportes[i].status == 2){
            total += carteira.aportes[i].valor/100;
          }
        }
        this.setState({"total": total});
      
      }catch(error){
          this.setState({"mensagem":error});
      } 
      this.totalRendimento();
      this.total();
    }
    totalRendimento = async () =>{
      var total = 0;
      var body = "";
      var list = new Array();
      var cont = 0;
      try{
          let carteira = this.state.carteira;

          /*this.setState({ idCarteira: carteira.id });
          this.setState({ aportes: carteira.aportes });
          this.carteiraConsolidada(carteira);
          this.dadosGrafico(carteira);*/
          for(var i = 0; i < carteira.aportes.length; i++ ){
            if(carteira.aportes[i].tipo == 2 && carteira.aportes[i].status == 2){
              total += carteira.aportes[i].valor/100;
              body = {
                id: i,
                dataCadastro: carteira.aportes[i].dataCadastro,
                valor: carteira.aportes[i].valor/100,
                tipo: 2
              }
              list[cont] = body;
              cont ++;
            }
            if(carteira.aportes[i].tipo == 3 && carteira.aportes[i].status == 2){
              total += carteira.aportes[i].valor/100;
              body = {
                id: i,
                dataCadastro: carteira.aportes[i].dataCadastro,
                valor: carteira.aportes[i].valor/100,
                tipo: 3
              }
              list[cont] = body;
              cont ++;
            }
          }
          this.setState({"rendimento": total});            
          this.setState({listaRendimento: list});
        
      }catch(error){
          this.setState({"mensagem":error});
      } 
    }
    total = async () =>{
      var total = 0;
      try{
        let carteira = this.state.carteira;    
        /*this.setState({ idCarteira: carteira.id });
        this.setState({ aportes: carteira.aportes });
        this.carteiraConsolidada(carteira);
        this.dadosGrafico(carteira);*/
        for(var i = 0; i < carteira.aportes.length; i++ ){
          if(carteira.aportes[i].tipo == 2 && carteira.aportes[i].status == 2){
            total += carteira.aportes[i].valor/100;
          }
          if(carteira.aportes[i].tipo == 3 && carteira.aportes[i].status == 2){
            total += carteira.aportes[i].valor/100;
          }
          if(carteira.aportes[i].tipo == 1 && carteira.aportes[i].status == 2){
            total += carteira.aportes[i].valor/100;
          }
        }
        this.setState({"totalRendimento": total});
    
      }catch(error){
          this.setState({"mensagem":error});
      } 
    }
    componentDidMount(){
      const M = window.M;
      document.addEventListener('DOMContentLoaded', function() {
        var elems = document.querySelectorAll('.collapsible');
        var instances = M.Collapsible.init(elems, {
          indicators:true,
        });
      });
      document.addEventListener('DOMContentLoaded', function() {
        var elems = document.querySelectorAll('.scrollspy');
        var instances = M.ScrollSpy.init(elems, {
          scrollOffset: 50,
        });
      });
    }
    toastMensagem(message){
      const M = window.M;
      M.toast({html: message, classes: 'rounded'})
    }
    mascaraValor = (valor, zeros) =>{
      return valor.toFixed(zeros).replace(".", ",")
    }
    mascaraValor = (valor) =>{
      return valor.toFixed(2).replace(".", ",")
    }
    calculoJurosComposto(valor, juros, periodos){
      var total = 0;
      total = valor * Math.pow((1 + juros), periodos);
      return this.mascaraValor(total);
    }
    

    render(){
      const {graficos, valor, data,  aportes, total, rendimento, totalRendimento, listaRendimento, saldo, selic, inflacao} = this.state;  
      
      return(
          <div className="carteira">
              <div className="row">
                <div className="card saldo col s10 push-s1 m8 push-m2">
                  <h3>Carteira</h3>
                  <h4>QTC {this.mascaraValor(saldo)}</h4>
                  <a href="#extrato" >Extrato</a>
                  <a href="aporte" >Comprar QuattroCoins</a>
                </div>
              </div>

              <div className="row">
                <div className="card comparativo col s10 push-s1 m5 push-m1">
                    <div className="row">
                      <div className="col s5 push-s1">
                        <p className="saldoTitulo">APLICAÇÃO EM 12 MESES</p>
                        <h5>R$ 1.000,00</h5>
                      </div>
                      <div className="col s5 push-s1">
                        <p className="saldoTitulo">INFLAÇÃO EM 12 MESES</p>
                        <h5 className="saldoVermelho">R$ {this.mascaraValor(parseInt(this.calculoJurosComposto(-1000, (inflacao) * (1/12), 12)) + 2000)}</h5>
                      </div>
                      <div className="col s5 push-s1">
                        <p className="saldoTitulo">MONTANTE FINAL NA POUPANÇA</p>
                        <h5 className="saldoVermelho">R$ {this.calculoJurosComposto(1000, (selic * 0.7) * (1/12), 12)}</h5>
                      </div>
                      <div className="col s5 push-s1">
                        <p className="saldoTitulo">MONTANTE FINAL NO TESOURO SELIC</p>
                        <h5 className="saldoVermelho">R$ {this.calculoJurosComposto(1000, selic * (1/251), 251)}</h5>
                      </div>
                      <div className="col s5 push-s1">
                        <p className="saldoTitulo">MONTANTE FINAL COM QUATTROCOINS</p>
                        <h5 className="saldoVerde">QC {this.calculoJurosComposto(1000, 0.07, 1)}</h5>
                      </div>
                      <div className="col s5 push-s1">
                        <p className="saldoTitulo">QUATTROCOINS X TESOURO SELIC</p>
                        <h5 className="saldoVerde">+ {this.mascaraValor((0.07/0.02))} %</h5>
                      </div>
                    </div>
                </div>
                
                <div className="col s10 push-s1 m5 push-m1">
                  <div className="card graficoComparacao">
                    <div className="card-image">
                    <Line
                      data={graficos}
                          option={{
                              title:{
                                  display:this.props.displayTitle,
                                  text: 'COMPARAÇÃO',
                                  fontSize:25
                              },
                              legend:{
                                  display:this.props.displayLegend,
                                  position: this.props.legendPosiotion,
                              },
                              scales: {
                                yAxes: [{
                                  barThickness: 100,
                                  gridLines:{
                                    display: false,
                                    offsetGridLines: false,
                                  },
                                  ticks: {
                                    beginAtZero:true
                                  }
                                }],
                                xAxes: [{
                                  gridLines:"rgba(255, 255, 255, 1)"
                                }]
                              },
                              maintainAspectRatio: false
                      }}
                    />
                      <span className="card-title"></span>
                    </div>
                    <div className="card-content">
                      <h5>Comparação</h5>
                      <div className="card comparacaoValores col s3">
                        <p>Poupança</p>
                        <h5 className="saldoVermelho">{this.calculoJurosComposto((saldo*0.93), (selic * 0.7) * 1/12, 12)}</h5>
                      </div>
                      <div className="card comparacaoValores col s3">
                        <p>Selic</p>
                        <h5 className="saldoVermelho">{this.calculoJurosComposto((saldo*0.93), selic * 1/12, 12)}</h5>
                      </div>
                      <div className="card comparacaoValores col s3">
                        <p>Inflação</p>
                        <h5 className="saldoVermelho">{this.mascaraValor(parseInt(this.calculoJurosComposto((-saldo*0.93), (inflacao) * (1/12), 12)) + (saldo*0.93*2))}</h5>
                      </div>
                      <div className="card comparacaoValores col s3">
                        <p>Quattrocoins</p>
                        <h5 className="saldoVerde">{this.mascaraValor(saldo)}</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
                      

              <div className="row">
                <div className="col s12 m4"> 
                  <ul className="collection with-header">
                    <li className="collection-header section scrollspy" id="extrato"><h4>EXTRATO</h4></li>
                    <li className="collection-item">      
                      <table className="responsive-table striped">
                        <thead>
                          <tr>
                              <th>Data Transferência</th>
                              <th>Valor</th>
                              <th>Tipo</th>
                          </tr>
                        </thead>
                        <tbody>
                          {aportes.map(lista => (
                            lista.status == 2 
                            ?
                              <tr key = {lista.guid} >
                                  <td>{moment(lista.dataTransferencia, "YYYY-MM-DD").format("DD/MM/YYYY")}</td>
                                  <td>{lista.tipo == 1 ? "R$" : "QTC"} {this.mascaraValor(lista.valor / 100)}</td>
                                  <td>{lista.tipo == 1 ? "Aporte" : lista.tipo == 2 ? "Rendimento" : "Indicação"}</td>
                              </tr>
                            :
                              ""  
                          ))}
                        </tbody>
                      </table>
                    </li>
                  </ul>
                </div>

                <div className="col s12 m4"> 
                  <ul className="collection with-header">
                    <li className="collection-header section scrollspy" id="aporte"><h4>APORTES</h4></li>
                    <li className="collection-item">      
                      <table className="responsive-table striped">
                        <thead>
                          <tr>
                              <th>Data Transferência</th>
                              <th>Valor Inicial</th>
                              <th>Taxa</th>
                              <th>Valor Final</th>
                          </tr>
                        </thead>
                        <tbody>
                          {aportes.map(lista => (
                              lista.tipo == 1 && lista.status == 2 
                              ?
                                <tr key = {lista.guid} >
                                    <td>{moment(lista.dataTransferencia, "YYYY-MM-DD").format("DD/MM/YYYY")}</td>
                                    <td>R$ {this.mascaraValor(lista.valor / 100)}</td>
                                    <td>{lista.taxaRendimento}%</td>
                                    <td>R$ {this.mascaraValor((lista.valor * (1 + lista.taxaRendimento))/100)}</td>
                                </tr>
                              :
                                ""
                          ))}
                        </tbody>
                      </table>
                    </li>
                  </ul>
                </div>
              <div className="col s12 m4"> 
                <ul className="collection with-header">
                  <li className="collection-header section scrollspy" id="rendimento"><h4>RENDIMENTOS</h4></li>
                  <li className="collection-item">
                    <table className="responsive-table striped" >
                      <thead>
                        <tr>
                            <th>Data Cadastro</th>
                            <th>Valor</th>
                            <th>Tipo</th>
                        </tr>
                      </thead>
                      <tbody className="section scrollspy">
                        {listaRendimento.map(lista => (
                          <tr key = {lista.id} >
                            <td>{moment(lista.dataCadastro, "YYYY-MM-DD").format("DD/MM/YYYY")}</td>
                            <td>QTC {lista.valor.toFixed(2).replace(".", ",")}</td>
                            <td>{lista.tipo == 2 ? "Rendimento" : "Indicação"}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </li>
                </ul>
              </div>
            </div>
          </div>
      );
    };
};
export default Aporte;