import React, { Component } from 'react';

//Paginas
//import App from '../../App';
import './index.css';

class Manutencao extends Component{

    constructor(props){
      super(props);
        this.state = {
          carregou:'0',
          mensagem:'',
          status: '4',
          id: localStorage.getItem('idUsuario')
        }
    }

    exiteMensagem = () => {
    
        
    }

    render(){
        
        return(
            <div className="manutencao">
                <h2 >Quattro Fintech</h2>
                <h3 className="typing-animation">PLATAFORMA EM MANUTENÇÃO PARA NOVOS SERVIÇOS FINANCEIROS!</h3>
                <br/>
            </div>
        );
    }
}

export default Manutencao;
