import React, { Component } from 'react';

//Paginas
import apiUrl from '../../../axiosApiDebug';
import './index.css';
/* img */

class Debug extends Component{
    constructor(props){
      super(props)
      let _localStorage = {
        logado: localStorage.getItem('logado'),
        idUsuario: localStorage.getItem('idUsuario'),        
        idStatus: localStorage.getItem('idStatus'),
      };

      this.state = {        
        carregou:'0',
        mensagem:'',
        storage: _localStorage,
        apiSession: {},
        apiDebug: {},
        apiLogFiles: {},        
      }

      this.getApiSession();
      this.getApiDebug();
      // this.getApiLogFiles();
    }

    changeHandler = e => {
      this.setState({[e.target.name]: e.target.value})
    }

    getApiSession = () =>{
      apiUrl.get("/session")
      .then(res => {
          console.log(res.data);
          this.setState({ apiSession: res.data});
      })
      .catch(error => {
        alert(error);
      })
    }
    getApiDebug = () =>{
      apiUrl.get("/debug")
      .then(res => {
          console.log(res.data);
          this.setState({ apiDebug: res.data});
      })
      .catch(error => {
        alert(error);
      })
    }
    getApiLogFiles = () =>{
      apiUrl.get("/usuario")
      .then(res => {
          console.log(res.data);
          this.setState({ listaAlunos: JSON.parse(JSON.stringify(res.data))});
      })
      .catch(error => {
        alert(error);
      })
    }
    componentDidMount(){
      const M = window.M;
      document.addEventListener('DOMContentLoaded', function() {
        var elems = document.querySelectorAll('.collapsible');
        var instances = M.Collapsible.init(elems, {});
      });
      document.addEventListener('DOMContentLoaded', function() {
        var elems = document.querySelectorAll('.modal');
        var instances = M.Modal.init(elems, {});
      })
    }
    toastMensagem(message){
      const M = window.M;
      M.toast({html: message, classes: 'rounded'})
    }
    
    render(){
      const { storage, apiSession, apiDebug } = this.state;

      return(
        <div className="debug">
          <h2>LocaStorage</h2>
          <p>
            {JSON.stringify(storage)}
          </p>
          <hr/>

          <h2>APISession</h2>
          <p>
            {JSON.stringify(apiSession)}
          </p>
          <hr/>

          <h2>APIDebug</h2>
          <p>
            {JSON.stringify(apiDebug)}
          </p>
          <hr/>

        </div>
      );
}
}
export default Debug;