import axios from 'axios';

const ENV = 'prod'; // dev, prod 

const URL = {
    dev: "http://localhost:4500/",
    prod: "https://quattro-api.herokuapp.com/"
}

const axiosInstance =  axios.create({
    baseURL: URL[ENV],
    withCredentials: true,
})

axiosInstance.interceptors.request.use(function (config) {
    const token = localStorage.getItem('X-Cookie-Status');

    if (token)
        config.headers['X-Cookie-Status'] =  token;

    return config;

});


export default axiosInstance