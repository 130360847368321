import React, { Component } from 'react';
import moment from 'moment';

//Paginas
import apiUrl from '../../../axiosApi';
import './index.css';


class ProdutoAdmin extends Component{
    constructor(props){
      super(props)
      this.state = {        
        carregou:'0',
        mensagem:'',
        id: '',
        nome: '',
        descricao: '',
        preco: 0,
        categoria: '',
        entidade: '',
        idEntidade: 0,
        produtos:[],
        idProduto: 0,
        idUsuario: 0,
        usuarioProdutos:[],
      }
      this.listarProdutos();
      this.listarUsuarioProdutos();
    }

    changeHandler = e => {
      this.setState({[e.target.name]: e.target.value})
    }

    
    cadastrarProduto = () =>{
      this.toastMensagem("Não Implementado ...");
      return;
      /*
      let body = {
        nome: '',
        descricao: '',
        preco: 0,
        categoria: '',
        entidade: '',
        idEntidade: 0,
      }
      apiUrl.put("/produto")
      .then(res => {
          console.log(res.data);

          this.setState({ 
            produtos: JSON.parse(JSON.stringify(res.data)),
          });

      })
      .catch(error => {
        console.log(error);
      })
      */
    }
    

    listarProdutos = () =>{
      apiUrl.get("/produto")
      .then(res => {
          console.log(res.data);

          this.setState({ 
            produtos: JSON.parse(JSON.stringify(res.data)),
          });

      })
      .catch(error => {
        console.log(error);
      })
    }

    btnAtualizar = () => {
      this.toastMensagem("Não Implementado ...");
    }

    btnAtualizarUsuarioProduto = () => {
      this.toastMensagem("Não Implementado ...");
    }

    cadastrarUsuarioProduto = async e =>{
      e.preventDefault();
      
      await apiUrl.post(`/usuario-produto`, {          
        idUsuario: this.state.idUsuario,
        idProduto: this.state.idProduto,
      })
      .then(res => {
        //Resposta do Servidor
        this.toastMensagem("Produto associado com sucesso.")
      })
      .catch(error => {      
        this.toastMensagem("Erro ao associar o produto. Error: " + error);
      })
      
      this.limparCampos();
      this.listarUsuarioProdutos();
    }

    listarUsuarioProdutos = () => {
      this.setState({ 
        usuarioProdutos: [],
      });

      apiUrl.get("/usuario-produto")
      .then(res => {
          console.log("usuario-produtos");
          console.log(res.data);
          
          this.setState({ 
            usuarioProdutos: [].concat( this.state.usuarioProdutos, res.data ),
          });
          
      })
      .catch(error => {
        console.log(error);
      })
    }

    limparCampos = () => {
      this.setState({
        idUsuario: 0,
        idProduto: 0,
      });
    }

    componentDidMount(){
      const M = window.M;
      document.addEventListener('DOMContentLoaded', function() {
        var elems = document.querySelectorAll('.collapsible');
        M.Collapsible.init(elems, {});
      });
      document.addEventListener('DOMContentLoaded', function() {
        var elems = document.querySelectorAll('.modal');
        M.Modal.init(elems, {});
      })
      
      document.addEventListener('DOMContentLoaded', function() {
        var elems = document.querySelectorAll('.tabs');
        M.Tabs.init(elems, {});
      });

      document.addEventListener('DOMContentLoaded', function() {
        var elems = document.querySelectorAll('select');
        M.FormSelect.init(elems, {});
      });
      document.addEventListener('DOMContentLoaded', function() {
        var elems = document.querySelectorAll('.materialboxed');
        M.Materialbox.init(elems, {});
      });
    }

    toastMensagem(message){
      const M = window.M;
      M.toast({html: message, classes: 'rounded'})
    }

    render(){
      const { id, nome, descricao, preco, categoria, entidade, idEntidade, produtos, idUsuario, idProduto, usuarioProdutos } = this.state;

      return( 
        <div className="cursoAdmin"> 
          <div className="row">
            <ul className="collection with-header">
              <li className="collection-header"><h3>Produtos</h3></li>
                <ul className="collapsible ">
                    <li className="collection-item">
                      <div className="collapsible-header">
                       <h5>Cadastrar/Atualizar</h5>
                      </div>
                      <div className="collapsible-body">
                        <div className="row">
                          <form className="section scrollspy col s10 push-s1 m8 push-m2 l6 push-l3"
                            name="cdtMorningCall" id="cdtMorningCall" onSubmit={this.cadastrarProduto}>
                          
                            <div className="row">
                              <div className="input-field col s10 m8 push-m2">
                                <input id="id" type="number" className="validate" required placeholder="Id"
                                  name="id" value={id} onChange={this.changeHandler} disabled />
                                <label className="active" htmlFor="idMorningCall" >ID</label>   
                              </div>
                            </div>
                            <div className="row">
                              <div className="input-field col s10 m8 push-m2">
                                <textarea id="nome" className="validate" required placeholder="Texto . . ."
                                  name="nome" value={nome} onChange={this.changeHandler} required   maxLength="5000">
                                </textarea >
                                <label htmlFor="textoMorningCall">Nome</label>
                              </div>
                            </div>
                            <div className="row">
                              <div className="input-field col s10 m8 push-m2">
                                <input id="descricao" type="text" className="validate"
                                  name="descricao" value={descricao} onChange={this.changeHandler}/>
                                <label htmlFor="dataMorningCall">Descricao</label>
                              </div>
                            </div>
                            <div className="row">
                              <div className="input-field col s10 m8 push-m2">
                                <input id="preco" type="number" className="validate"
                                  name="preco" value={preco} onChange={this.changeHandler}/>
                                <label htmlFor="dataMorningCall">Preco</label>
                              </div>
                            </div>
                            <div className="row">
                              <div className="input-field col s10 m8 push-m2">
                                <input id="categoria" type="text" className="validate"
                                  name="categoria" value={categoria} onChange={this.changeHandler}/>
                                <label htmlFor="dataMorningCall">Categoria</label>
                              </div>
                            </div>
                            <div className="row">
                              <div className="input-field col s10 m8 push-m2">
                                <input id="entidade" type="text" className="validate"
                                  name="entidade" value={entidade} onChange={this.changeHandler}/>
                                <label htmlFor="entidade">Entidade</label>
                              </div>
                            </div>
                            <div className="row">
                              <div className="input-field col s10 m8 push-m2">
                                <input id="idEntidade" type="number" className="validate"
                                  name="idEntidade" value={idEntidade} onChange={this.changeHandler}/>
                                <label htmlFor="idEntidade">IdEntidade</label>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col s12 m12 l12">
                                { id == 0 ?
                                  <button className="btn waves-effect waves-light col s10 m10 push-m1" type="submit" name="action">CADASTRAR</button>
                                :
                                  <button className="btn waves-effect waves-light col s10 m10 push-m1" type="submit" name="action">ATUALIZAR</button>
                                }
                              </div>
                            </div>     
                          </form>
                        </div>
                      </div>
                    </li>
                </ul>

                <ul className="collapsible ">
                    <li className="collection-item">
                      <div className="collapsible-header">
                       <h5>Produtos</h5>
                      </div>
                      <div className="collapsible-body">
                        <table className="responsive-table">
                          <thead>
                            <tr>
                                <th>ID</th>
                                <th>Nome</th>
                                <th>Preco</th>
                                <th>Categoria</th>
                                <th>Entidade</th>
                                <th>IdEntidade</th>
                                <th>Ativo</th>
                                <th>Data de Cadastro</th>
                            </tr>
                          </thead>
                          <tbody>
                            {produtos.map(p =>
                              <tr key={p.id}>
                                <td>{p.id}</td>
                                <td>{p.nome}</td>
                                <td>{p.preco}</td>
                                <td>{p.categoria}</td>
                                <td>{p.entidade}</td>
                                <td>{p.idEntidade}</td>
                                <td>{p.ativo === true ? "Ativo":"Inativo"}</td>
                                <td>{moment(p.dataCadastro).format('DD/MM/YYYY')}</td>
                                <td><input type="button" value="Alterar" name={p.id} id={p.id} onClick={this.btnAtualizar}/> </td>
                              </tr> 
                            )}
                          </tbody>
                        </table>
                      </div>
                    </li>
                </ul>

                <ul className="collapsible ">
                    <li className="collection-item">
                      <div className="collapsible-header">
                       <h5>Cadastrar Usuario Produto</h5>
                      </div>
                      <div className="collapsible-body">
                        <div className="row">
                          <form className="section scrollspy col s10 push-s1 m8 push-m2 l6 push-l3"
                            name="cdtUsuarioProduto" id="cdtUsuarioProduto" onSubmit={this.cadastrarUsuarioProduto}>
                          
                            <div className="row">
                              <div className="input-field col s10 m8 push-m2">
                                <input id="idUsuario" type="number" className="validate" required placeholder="IdUsuario"
                                  name="idUsuario" value={idUsuario} onChange={this.changeHandler} />
                                <label className="active" htmlFor="idUsuario" >ID Usuario</label>   
                              </div>
                            </div>
                          
                            <div className="row">
                              <div className="input-field col s10 m8 push-m2">
                                <input id="idProduto" type="number" className="validate" required placeholder="IdProduto"
                                  name="idProduto" value={idProduto} onChange={this.changeHandler} />
                                <label className="active" htmlFor="idProduto" >ID Produto</label>   
                              </div>
                            </div>

                            <div className="row">
                              <div className="col s12 m12 l12">
                                  <button className="btn waves-effect waves-light col s10 m10 push-m1" type="submit" name="action">CADASTRAR</button>
                              </div>
                            </div>     
                          </form>
                        </div>
                      </div>
                    </li>
                </ul>

                <ul className="collapsible ">
                    <li className="collection-item">
                      <div className="collapsible-header">
                       <h5>Usuarios - Produtos</h5>
                      </div>
                      <div className="collapsible-body">
                        <table className="responsive-table">
                          <thead>
                            <tr>
                                <th>ID Usuario</th>
                                <th>Nome</th>
                                <th>ID Produto</th>
                                <th>Nome</th>
                                <th>Data de Cadastro</th>
                            </tr>
                          </thead>
                          <tbody>
                            {usuarioProdutos.map(up =>
                              <tr key={up.id}>
                                <td>{up.idUsuario}</td>
                                <td>{up.usuario.nome}</td>
                                <td>{up.idProduto}</td>
                                <td>{up.produto.nome}</td>
                                <td>{up.dataCadastro}</td>
                                <td>{up.ativo === true ? "Ativo":"Inativo"}</td>
                                <td>{moment(up.dataCadastro).format('DD/MM/YYYY')}</td>
                                <td><input type="button" value="Alterar" name={up.id} id={up.id} onClick={this.btnAtualizarUsuarioProduto}/> </td>
                              </tr> 
                            )}
                          </tbody>
                        </table>
                      </div>
                    </li>
                </ul>
            </ul>  
          </div> 
                 
        </div>
    );
}
}

export default ProdutoAdmin;