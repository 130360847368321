import React, { Component } from 'react';
import moment from 'moment';
//Paginas
import apiUrl from '../../../axiosApi';
import './index.css';
import pageView from '../../../utils/pageView';

//Imagem
import imgFundo from '../../../assets/fundoRelatorio.jpg';

class Indique extends Component {

    constructor(props){
      super(props)
      this.state = {        
        carregou:'0',
        id: localStorage.getItem('idUsuario'),
        nome: '',
        email: '',
        status: '',
        indice: 0,
        lista: [
          {codigo: 1,
          ticket: "ITSA4",
          nome: "ITAUSA HOLDING S.A"},
          {codigo: 2,
            ticket: "KLBN4",
            nome: "KLABIN CELULOSE E PAPEL S.A"},
          {codigo: 3,
            ticket: "OIBR3",
            nome: "OI S.A"},   
        ],
      }

      pageView.log('carteira-completa-quattro');
    }

    changeHandler = e => {
      this.setState({[e.target.name]: e.target.value})
    }
    toastMensagem(message){
      const M = window.M;
      M.toast({html: message, classes: 'rounded'})
    }
    renderizarTamanhodaImagem = e =>{
      e.preventDefault();
      //var idImagem = e.target.name; 
      //var size = Math.floor(Math.random() * 155) + "px ";
      var size = "";
      size += Math.floor(Math.random() * 851) + "px ";
      size += Math.floor(Math.random() * 315) + "px ";
      size += Math.floor(Math.random() * 425) + "px";

      //Math.floor(Math.random() * 11); 
      for(var i = 0; i < this.state.lista.length; i++){
        size = "";
        size = Math.floor(Math.random() * 155) + "px ";
        size += Math.floor(Math.random() * 851) + "px ";
        size += Math.floor(Math.random() * 315) + "px ";
        size += Math.floor(Math.random() * 425) + "px";
        document.getElementById("acao"+(1+i)).style.clip = "rect("+ size +")";
        //alert(document.getElementById("acao"+(1+i)).style.clip);
      }
      //alert(document.getElementById(idImagem).style.clip);
      //alert(idImagem);
      //return "rect("+ size +");";
      this.setState({carregou: 1});
    }
    relatorioAcoes = e =>{
      e.preventDefault();
      var id = e.target.name;
      //this.toastMensagem("Abrindo Relatório . . .");
      document.getElementById('relatorioCompleto').style.display = "block";
      document.getElementById('btnFloat').style.display = "block";
      //document.getElementById('caixasRelatorio').style.display = "none";
      this.mostraConteudoEscolhodo(id);
    }
    mostraConteudoEscolhodo = (id) =>{
      for(var i = 0; i < this.state.lista.length; i++){
        if(id == "acao"(this.state.lista[i].codigo)){
          this.setState({indice: i});
        }
      }
    }
    fecharelatoriodeAcoes = e => {
      e.preventDefault();
      var id = e.target.name;
      //this.toastMensagem("Fechando Relatório . . .");
      document.getElementById('relatorioCompleto').style.display = "none";
      document.getElementById('btnFloat').style.display = "none";
      //document.getElementById('caixasRelatorio').style.display = "none";
    }
    componentDidMount(){
      const M = window.M;
      document.addEventListener('DOMContentLoaded', function() {
        var elems = document.querySelectorAll('.collapsible');
        //var instances = M.Collapsible.init(elems, {
        M.Collapsible.init(elems, {});
      });
      document.addEventListener('DOMContentLoaded', function() {
        var elems = document.querySelectorAll('.scrollspy');
        //var instances = M.ScrollSpy.init(elems, {
        M.ScrollSpy.init(elems, {
          scrollOffset: 20,
        });
      });
      document.addEventListener('DOMContentLoaded', function() {
        var elems = document.querySelectorAll('.slider');
        //var instances = M.Slider.init(elems, {
        M.Slider.init(elems, {
          interval: 5000,
          fullWidth: false,
        });
      });
      document.addEventListener('DOMContentLoaded', function() {
        var elems = document.querySelectorAll('.fixed-action-btn');
        var instances = M.FloatingActionButton.init(elems, {
          
        });
      });
    }

    render(){
      const {nome, email, lista, carregou, indice} = this.state;
      return( 
          <div className="indique">
            <div className="row">
              <div className="col s10 push-s1 m10 push-m1 l10 push-l1">      
                <blockquote>
                  <h3>Relatório de Ações.</h3>
                </blockquote>
              </div>
            </div>
            <div className="relatorioCompleto row" id="relatorioCompleto">
                <div className="col s6 push-s3">
                  <h3>{lista[indice].ticket}</h3>
                  <p>ITAUSA HOLDING S.A.</p>
                </div>
                <div className="col s10 push-1">
                  Gráfico e mais sei la o que . . . .
                </div>
            </div>
            <div className="row" id="caixasRelatorio">
              {lista.map(c =>
                <div className="caixaRelatorio col s10 push-s1 m4 " key={c.codigo} >
                  <img className="fundoRelatorio" src={imgFundo} id={"acao"+c.codigo} name={"acao"+c.codigo} onClick={this.relatorioAcoes}/>
              <h4 className="center" id={"acao"+c.codigo} name={"acao"+c.codigo} onClick={this.relatorioAcoes}>{c.ticket}</h4>
                </div>
              )}
            </div>
            <div className="fixed-action-btn" id="btnFloat">
              <a className="btn-floating btn-large" onClick={this.fecharelatoriodeAcoes}>
                <b>X</b>
              </a>
            </div>
          </div>
    );
}
};
export default Indique;